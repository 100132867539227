import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import ESN_BACKGROUND from '../images/ESN_SGGW_background.png';
import clock from '../images/clock.svg';
import clockBlue from '../images/clockBlue.svg';
import clockRed from '../images/clockRed.svg';
import marker from '../images/marker.svg';
import markerBlue from '../images/markerBlue.svg';
import markerRed from '../images/markerRed.svg';
import backArrow from '../images/backMobile.svg';
import nextArrow from '../images/nextMobile.svg';
import backArrowMobile from '../images/backMobile.svg';
import nextArrowMobile from '../images/nextMobile.svg';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import $ from 'jquery';
import Loading from './Loading.js';
import esn_logo from '../images/esn_logo.png';
import close from '../images/close.svg';
import user from '../images/audience.svg';
dayjs.extend(isBetween);
const months = [
    'Jan.',
    'Feb.',
    'Mar.',
    'Apr.',
    'May',
    'June',
    'July',
    'Aug.',
    'Sept.',
    'Oct.',
    'Nov.',
    'Dec.'
  ]
const monthsDigits = [
    '01','02','03','04','05','06','07','08','09','10','11','12'
];



class Calendar extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            selectedMonth: dayjs(),
            chosenDay: dayjs(),
            showAllUpcomingEvents: true,
            // events: []
         }

    }
    componentDidMount() {
        this.displayEvents();
    }
    setDate = (newDate)=>{
        this.setState({selectedMonth:newDate});
    }
    handleGoBackMonth = ()=>{
        const newDate = this.state.selectedMonth.subtract(1,'months');;
        this.setDate(newDate);
    }
    handleGoForwardMonth = ()=>{
        const newDate = this.state.selectedMonth.add(1,'months');;
        this.setDate(newDate);
    }
    createCalendarDays = () =>{
        const daysInMonth = this.state.selectedMonth.daysInMonth();
        let printDays =[];
        // 1 - monday 0 - sunday
        const monthNo = this.state.selectedMonth.month();
        const year = this.state.selectedMonth.year();
        const firstDayOfMonth = this.state.selectedMonth.startOf('month').day();
        const addPreviousMonthDaysCount = firstDayOfMonth===0 ? 6 : firstDayOfMonth-1;

        // print X days from previous month.
        const previousMonth = this.state.selectedMonth.subtract(1,'month');
        const previousMonthNo = previousMonth.month();
        const previousYear = previousMonth.year();
        const daysInPreviousMonth = previousMonth.daysInMonth();
        for (let index = addPreviousMonthDaysCount; index >0; index--) {
            const dayObj = { day: daysInPreviousMonth-index+1, month: previousMonthNo, year:previousYear}
            printDays.push(dayObj);
        }
        //print selected month
        for (let index = 0; index < daysInMonth; index++) {
            const dayObj = { day: index+1, month: monthNo, year:year}
            printDays.push(dayObj);
        }
        // print X days from next month
        const lastDayOfMonth = this.state.selectedMonth.endOf('month').day();
        const addNextMonthDaysCount = lastDayOfMonth===0 ? 0 : 7-lastDayOfMonth;
        const nextMonth = this.state.selectedMonth.add(1,'month');
        const nextMonthNo = nextMonth.month();
        const nextYear = nextMonth.year();
        for (let index = 0; index <addNextMonthDaysCount; index++) {
            const dayObj = { day: index+1, month: nextMonthNo, year:nextYear}
            printDays.push(dayObj);
        }
        return printDays;
    }
    clearEvents = () =>{
        //cleaning so the events don't append multiple times
        $('.calendar .day .events-of-day').html('');
        $('.calendar .day .multiday-events').html('');
        $('.calendar .day .all-events-mobile').html('');
    }

    displayEvents = ()=>{
        this.props.events.slice().sort((a,b)=>{
            return b.isMultiDay - a.isMultiDay;
        }).forEach(event => {
            const eventStartTime = dayjs(event.startTime.seconds*1000);
            const getDay = eventStartTime.date();
            const getMonth = eventStartTime.month();
            const getYear = eventStartTime.year();
            let eventPrint ='';
            const esnEventClass= event.esnOnly && this.props.esn ? 'esn-only' : ''; 
            const zarzadEventClass= event.zarzadOnly && this.props.isZarzad ? 'zarzad-only' : ''; 
            if(event.esnOnly && !this.props.esn)
                return false;
            if(event.zarzadOnly && !this.props.isZarzad)
                return false;
            if(!event.isMultiDay){
                eventPrint =
                `<div class="event ${esnEventClass} ${zarzadEventClass}">
                    <span class="time">`;
                    if((!event.hasOwnProperty('isTimeKnown') || (event.hasOwnProperty('isTimeKnown') && event.isTimeKnown===true))){
                        eventPrint+=`
                        ${dayjs(event.startTime.seconds*1000).format('H:mm')} 

                        ${event.endTime ? ' - ' + dayjs(event.endTime.seconds*1000).format('H:mm'): ''}`;
                    } else{
                        eventPrint+=`TBA`;
                    }
                    eventPrint+=`
                    </span>
                    <br/>
                    <span>${event.name}</span>
                </div>`
            }
            //manage multiday events
            if(event.isMultiDay){
                const eventEndTime = dayjs(event.endTime.seconds*1000);
                const startDay = eventStartTime.date();
                const startMonth = monthsDigits[eventStartTime.month()];
                const startYear = eventStartTime.year();
                
                //calculating the difference from the beginning of both start and end day - creating new instances of those.
                const endDay = eventEndTime.date();
                const endMonth = monthsDigits[eventEndTime.month()];
                const endYear = eventEndTime.year();
                const startDateNew = dayjs(`${startMonth}/${startDay}/${startYear}`,'MM/DD/YYYY');
                const endDateNew = dayjs(`${endMonth}/${endDay}/${endYear}`,'MM/DD/YYYY');
                const daysDifferenceNew = endDateNew.diff(startDateNew,'day');

                //iterate from the first day of event
                let iterateDates = dayjs(eventStartTime);
                let additionalClasses='';
                for (let index = 0; index <= daysDifferenceNew; index++) {
                    if(iterateDates.isSame(eventStartTime,'day'))
                        additionalClasses+='first-day'
                    else if(iterateDates.isSame(eventEndTime,'day'))
                        additionalClasses+='last-day'
                    eventPrint =             
                    `
                    <div class="multiday-event ${additionalClasses} ${esnEventClass} ${zarzadEventClass}">
                        <span>${iterateDates.isSame(eventStartTime,'day') ? event.name : ''}</span>
                    </div>`;
                    const eventDot = `<div class="dot ${esnEventClass} ${zarzadEventClass}"></div>`
                    $(`.calendar .day[data-day=${iterateDates.date()}][data-month=${iterateDates.month()}][data-year=${iterateDates.year()}] .all-events-mobile`).html(eventDot);
                    $(`.calendar .day[data-day=${iterateDates.date()}][data-month=${iterateDates.month()}][data-year=${iterateDates.year()}] .multiday-events`).append(eventPrint);
                    iterateDates = iterateDates.add(1,'day');
                    additionalClasses='';
                }
                
            //singleDay events
            }else{
                $(`.calendar .day[data-day=${getDay}][data-month=${getMonth}][data-year=${getYear}] .events-of-day`).append(eventPrint);
                // const currentEventsCount = $(`.calendar .day[data-day=${getDay}][data-month=${getMonth}][data-year=${getYear}] .all-events-mobile`).html();
                const eventDot = `<div class="dot ${esnEventClass} ${zarzadEventClass}"></div>`
                $(`.calendar .day[data-day=${getDay}][data-month=${getMonth}][data-year=${getYear}] .all-events-mobile`).html(eventDot);

            }

        });
    }

    selectDay = (e) =>{
        const dayData = e.currentTarget.dataset;
        let selectedDay = dayData.day;
        const selectedMonth = monthsDigits[dayData.month];
        const selectedYear = dayData.year;
        $(`.calendar .day.selected`).removeClass('selected');
        $(`.calendar .day[data-day=${selectedDay}][data-month=${dayData.month}][data-year=${selectedYear}]`).addClass('selected');
        if(selectedDay<=9)
            selectedDay="0"+selectedDay;
        const startDateNew = dayjs(`${selectedMonth}/${selectedDay}/${selectedYear}`,'MM/DD/YYYY');
        this.setState({chosenDay:startDateNew, showAllUpcomingEvents:false});
    }
    handleShowUpcomingEvents = () => {
        $(`.calendar .day.selected`).removeClass('selected');
        this.setState({showAllUpcomingEvents:true});
    }
    componentDidUpdate(){
        if(this.props.events.length!==0){
            this.clearEvents();
            this.displayEvents();
        }   
    }
    handleRemoveNotification = (e) =>{
        $('.esn-pending').addClass('hidden');
    }
    render() { 
        const {selectedMonth, chosenDay, showAllUpcomingEvents} =this.state;
        const {events} = this.props;
        const filteredEvents = events.filter((event)=>{
            const dateStartDayJS = dayjs(event.startTime.seconds*1000);
            let dateEndDayJS;
            const now = dayjs();
            if(event.isMultiDay)
                dateEndDayJS = dayjs(event.endTime.seconds*1000);
            if(event.esnOnly && !this.props.esn)
                return false;
            if(event.zarzadOnly && !this.props.isZarzad)
                return false;
            if(!showAllUpcomingEvents){
                //if multiDay
                if(event.isMultiDay && !chosenDay.isBetween(dateStartDayJS,dateEndDayJS,'day','[]'))
                    return false;
                // if nonMultiDay
                if(!event.isMultiDay && (dateStartDayJS.date()!==chosenDay.date() || dateStartDayJS.month()!==chosenDay.month() || dateStartDayJS.year()!==chosenDay.year()))
                    return false;
            }
            if(showAllUpcomingEvents && (!event.isMultiDay && dateStartDayJS.isBefore(now,'day')) || (showAllUpcomingEvents && event.isMultiDay && dateEndDayJS.isBefore(now,'day')))
                return false;
            //else, keep event.
            return true;
        })
        const eventsToday = filteredEvents.map((event)=>{
            const dateStart = new Date(event.startTime.seconds*1000)
            const dateEnd = (event.endTime && event.endTime!=='') ? new Date(event.endTime.seconds*1000): '';
            const day = dateStart.getDate() <=9 ? "0"+dateStart.getDate() : dateStart.getDate();
            const month = months[dateStart.getMonth()];
            const minutes = dateStart.getMinutes() <=9 ? "0"+dateStart.getMinutes() : dateStart.getMinutes();
            const hours = dateStart.getHours() <=9 ? "0"+dateStart.getHours() : dateStart.getHours();
            const getTime = hours+':'+minutes;
            let endTimePrint='';
            // if on the same day, display just the hour after '-'
            if(dateEnd!=='' && dateStart.getDate()===dateEnd.getDate() && dateStart.getMonth()===dateEnd.getMonth()){
                const endMinutes = dateEnd.getMinutes() <=9 ? "0"+dateEnd.getMinutes() : dateEnd.getMinutes();
                const endHours = dateEnd.getHours() <=9 ? "0"+dateEnd.getHours() : dateEnd.getHours();
                endTimePrint = ' - '+endHours+':'+endMinutes
            }
            //end date specified, but not on the same day.
            else if( dateEnd!==''){
                const endDay = dateEnd.getDate() <=9 ? "0"+dateEnd.getDate() : dateEnd.getDate();
                const endMonth = months[dateEnd.getMonth()];
                const endMinutes = dateEnd.getMinutes() <=9 ? "0"+dateEnd.getMinutes() : dateEnd.getMinutes();
                const endHours = dateEnd.getHours() <=9 ? "0"+dateEnd.getHours() : dateEnd.getHours();
                const endTime = endHours+':'+endMinutes;
                endTimePrint = ` - ${endDay} ${endMonth} ${endTime}`
            }
            const startTimePrint = `${day} ${month} ${getTime}`;
            const showEsnColors = event.esnOnly && this.props.esn ? true: false;
            const showZarzadColors = event.zarzadOnly && this.props.isZarzad ? true: false;
            const esnClass = showEsnColors ? 'esn-only' : '';
            const zarzadClass = showZarzadColors ? 'zarzad-only' : '';
            let pastEvent = !event.isMultiDay && Date.now()>event.startTime.seconds*1000;
            if(event.endTime && Date.now()>event.endTime.seconds*1000)
                pastEvent = true;
            const pastEventClass = pastEvent ? 'past-event' : '';
            return (
                <div key={event.id} className={`event-wrapper ${esnClass} ${pastEventClass} ${zarzadClass}`}>
                    <Link className="link" to={`events/${event.id}`} >
                    {event.interested && event.interested.length>0 ? <div className="user-counter"><div className="inside"><img src={user} alt="users"></img>{event.interested.length}</div></div> : ''}
                    <div className="image-wrapper">
                        <img className="banner-img" src={event.image==='' ? ESN_BACKGROUND : event.image } alt={event.name}/>
                    </div>
                        <div className="description">
                            <h5 className="name kelson-bold">{event.name}</h5>
                            <div className={`separator ${esnClass} ${zarzadClass}`}></div>
                            <div className="date">{
                                showZarzadColors ? 
                                <img className="icon" src={clockRed} alt="time"/> 
                                :showEsnColors ? 
                                <img className="icon" src={clockBlue} alt="time"/>
                                : <img className="icon" src={clock} alt="time"/>}
                                {(!event.hasOwnProperty('isTimeKnown') || (event.hasOwnProperty('isTimeKnown') && event.isTimeKnown===true)) ? (startTimePrint+endTimePrint) : 'TBA'}</div>
                            <div className="location">{showZarzadColors ? <img className="icon" src={markerRed} alt="location"/> : showEsnColors ? <img className="icon" src={markerBlue} alt="location"/>: <img className="icon" src={marker} alt="location"/>}{event.location}</div>
                            <div className={`see-more ${esnClass} ${zarzadClass}`}>See more</div>
                        </div>
                    </Link>

                </div>
            )
        });
        return ( 
                events.length===0 ? <Loading/> :
                <div className="container page-container col-md-10 calendar-col">
                    {this.props.waitingForConfirmation ? 
                    <div className="esn-pending">
                        <div className="star-container">
                            <img src={esn_logo} alt="esn star"/>
                        </div>
                        <div className="text">
                            Your ESN account privileges are pending approval.<br/> Please wait or contact someone from the ESN SGGW board.
                        </div>
                        <div className="cross" onClick={this.handleRemoveNotification}>
                            <img src={close} alt="cross - close notification"/>
                        </div>
                    </div>
                     : ''}
                    {/* <h3 className="page-title">This is the Calendar component</h3>  */}
                    <div className="calendar-container">
                    <div className="calendar-heading ">
                        <div className="arrow arrow-left" onClick={this.handleGoBackMonth}><img src={backArrow} alt="previous month" className="hidden-xs"/><img src={backArrowMobile} alt="previous month" className="visible-xs"/></div>
                        <div className="date">{selectedMonth.format('MMMM YYYY')}</div>
                        <div className="arrow arrow-right" onClick={this.handleGoForwardMonth}><img src={nextArrow} alt="next month" className="hidden-xs"/><img src={nextArrowMobile} alt="next month" className="visible-xs"/></div>
                    </div>
                    <div className="calendar ">
                        
                        <div className="weekDay"><span className="visible-xs">Mon</span><span className="hidden-xs">Monday</span></div>
                        <div className="weekDay"><span className="visible-xs">Tue</span><span className="hidden-xs">Tuesday</span></div>
                        <div className="weekDay"><span className="visible-xs">Wed</span><span className="hidden-xs">Wednesday</span></div>
                        <div className="weekDay"><span className="visible-xs">Thu</span><span className="hidden-xs">Thursday</span></div>
                        <div className="weekDay"><span className="visible-xs">Fri</span><span className="hidden-xs">Friday</span></div>
                        <div className="weekDay"><span className="visible-xs">Sat</span><span className="hidden-xs">Saturday</span></div>
                        <div className="weekDay"><span className="visible-xs">Sun</span><span className="hidden-xs">Sunday</span></div>
        
                        {this.createCalendarDays().map((date)=>{
                            const currentMonthNo = selectedMonth.month();
                            const isDateInCurrentMonthClass = date.month!==currentMonthNo ? 'other-month-day' : 'current-month-day';
                            let currentDay='';
        
                            //nie selected
                            const now = dayjs();
                            if(now.date()===date.day && now.month()===date.month && now.year()===date.year)
                                currentDay='current-day'
                            return (
                                <div key={date.day+'-'+date.month+'-'+date.year} onClick={this.selectDay} className={"day "+isDateInCurrentMonthClass+" "+ currentDay} data-day={date.day} data-month={date.month} data-year={date.year}>
                                    <div className="indicator"><span>{date.day}</span></div>
        
                                    {/* desktop calendar */}
                                    <div className="all-events hidden-xs">
                                        <div className="multiday-events"></div>
                                        <div className="events-of-day"></div>
                                    </div>
                                    {/* mobile calendar */}
                                    <div className="visible-xs all-events-mobile"></div>
                                </div>
                                )
                        })}
                        
                    </div>
                    <div className="calendar-footer ">

                        <button className="btn" onClick={this.handleShowUpcomingEvents}>Show all upcoming events</button>
                    </div>
                    </div>
                        <h3 className="list-header">
                            {showAllUpcomingEvents ? <span>Upcoming and ongoing events:</span> : <span>Events on {chosenDay.format('DD-MM-YYYY')}</span>}
                        </h3>
                    {
                        <div className="events-list container">
                        {eventsToday.length===0 ? <h4 className="no-events">No events planned</h4> : eventsToday}
                        </div>
                    } 
                
                </div>
         );
    }
}
 
export default Calendar;

