import React, { Component } from 'react';
import userIcon from '../images/userIcon.png'
import configure from '../images/configure.svg'
import firebase from '../firebase';
class SingleOfficeHour extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            editCurrent: false,
            editDefault: false,
            menuOpen: false,
            defaultTime:null,
            showChanges: null,
            isCancelled: null,
            modifiedTime: null
         }
    }
    toggleMenu  = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({menuOpen: !this.state.menuOpen});
    }
    closeMenu = (e) =>{
        e.preventDefault();
        e.stopPropagation();
        this.setState({menuOpen: false});
    }
    handleDefaultTimeChange  = (e) => {
        this.setState({defaultTime:e.target.value});
    }
    handleModifiedTimeChange  = (e) => {
        this.setState({modifiedTime:e.target.value});
    }
    handleCancel = (e) =>{
        this.setState({isCancelled: e.target.checked});
    }
    handleShowChanges  = (e)=>{
        this.setState({showChanges: e.target.checked});
    }
    handleApplyChanges = (e)=>{
        if(this.state.editDefault){
            firebase.firestore().collection('officeHours').doc(this.props.dayData.id).update({defaultTime:this.state.defaultTime}).then(()=>{
                this.setState({editDefault: false});
            });

        }else if(this.state.editCurrent){
            const newData = {};
            if(this.state.showChanges!=null) newData.showChanges=this.state.showChanges;
            if(this.state.isCancelled!=null) newData.isCancelled=this.state.isCancelled;
            if(this.state.modifiedTime!=null) newData.modifiedTime=this.state.modifiedTime;
            firebase.firestore().collection('officeHours').doc(this.props.dayData.id).update(newData).then(()=>{
                this.setState({editCurrent: false});
            });
        }
    }
    render() { 
        const {menuOpen, editCurrent, editDefault} = this.state;
        const {userId, day, name, defaultTime, modifiedTime, isCancelled, showChanges} = this.props.dayData;
        const {user, dayNumber, photo} = this.props;
        return ( 
        <div className={`day ${dayNumber}`}>
            {user.uid===userId ? 
            <div className="configure-container" >
                <img className="configure-icon" onClick={this.toggleMenu} src={configure} alt="configure icon"/>
                <div className={menuOpen ? 'submenu visible' : 'submenu'}>
                    <div className={editCurrent ? "menu-item active" : "menu-item"} onClick={()=>{this.setState({editDefault: false, editCurrent: !editCurrent})}}>Edit current</div>
                    <div className={editDefault ? "menu-item active" : "menu-item"} onClick={()=>{this.setState({editCurrent: false, editDefault: !editDefault})}} >Edit default</div>
                </div>
            </div> : ''}
            <h3 className="day-name">{day}</h3>
            <div className="photo"> {photo ? <div className="img-wrapper"><img src={photo} alt="Volunteer"/></div>: <div className="img-wrapper"><img src={userIcon} alt="user default icon"/></div>} </div>
            <h5 className="name">{name}</h5>
            <div className={showChanges ? "time crossed" : "time"}><span className="kelson-bold">Hours:</span> {editDefault ? <span><input className="form-control" type="text" name="defaultTime" placeholder={defaultTime} onChange={this.handleDefaultTimeChange}/></span>: <span className="kelson-normal hours">{defaultTime}</span>}</div>
            {editDefault ? <span className="disclaimer">Format: HH:MM - HH:MM</span> : ''}
            {isCancelled ? <div className="time changed cancelled"><span className="kelson-bold" >This week: </span>Cancelled</div> : showChanges ? <div className="time changed"><span className="kelson-bold">This week:</span> <span className="kelson-normal">{modifiedTime}</span></div> : ''}
            {editCurrent ?
            <div className="modify-container">
                <div className="modify-time-container"><label htmlFor="modifiedTime">New time: </label><input className="form-control" type="text" name="modifiedTime" placeholder={modifiedTime} onChange={this.handleModifiedTimeChange}/></div>
                <span className="disclaimer">Format: HH:MM - HH:MM</span>
                <div className="checkbox-container"><input className="form-control" type="checkbox" name="cancel" id="cancel" defaultChecked={isCancelled} onClick={this.handleCancel}/><label htmlFor="cancel">Cancel</label></div>
                <div className="checkbox-container"><input className="form-control" type="checkbox" name="showChanges" id="showChanges" defaultChecked={showChanges} onClick={this.handleShowChanges}/><label htmlFor="showChanges">Show changes</label></div>
            </div> : ''}

            {editCurrent || editDefault ? <button className="btn dark-blue-button" onClick={this.handleApplyChanges}>Apply changes</button> : ''}
        </div>
         );
    }
}
 
export default SingleOfficeHour;