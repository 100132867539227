import React,{useState} from 'react';
import firebase from '../firebase';
const ManageUsers = () => {
    const [waitingUsers, setUsers] = useState([]);
    const handleGetESNers = () =>{
        firebase.firestore().collection('users').where('type','==','esn').where('isConfirmedEsn','==',false).get().then((resp)=>{
            let waitingUsers = [];
            resp.forEach(user=>{
                const userData = {...user.data(),id:user.id};
                waitingUsers.push(userData);
            });
            setUsers(waitingUsers);
        })
    }
    const handleAcceptUser = (event, id) =>{
        firebase.firestore().collection('users').doc(id).update({isConfirmedEsn:true}).then(()=>{
            const updatedUsers = waitingUsers.filter(user => {
                if(id===user.id)
                    return false;
                return true;
            });
            setUsers(updatedUsers);
        });
    }
    return ( 
        <div className="container col-md-10 page-container">
            <h3>Manage users</h3>
             <button onClick={handleGetESNers}>Get applying ESNers</button>
             <div className="list">
             <div className="waiting-esner">
                 <div className="name">Name</div>
                 <div className="email">Email</div>
                 <div className="confirm">Confirm?</div>
             </div>
             {waitingUsers.map(user =>{
                 return (
                     <div key={user.id} className="waiting-esner">
                         <div className="name">{user.firstName} {user.lastName}</div>
                         <div className="email">{user.email}</div>
                         <button className="btn dark-blue-button" onClick={(event)=>handleAcceptUser(event, user.id)}>Confirm</button>
                     </div>
                 )
             })}
             </div>
        </div>
     );
}
 
export default ManageUsers;