import React from 'react';
import { Link, NavLink } from 'react-router-dom'
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';
import ESNSGGWLogo from '../images/esn_logo.png';
import ESNStar from '../images/esn_logo.png'
import faq from '../images/question.svg';
import more from '../images/more.svg'
import team from '../images/team1.svg'
import office from '../images/office.svg'
import discover from '../images/mapLogos/discover_gray.svg'
import discoverActive from '../images/mapLogos/discover_active.svg'
import officeActive from '../images/officeActive.svg'
import calendar from '../images/calendar.svg';
import moreActive from '../images/moreActive.svg';
import faqActive from '../images/questionActive.svg';
import teamActive from '../images/team1Active.svg'
import calendarActive from '../images/calendarActive.svg';
import loginActive from '../images/loginActive.svg';
import registerActive from '../images/registerActive.svg';
import addEvent from '../images/addEvent.svg';
import addEventActive from '../images/addEventActive.svg';
import login from '../images/login.svg';
import poland from '../images/poland.svg';
// import polandActive from '../images/polandActive.svg';
import logout from '../images/logout.svg';
import register from '../images/register.svg';
import tasks from '../images/tasks.svg';
import map from '../images/map.svg';
import mapActive from '../images/mapActive.svg';
import profile from '../images/user.svg';
import profileActive from '../images/userActive.svg';
import tasksActive from '../images/tasksActive.svg';
import forms from '../images/forms.svg';
import formsActive from '../images/formsActive.svg';
import firebase from '../firebase';
import {withFirestore} from 'react-redux-firebase';
import {compose} from 'redux';
import $ from 'jquery';

 const Header = (props) => {
  const handleManageToggle = () =>{
    $('.header .dropdown .submenu, .header .mobile-dropdown').toggleClass('active');
    // $('#fb-root .fb_dialog_content').toggleClass('menu-open');
    if($('.header .dropdown .submenu, .header .mobile-dropdown').hasClass('active')) $('#fb-root .fb_dialog_content').addClass('menu-open');
    else $('#fb-root .fb_dialog_content').removeClass('menu-open');
  }
  const handleLogout = ()=>{
    firebase.auth().signOut();
  }
  let user;
  if( props.user)
    user = props.user;
  
     return (      
<div>
{/* Desktop Header */}
  <Navbar bg="light" expand="lg" fixed="top" className="header hidden-xs navbar-expand-sm dark-version">
    <div className="navbar-brand">
          <Link to="/">
          <img className="brand-logo" src={ESNSGGWLogo} alt="ESN SGGW Logo"></img>
          <span className="brand-name">ESN SGGW</span>
          </Link>
    </div>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="ml-auto">
        {/* <div>{user==null ? 'asd' : user.country}</div> */}
        <NavLink exact={true} to="/">Home</NavLink>
        <NavLink exact={true} to="/events">Calendar</NavLink>
        <NavLink exact={true} to="/faq">FAQ</NavLink>
        <NavLink exact={true} to="/team">Team</NavLink>
        <NavLink exact={true} to="/map">Map</NavLink>
        {/* <NavLink exact={true} to="/discover-warsaw">Discover Warsaw | DE</NavLink> */}
        {/* <NavLink exact={true} to="/treasure-hunt">Treasure Hunt</NavLink> */}
        {/* <NavLink exact={true} to="/polish-students">For Polish Students</NavLink> */}
        <NavDropdown alignRight title="For Polish Students" id="basic-nav-dropdown">
          {<div className="dropdown-item"><NavLink exact={true} to="/polish-students/dolacz">Dołącz do nas!</NavLink></div> }
          {<div className="dropdown-item"><NavLink exact={true} to="/polish-students/erasmus">Program Erasmus+</NavLink></div> }
          {<div className="dropdown-item"><NavLink exact={true} to="/polish-students/mentor">Zostań Mentorem</NavLink></div> }
        </NavDropdown>
        <NavDropdown alignRight title="More" id="basic-nav-dropdown">
          {user && user.type==='esn' && user.isConfirmedEsn ? <div className="dropdown-item"><NavLink exact={true} to="/createEvent">Create Event</NavLink></div> : ''}
          {user && user.type==='esn' && user.isConfirmedEsn ? <div className="dropdown-item"><NavLink exact={true} to="/tasks">Tasks</NavLink></div> : ''}
          {/* {<div className="dropdown-item"><NavLink exact={true} to="/office-hours">Office Hours</NavLink></div> } */}
          {!user ? <div className="dropdown-item"><NavLink exact={true} to="/register">Register</NavLink></div>: ''}
          {!user ? <div className="dropdown-item"><NavLink exact={true} to="/login-box">Login</NavLink></div>: ''}
          {user && user.isAdmin ? <div className="dropdown-item"><NavLink exact={true} to="/manage-users">Manage Users</NavLink></div>: ''}
          {user ? <div className="dropdown-item" onClick={handleLogout}><Link to="">Logout</Link></div>: ''}
          {user ? <NavDropdown.Divider /> : ''}
          {user ? <div className="dropdown-item"><Link to="/profile">Profile</Link></div> : ''}
          <div className="dropdown-item"><NavLink exact={true} to="/forms">Feedback</NavLink></div>
        </NavDropdown>
      </Nav>
    </Navbar.Collapse>
  </Navbar>

  {/* Mobile Header */}
  <Navbar bg="light" expand="lg" fixed="bottom" className="header mobile visible-xs dark">
      <Nav className="ml-auto">
        {/* <div>{user==null ? 'asd' : user.country}</div> */}
        <NavLink exact={true} to="/events"><img className="non-active" src={calendar} alt="Calendar"/><img className="active-img" src={calendarActive} alt="Calendar"/><span>Calendar</span></NavLink>
        <NavLink exact={true} to="/faq"><img className="non-active" src={faq} alt="FAQ"/><img className="active-img" src={faqActive} alt="FAQ"/><span>FAQ</span></NavLink>
        <NavLink to="/" className="star"><img src={ESNStar} alt="ESNStar"/></NavLink>
        <NavLink exact={true} to="/map"><img className="non-active" src={map} alt="map icon"/><img className="active-img" src={mapActive} alt="map icon"/><span>Map</span></NavLink>

        {/* <NavLink exact={true} to="/office-hours"><img className="non-active" src={office} alt="Office Hours"/><img className="active-img" src={officeActive} alt="Office Hours"/><span>Office</span></NavLink> */}
        {/* <Link to="/profile"><img src={profile} alt="User"/><span>Profile</span></Link></div> */}
        <div className="nav-link dropdown mobile-dropdown" onClick={handleManageToggle}>
          <img className="non-active" src={more} alt="More"/>
          <img className="active-img" src={moreActive} alt="More"/>
          <div className="submenu">
            {/* {<div className="dropdown-item" ><NavLink exact={true} to="/discover-warsaw"><img className="non-active" src={discover} alt="Discover Warsaw"/><img className="active-img" src={discoverActive} alt="Discover Warsaw"/><span>DE</span></NavLink></div>} */}
            {/* {<div className="dropdown-item" ><NavLink exact={true} to="/treasure-hunt"><img className="non-active" src={treasure} alt="Treasure Hunt"/><img className="active-img" src={treasure} alt="Treasure Hunt"/><span>Treasure Hunt</span></NavLink></div>} */}
            {<div className="dropdown-item" ><NavLink exact={true} to="/polish-students"><img className="non-active" src={poland} alt="for polish students"/><img className="active-img" src={poland} alt="for polish students"/><span>Polish St.</span></NavLink></div>}
            {user && user.type==='esn' && user.isConfirmedEsn ? <NavLink exact={true} to="/createEvent"><img className="non-active" src={addEvent} alt="Create event icon"/><img className="active-img" src={addEventActive} alt="Create event icon"/><span>New Event</span></NavLink> : ''}
            {user && user.type==='esn' && user.isConfirmedEsn ? <NavLink exact={true} to="/tasks"><img className="non-active" src={tasks} alt="Tasks icon"/><img className="active-img" src={tasksActive} alt="Tasks icon"/><span>Tasks</span></NavLink> : ''}
            {user ? <div className="dropdown-item" ><NavLink exact={true} to="/profile"><img className="non-active" src={profile} alt="Profile icon"/><img className="active-img" src={profileActive} alt="Profile icon"/><span>Profile</span></NavLink></div>: ''}
            {/* {<div className="dropdown-item" ><NavLink exact={true} to="/forms"><img className="non-active" src={forms} alt="forms icon"/><img className="active-img" src={formsActive} alt="forms icon"/><span>Feedback</span></NavLink></div>} */}
            {<div className="dropdown-item" ><NavLink exact={true} to="/team"><img className="non-active" src={team} alt="team icon"/><img className="active-img" src={teamActive} alt="team icon"/><span>Team</span></NavLink></div>}
            {!user ? <NavLink exact={true} to="/login-box"><img className="non-active" src={login} alt="login icon"/><img className="active-img" src={loginActive} alt="login icon"/><span>Login</span></NavLink>: ''}
            {user ? <div className="dropdown-item" onClick={handleLogout}><Link to=""><img className="non-active" src={logout} alt="logout icon"/><span>Logout</span></Link></div>: ''}
          </div>
        </div>
      </Nav>
  </Navbar>
</div>

    );
 }
  
 export default compose(withFirestore)(Header);