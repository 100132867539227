import React from 'react';

const Forms = () => {
    return ( 
        <div className="container page-container forms-container col-md-10"> 
            <h3 className="page-title">Feedback</h3>
            <iframe title="erasmus-sggw feedback form" src="https://docs.google.com/forms/d/e/1FAIpQLSforuHVVNw5iY1PHaIRcnaiJIr-sqsd_DKxWGHSDDJKhYapgg/viewform?embedded=true" width="100%" height="951" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
        </div>
     );
}
 
export default Forms;