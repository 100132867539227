import React from 'react';
import ESNstar from '../../images/esn_logo.png';
import banner from '../../images/homeSlider/Erasmus-webinar.jpg';
const Erasmus = () => {
    const toggleQuestion = (e) =>{
        e.currentTarget.classList.toggle('show');
        const answer = e.currentTarget.querySelector('.answer');
        const answerHeight = answer.scrollHeight;
        const isCollapsed = answer.getAttribute('data-collapsed') === 'true';
        if(isCollapsed){
            answer.setAttribute('data-collapsed',false);
            answer.style.height=answerHeight+"px";
        }else{
            answer.setAttribute('data-collapsed',true);
            answer.style.height=0;
        }
        
    }
    return ( 

        <div className="article-wrapper polish-article-wrapper">

            <div className="image-wrapper container-fluid">
                <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" target="_blank" href="https://fb.me/e/20cVNSmxT"><img src={banner} alt=""/></a>
            </div>
            
            <div className="content">
            <h3 className="star-header"><img src={ESNstar} alt=""/>Program Erasmus+</h3>
            <div className="description">

            <p>Erasmus+ to program dla uczelni, ich studentów i pracowników. Jego celem jest wspieranie współpracy międzynarodowej szkół wyższych, umożliwianie studentom wyjazdów za granicę na część studiów i praktykę, promowanie mobilności pracowników uczelni, stwarzanie uczelniom licznych możliwości udziału w projektach wraz z partnerami zagranicznymi.</p>

            <h5 className="bold">Wymagania wobec studentów</h5>
            <ul>
            <li>ukończony pierwszy rok studiów pierwszego stopnia</li>
            <li>w momencie wyjazdu kandydat musi mieć status studenta SGGW (nie może być na urlopie dziekańskim, zdrowotnym, okolicznościowym itp)</li>
            <li>znajomość języka obcego na poziomie min. B1 (obowiązującego w instytucji przyjmującej) potwierdzona certyfikatem (np. FCE, CAE, CPE, TOEFL, LCCI) lub podczas egzaminu organizowanego przez BWM lub matura z języka obcego na poziomie rozszerzonym min. 50 %</li>
            <li>posiadanie dodatkowych środków na dofinansowanie pobytu. Student wyjeżdżający w ramach programu Erasmus+ otrzymuje stypendium, które ma na celu wyrównanie różnic w kosztach ponoszonych w Polsce i podczas pobytu za granicą, a nie pokrycie kosztów wyjazdu jako takiego. W związku z tym student musi się liczyć z koniecznością poniesienia kosztów związanych z pobytem na studiach</li>
            </ul>
            <p>Więcej informacji znajdziesz na <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" target="_blank" href="https://www.sggw.pl/programy-edukacyjne/erasmus-llp_">stronie uczelni</a> oraz kontaktując się z <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" target="_blank" href="https://www.sggw.pl/biuro-wspolpracy-miedzynarodowej">Biurem Współpracy Międzynarodowej</a></p>
            <p>Koniecznie zajrzyjcie również na grupy na Facebooku: </p>
            <ul>
                
                <li><a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/groups/1701920496693568/">Erasmus+ na SGGW</a> </li>
                <li><a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/groups/149176352362514/?ref=br_rs">Studenckie wyjazdy z Erasmus+</a> (ogólnopolska)</li> 
            </ul>
            <p className="bold">Zachęcamy też do zapoznania się z odpowiedziami do najczęściej zadawanych pytań związanych z wyjazdami w ramach Programu Erasmus+ poniżej!</p>
            </div>
            <div className="polish-faq">
                <h3>Najczęściej zadawane pytania</h3>
                <section className="polish ">
                    {/* <div className="section-title">Studia - Przed wyjazdem</div> */}
                    {/* <div className="question" onClick={toggleQuestion}>
                        <div className="title" >Kiedy przeprowadzana jest rekrutacja?</div>
                        <div className="answer" data-collapsed={true}>
                            A1
                        </div>
                    </div> */}
                    {/* <div className="question" onClick={toggleQuestion}>
                        <div className="title" >Czy mogę wyjechać na semestrze na którym mam seminarium dyplomowe?</div>
                        <div className="answer" data-collapsed={true}>
                            A2
                        </div>
                    </div> */}
                    <div className="question" onClick={toggleQuestion}>
                        <div className="title" >Czy studia za granicą w ramach wymian Erasmus+ są płatne?</div>
                        <div className="answer" data-collapsed={true}>
                            NIE - co więcej, Erasmus daje możliwość zrealizowania semestru ZA DARMO na uczelniach PŁATNYCH (a więc: stypendysta programu Erasmus+ nie musi płacić czesnego na uczelni zagranicznej, które jest obligatoryjne dla „zwykłych” studentów tej uczelni)
                        </div>
                    </div>
                    <div className="question" onClick={toggleQuestion}>
                        <div className="title" >Ile wynosi dofinansowanie dla wyjeżdżających?</div>
                        <div className="answer" data-collapsed={true}>
                        Miesięczna wysokość dofinansowania zależy od wybranego kraju docelowego - w roku akademickim 2020/2021 dla poszczególnych krajów wynosi ona od 450 do 520 EUR/mies.
<br/><br/>
80% ogólnej kwoty dostajemy przed wyjazdem, 20% po powrocie i dopełnieniu formalności powyjazdowych.
<br/><br/>
DODATKOWO, studenci SGGW otrzymują również dofinansowanie kosztów podróży, w wysokości od 600 do 1500 PLN.
<br/><br/>
Dokładne informacje na temat dofinansowania (również dla studentów otrzymujących stypendium socjalne oraz wsparcie dla osób niepełnosprawnych), a także aktualne stawki stypendium dla wyjeżdżający na Erasmusa znajdują się na stronie Biura Współpracy Międzynarodowej SGGW: <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" target="_blank" href="https://tiny.pl/tr57n">https://tiny.pl/tr57n</a>

                        </div>
                    </div>
                    <div className="question" onClick={toggleQuestion}>
                        <div className="title" >Czy wyjazd za granicę sprawi, że będę musiał odrobić „opuszczony” semestr po powrocie do Polski?</div>
                        <div className="answer" data-collapsed={true}>
                        NIE, semestr spędzony za granicą jest zaliczany tak samo jak semestr spędzony na SGGW (oczywiście pod warunkiem dobrania i zaliczenia przedmiotów na uczelni zagranicznej i dopełnieniu wszystkich formalności).
                        </div>
                    </div>
                    <div className="question" onClick={toggleQuestion}>
                        <div className="title" >Czy muszę znać język angielski, żeby wyjechać na Erasmusa?</div>
                        <div className="answer" data-collapsed={true}>
                        Aby wyjechać na Erasmusa wymagana jest znajomość języka wykładowego na danej uczelni zagranicznej. Większość uczelni partnerskich oferuje przedmioty w języku angielskim, jednak są też takie, gdzie językiem wymaganym jest język danego kraju. Informacje o tym, w jakim języku prowadzone są zajęcia znajdują się w tabeli z ofertą wydziałową. (patrz pytanie "Gdzie mogę wyjechać?")
                        </div>
                    </div>

                    <div className="question" onClick={toggleQuestion}>
                        <div className="title" >Czy seminaria dyplomowe muszę zaliczyć za granicą?</div>
                        <div className="answer" data-collapsed={true}>
                        Warunki zaliczeń seminariów powinny zostać ustalone bezpośrednio z osobą odpowiedzialną za seminaria oraz promotorem (zwykle możliwe jest zaliczenie seminariów drogą mailową).
                        </div>
                    </div>
                    <div className="question" onClick={toggleQuestion}>
                        <div className="title" >Co muszę zrobić aby dostać się na Erasmusa?</div>
                        <div className="answer" data-collapsed={true}>
                        Wszystkie niezbędne informacje na temat rekrutacji znajdziesz na stronie Biura Współpracy Międzynarodowej SGGW <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" target="_blank" href="https://tiny.pl/tr57n">https://tiny.pl/tr57n</a> – koniecznie zapoznaj się ze wszystkimi informacjami, wymaganiami i procedurami.
                        <br/><br/>
                        <span className="bold">W skrócie:</span>
                        <br/><br/>
                        Pierwszym krokiem do zakwalifikowania się na studia wymienne Erasmus+ jest uzupełnienie Formularza Zgłoszeniowego i wysłanie go do Koordynatora Wydziałowego (informacje o tym, kto jest Koordynatorem Wydziałowym znajdziesz w tabeli z ofertą miejsc na wydziałach – patrz pytanie "Gdzie mogę wyjechać?").
                        <br/><br/>
                        Drugim etapem jest przystąpienie do egzaminu językowego.
                        <br/><br/>
                        <span className="bold">UWAGA:</span> Osoby, które posiadają certyfikat językowy, nie muszą przystępować do egzaminu – aby upewnić się, czy Twój certyfikat zostanie zaakceptowany przy rekrutacji zajrzyj na stronę Biuro Współpracy Międzynarodowej SGGW: „Nabór na studia…” - „Etap II - Egzamin z języka obcego”, a w razie dalszych wątpliwości skontaktuj się z pracownikiem BWM.
                        <br/><br/>
                        Po rozpatrzeniu aplikacji przez koordynatora wydziałowego zostaniesz poinformowany o wyniku rekrutacji, a także o kolejnych etapach procedury aplikacyjnej (przygotowywanie i dostarczenie do BWM i Dziekanatu odpowiednich dokumentów - patrz pytanie "nie pamiętam jak się nazywało ale to o tym co zrobić żeby zaliczyc bezproblemowo semestr po powrocie

                        </div>
                    </div>

                    <div className="question" onClick={toggleQuestion}>
                        <div className="title" >Dlaczego w Formularzu Zgłoszeniowym wpisuję 2 uczelnie?</div>
                        <div className="answer" data-collapsed={true}>
                        Wypełniając Formularz Zgłoszeniowy wskazujemy uczelnie pierwszego i drugiego wyboru – oznacza to, że jeśli nie uda nam się otrzymać miejsca na uczelni pierwszego wyboru, wciąż mamy szansę na wyjazd na drugą uczelnię.
                        <br/><br/>
                        O kolejności przyznawania miejsc decydują zwykle trzy kryteria:
                        <ul>
                            <li>wynik z egzaminu językowego</li>
                            <li>średnia ocen</li>
                            <li>zasługi dla uczelni</li>
                        </ul>
                        </div>
                    </div>


                    {/* asd */}
                    <div className="question" onClick={toggleQuestion}>
                        <div className="title" >Gdzie mogę wyjechać?</div>
                        <div className="answer" data-collapsed={true}>
                            Informację o tym na jakie uczelnie możesz wyjechać ze swojego wydziału znajdziesz na stronie <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" target="_blank" href="https://www.sggw.pl/programy-edukacyjne/erasmus-llp_">PROGRAMY EDUKACYJNE-WYJAZDY STUDENTÓW SGGW:</a> „Nabór na studia…” - „Oferta miejsc na wydziałach”
                        </div>
                    </div>
                    <div className="question" onClick={toggleQuestion}>
                        <div className="title" >Czy mogę jechać na Erasmusa na uczelnię, której nie ma na liście dla mojego wydziału?</div>
                        <div className="answer" data-collapsed={true}>
                        Teoretycznie istnieje taka możliwość, jednak za względu na skomplikowane procedury zwykle nie dopuszcza się takich wyjazdów. W takim przypadku warto natomiast zorientować się, czy dana uczelnia potencjalnie mogłaby zawrzeć umowę partnerską z SGGW, a następnie skontaktować się z Biurem Współpracy Międzynarodowej SGGW w celu omówienia tej kwestii.
                        </div>
                    </div>
                    <div className="question" onClick={toggleQuestion}>
                        <div className="title" >Czy mogę jechać na Erasmusa do Azji lub Ameryki?</div>
                        <div className="answer" data-collapsed={true}>
                        Program Erasmus+ i inne programy wymian międzynarodowych dotyczą nie tylko państw Europy, ze względu jednak na to, że rekrutacja, oferta wydziałowa i inne aspekty formalne różnią się od tych, które obowiązują wyjeżdżających do krajów europejskich, polecamy bezpośredni kontakt z Biurem Współpracy Międzynarodowej SGGW w celu uzyskania pewnych informacji.
                        </div>
                    </div>
                    <div className="question" onClick={toggleQuestion}>
                        <div className="title" >Jak mogę upewnić się, że powrocie z Erasmusa nie będę mieć problemów z zaliczeniem semestru?</div>
                        <div className="answer" data-collapsed={true}>
                        Najważniejszą kwestią jest odpowiednio wczesne i staranne przygotowanie Learning Agreement, Karty Uzgodnień i Karty Porównań – są to dokumenty, w których zawarte jest zestawienie przedmiotów na uczelni macierzystej oraz przedmiotów z uczelni goszczącej, które zapewnią zaliczenie semestru na SGGW.
                        <br/><br/>
                        Jeszcze przed wysłaniem Formularza Zgłoszeniowego upewnij się, że uczelnia którą wybrałeś ma w ofercie przedmioty, które zostaną zaakceptowane przez Koordynatora Wydziałowego/Dziekana Twojego wydziału.
                        <br/><br/>
                        Jeśli na stronie uczelni goszczącej nie możesz znaleźć informacji na temat przedmiotów, lub są one niedokładne – skontaktuj się z tamtejszym Biurem Erasmusa, koordynatorem Erasmusa, lub koordynatorem wydziału na który się wybierasz i poproś o wysłanie listy przedmiotów, które będą prowadzone na semestrze w którym planujesz wyjechać (<span className="bold">UWAGA:</span> to ważne, aby dokładnie sprawdzić kiedy dany przedmiot jest prowadzony - zdarza się, że uczelnie podają rozpiski roczne, które nie uwzględniają podziału na semestry).
                        <br/><br/>
                        Z wstępnie przygotowanym Learning Agreement i Kartą Porównań udaj się do Dziekana i/lub Koordynatora Wydziałowego, aby potwierdzić uznawalność wybranych przez Ciebie przedmiotów.
                        </div>
                    </div>
                    <div className="question" onClick={toggleQuestion}>
                        <div className="title" >Co zrobić, jeśli uczelnia na którą chce wyjechać nie realizuje tych samych przedmiotów co SGGW?</div>
                        <div className="answer" data-collapsed={true}>
                        Kwestia wymienności przedmiotów jest indywidualna dla każdego wydziału – na części z nich wymaga się, aby przedmioty pokrywały się w całości, „jeden do jednego”, na części wybór przedmiotów jest bardziej elastyczny. Niezbędny w tym przypadku jest kontakt z Dziekanem i/lub Koordynatorem Wydziałowym, aby upewnić się, że przedmioty które są dostępne na uczelni goszczącej zapewnią nam zaliczenie semestru na uczelni macierzystej.
                        </div>
                    </div>
                    <div className="question" onClick={toggleQuestion}>
                        <div className="title" >Czy mogę jechać ze znajomym w to samo miejsce?</div>
                        <div className="answer" data-collapsed={true}>
                        W tabeli z ofertą miejsc na wydziałach, znajduje się informacja na temat tego ile osób z danego wydziału może przyjąć dana uczelnia goszcząca. Teoretycznie więc, jeśli miejsc jest więcej niż jedno, istnieje możliwość wyjazdu z inna osobą z tego samego wydziału. Sytuacja ma się podobnie, w przypadku kiedy znajomy studiuje na innym wydziale, pod warunkiem oczywiście, że jego wydział również oferuje miejsce na danej uczelni.
                        </div>
                    </div>
                    <div className="question" onClick={toggleQuestion}>
                        <div className="title" >Czy mogę wyjechać na Erasmusa podczas urlopu dziekańskiego?</div>
                        <div className="answer" data-collapsed={true}>
                        Na studia w ramach Erasmus+ wyjechać może jedynie osoba z aktywnym statusem studenta, nie można wyjechać więc będąc na urlopie dziekańskim. MOŻNA natomiast w jego trakcie przystąpić do rekrutacji (wysłać formularz zgłoszeniowy i przystąpić do egzaminu językowego), pod warunkiem, że taka osoba będzie posiadała aktywny statusu studenta w momencie wyjazdu.
                        </div>
                    </div>
                    <div className="question" onClick={toggleQuestion}>
                        <div className="title" >Czy mogę wyjechać na Erasmusa mając warunek?</div>
                        <div className="answer" data-collapsed={true}>
                        Jest to kwestia indywidualna i decyzja w tej sprawie zależy wyłącznie od Dziekana Twojego wydziału.
                        </div>
                    </div>
                    <div className="question" onClick={toggleQuestion}>
                        <div className="title" >Co zrobić, jeśli nie uda mi się zaliczyć przedmiotu za granicą?</div>
                        <div className="answer" data-collapsed={true}>
                        W sytuacji, kiedy na uczelni partnerskiej nie uzyskano zaliczenia przedmiotu, zwykle wymagane jest zaliczenie go w Polsce – po powrocie, w sesji poprawkowej, indywidualnie bądź w kolejnym roku akademickim – aby ustalić warunki zaliczenia, konieczny będzie kontakt z Dziekanem a także (prawdopodobnie) z osobą prowadzącą przedmiot będący odpowiednikiem tego, którego nie udało się zaliczyć.
                        </div>
                    </div>
                    <div className="question" onClick={toggleQuestion}>
                        <div className="title" >O czym muszę pamiętać wyjeżdżając na Erasmusa?</div>
                        <div className="answer" data-collapsed={true}>
                        - odpowiednio wczesnym i starannym przygotowaniu dokumentów: Learning Agreement, Karty Uzgodnień i Karty Porównań
                        <br/><br/>
                        - osobistym dopilnowaniu wszystkich formalności, zarówno na uczelni goszczącej jak i na SGGW
                        <br/><br/>
                        - ubezpieczeniu zdrowotnym
                        <br/><br/>
                        - zadawaniu dużej ilości pytań, na przykład na grupie FB <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/groups/1701920496693568">Erasmus+ na SGGW</a>
                        </div>
                    </div>
                    <div className="question" onClick={toggleQuestion}>
                        <div className="title" >Gdzie mogę szukać informacji/pomocy w kwestii wyjazdu?</div>
                        <div className="answer" data-collapsed={true}>
                            Pierwszym źródłem informacji dla studentów wyjeżdżających, jest Biuro Współpracy Międzynarodowej SGGW – <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" target="_blank" href="https://tiny.pl/gl8hx">tutaj</a>. Znajdziesz tam niezbędne informacje na temat procedury aplikacyjnej, wszelkich formalności związanych z wyjazdem, a także dane kontaktowe BWM.
                        </div>
                    </div>
                </section>
                {/* <section className="polish">
                    <div className="section-title">Praktyki</div>
                    <div className="question" onClick={toggleQuestion}>
                        <div className="title" >Czy istnieje baza firm, gdzie mogę znaleźć ciekawe praktyki w ramach programu Erasmus+ ? </div>
                        <div className="answer" data-collapsed={true}>
                            A1
                            https://erasmusintern.org/traineeships
                        </div>
                    </div>
                </section> */}
            </div>
            </div>
        </div>
     );
}
 
export default Erasmus;