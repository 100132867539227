import React from 'react';
import banner from '../../images/homeSlider/mentor.jpg';
import ESNstar from '../../images/esn_logo.png'
const Pepehousing = () => {
    return ( 
        <div className="article-wrapper">
            <div className="image-wrapper container-fluid">
                <img src={banner} alt=""/>
            </div>
            <div className="content">
                <h3 className="star-header"><img src={ESNstar} alt=""/>Mentor programme</h3>
                <div className="description">
                    Under construction
                </div>
            </div>
        </div>
     );
}
 
export default Pepehousing;