import React from 'react';
import banner from '../../images/homeSlider/pepehousing.jpg';
import ESNstar from '../../images/esn_logo.png'
const Pepehousing = () => {
    return ( 
        <div className="article-wrapper">
            <div className="image-wrapper container-fluid">
                <img src={banner} alt=""/>
            </div>
            <div className="content">
                <h3 className="star-header"><img src={ESNstar} alt=""/>Pepe Housing - accomodation for students and expats</h3>
                <div className="description">
                We are happy to announce <span className="bold">Pepe Housing</span> as an official ESN SGGW partner for Erasmus accommodation in Poland. <br/><br/>

                Do you want to avoid the frustrations of trying to communicate with a landlord who only speaks the confusing, Polish language? <br/>
                Do you want to make your search for the perfect flat easier by making a good use of a platform created by people who experienced all possible problems related to living abroad? <br/>
                Do you want to support Erasmus Student Network by trusting its official partner? <br/>

                If you answered yes to any of those questions, and you are not interested in living in the SGGW dormitories, we recommend <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" href="https://pepehousing.com/" target="_blank">Pepe Housing</a> to safely and easily book a flat or a room in Warsaw. 

                Use promocode <span className="bold">ESNSGGW</span> to get 10% off booking fee! <br/><br/>

                You can find more information here: <br/>
                <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" href="http://esn.pl/en/partners/pepe-housing" target="_blank">http://esn.pl/en/partners/pepe-housing</a><br/>
                <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" href="https://pepehousing.com/" target="_blank">pepehousing.com</a>
                </div>
            </div>
        </div>
     );
}
 
export default Pepehousing;