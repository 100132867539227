import React, {useState} from 'react';
import { Link, NavLink } from 'react-router-dom'
import { useParams, useHistory } from 'react-router-dom';
import Pepehousing from './articles/Pepehousing.js';
import Mentor from './articles/Mentor_PL.js';
import AboutUs from './articles/AboutUs.js';
import Workgroups from './articles/Workgroups.js';
import JoinUs_PL from './articles/JoinUs_PL.js';
import Erasmus from './articles/Erasmus.js';

//Erasmus-FAQ
// Dolacz do nas
// Program Erasmus
// Zostań Mentorem

// import Pepehousing from './articles/Pepehousing';
const Polish = () => {
    const [menuOn, toggleMenu] = useState(false);
    const [articleName,setArticle] = useState('');
    const params = useParams();
    const history = useHistory();
    if(!(Object.keys(params).length === 0 && params.constructor === Object)){
        if(articleName!=params.articleName){
            setArticle(params.articleName);
        }
    }
    const renderArticle = (articleName) =>{
        switch(articleName){
            case 'erasmus':
                return <Erasmus/>
            case 'dolacz':
                return <JoinUs_PL/>
            case 'rekrutacja':
                return <JoinUs_PL/>
            case 'mentor':
                return <Mentor/>
            case 'workgroups':
                return <Workgroups/>
            default: return <JoinUs_PL/>
        }
    }
    return ( 
        <div className="articles-container">
            <div className={menuOn ? "articles-menu active" : "articles-menu"}>
                <div className="title">
                    Dla Polskich Studentów
                </div>
                <div className="hamburger" onClick={()=>{toggleMenu(!menuOn)}}><div className="box"><div className="hamburger-inner"></div></div></div>

            </div>
            <div className={menuOn ? "articles-list active" : "articles-list"} onClick={()=>toggleMenu(false)}>
                <NavLink to="/polish-students/dolacz" className="list-item">Dołącz do nas!</NavLink>
                <NavLink to="/polish-students/erasmus" className="list-item">Program Erasmus+</NavLink>
                <NavLink to="/polish-students/mentor" className="list-item">Zostań Mentorem</NavLink>

            </div>
            <div className="container page-container col-md-12 articles">
               {renderArticle(articleName)}
            </div>
        </div>
     );
}
 
export default Polish;