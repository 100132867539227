import React from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';
const MessengerBubble = () => {
    return ( 
        <div className="messenger-bubble">
            <MessengerCustomerChat
            pageId="146760465383748"
            appId="741884056375423"
            />
        </div>
     );
}
 
export default MessengerBubble;