import React from 'react';
import logoESN from '../images/esn_logo.png';
const Loading = (props) => {
    return ( 
        <div className="loading-container">
        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
      {/* <img className="rotating esn-logo" src={logoESN} alt=""/>
      {props.noText ? null : <h2 className="loading-text">Loading...</h2>} */}
      </div>
     );
}
 
export default Loading;