import React, {useState} from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import mapStyles from './mapStyles.js';
import sidebarToggleIcon from '../images/left_fat.svg';
import Loading from './Loading';
import firebase from '../firebase';
import MessageModal from './MessageModal';
import Confetti from 'react-dom-confetti';


const places=[
    {name: "Old Town / Royal Castle",address: "plac Zamkowy 4, 00-277 Warsaw",lat:52.248903,lng:21.011819,icon: "de_pin.png",website: "https://warsawtour.pl/en/project/old-town-2/",icon2: "de.png",description: "The Old Town is one of the most visited places in the capital. On Castle Square stands column of King Sigismund III Vasa. Attention is also drawn to the Royal Castle in which was passed the first constitution in Europe. There are two gardens behind. In the Old Town Square stands a statue of the Warsaw Mermaid, the emblem and guardian of the city."},
    {name: "ZOO",address: "Ratuszowa 1/3, 03-461 Warsaw",lat:52.25846775,lng:21.02205910849126,icon: "de_pin.png",website: "https://zoo.waw.pl/",icon2: "de.png",description: "The Warsaw ZOO is located alongside the Vistula River which history is long and rough. It was rebuilt after World War II and animals were brought back. Now the ZOO covers about 40 hectares and it is home to over 11,000 animals representing more than 500 species. There’s also a  bird sanctuary -  a rehabilitation center for endangered bird species."},
    {name: "Palm",address: "rondo gen. Charles'a de Gaulle'a, 00-001 Warsaw",lat:52.231667,lng:21.020833,icon: "de_pin.png",website: "https://www.inyourpocket.com/warsaw/the-palm-tree_43640v",icon2: "de.png",description: "Unfortunately, the big tropical palm that we can find in the middle of a concrete city isn’t real, but it is for sure one of the most recognizable places in Warsaw. It is in fact, an art installation, which can encourage reflection on art, but also be a great background for photos."},
    {name: "PKiN",address: "plac Defilad 1, 00-901 Warsaw",lat:52.2317641,lng:21.006389,icon: "de_pin.png",website: "https://pkin.pl/en/home/",icon2: "de.png",description: "Palace of Culture and Science is worth visiting, not only because it is the second tallest building in Poland with the extensive sightseeing terrace on 30th floor, but also because interesting events and exhibitions are taking place there. "},
    {name: "Palace in Wilanów",address: "Stanisława Kostki Potockiego 10/16, 02-958 Warsaw",lat:52.16499719314605,lng:21.0904654787607,icon: "de_pin.png",website: "https://www.wilanow-palac.pl/",icon2: "de.png",description: "Wilanów Palace is a baroque royal residence with the royal garden around, where we can admire the unchanged architectural form as well as historical and artistic values."},
    {name: "Powązki",address: "ul. Powązkowska 1 01-797 Warsaw",lat:52.2609946,lng:20.9564569,icon: "de_pin.png",website: "http://starepowazki.sowa.website.pl/",icon2: "de.png",description: "The Powązki Cemetery is a unique place, the oldest one in Warsaw, where we can find graves of many honored Poles, writers, artists, scholars who made merit for Warsaw, but also people who fought for Poland's independence during World War II."},
{name: "National Stadium",address: "al. Księcia Józefa Poniatowskiego 1, 03-901 Warsaw",lat:52.239444,lng:21.045556,icon: "de_pin.png",website: "https://www.pgenarodowy.pl/",icon2: "de.png",description: "The National Stadium is one of the most characteristic places in Warsaw. It was built for the needs of the UEFA Euro 2012 championship. Now it is open to various events, not only the sports ones, and it makes an impression with its size, structure and light effects especially in the evening."},
{name: "Mermaid Monument",address: "Generała George’a Smitha Pattona, 00-390 Warsaw",lat:52.24,lng:21.031389,icon: "de_pin.png",website:"",icon2: "de.png",description: "The monument of the Warsaw Mermaid is a symbol of the city and  something that distinguish it. According to legend, it is half a woman and a half fish that is considered to be the city's protector against all evil."},
{name: "Dachshund",address: "ul. Kijowskiej 11",lat:52.25333295,lng:21.049075484368707,icon: "de_pin.png",website: "",icon2: "de.png",description: "Jamnik is the the longest building in Warsaw, a block of flats which length is 508 metres. There are 430 apartments in the structure. In order to commemorate the name Jamnik, which means dachshund in Polish, there was a mural painted at Marszałkowska Street in 2017."},
{name: "Keret's House",address: "Żelazna 74, 00-875 Warsaw",lat:51.09977035,lng:17.00379978570055,icon: "de_pin.png",website: "http://www.domkereta.pl/",icon2: "de.png",description: "Dom Kereta is the narrowest building in the capital of Poland. The length of the construction is only 152 centimetres(about 60 inches), but is fully well-adjusted to live in. It was created for Edgar Keret who is famous for his short stories and graphic novels. Apart from Keret 40 different artists have had a chance to live there at least for a short time so far."},
{name: "Doll house",address: "Hoża 70 00-682 Warsaw",lat:52.2252848,lng:21.0091724,icon: "de_pin.png",website: "",icon2: "de.png",description: "Domek dla lalek is a tenement house built in 1910. It looks like a great dollhouse, but when you are looking for it, be observant, because it is obscured by higher buildings. It was an exclusive cosmetic factory long time ago."},
{name: "Warsaw Uprising Museum",address: "Grzybowska 79, 00-844 Warsaw",lat:54.1619164,lng:15.5471173,icon: "de_pin.png",website: "https://www.1944.pl/",icon2: "de.png",description: "The Museum is a tribute of Warsaw’s residents to those who fought and died for independent Poland and its free capital. The exhibition depicts fighting and everyday life during the Rising, keeping occupation terror in the background."},
{name: "The Museum of the History of Polish Jews  - POLIN ",address: "Mordechaja Anielewicza 6, 00-157 Warsaw",lat:52.24932145,lng:20.993173619961663,icon: "de_pin.png",website: "http://polin.pl/en",icon2: "de.png",description: "The Museum is a modern institution of culture - it is a historical museum which presents the 1000 years of Jewish life in the Polish lands. It is also a place of meeting and dialogue among those who wish to explore the past and present Jewish culture, those eager to draw conclusions for the future from Polish-Jewish history, and finally those who are ready to face the stereotypes and oppose xenophobia and nationalistic prejudices that threaten today’s societies. "},
{name: "Varso's tallest building",address: "Chmielna 69, 00-801 Warsaw",lat:52.22885615,lng:21.000114838801288,icon: "de_pin.png",website: "",icon2: "de.png",description: "It is a neomodern office building complex, currently under construction. It has already become the tallest building in Poland and in the European Union at 310m in height."},
{name: "Stone steps of Street",address: "rynek Starego Miasta 26, 00-272 Warsaw",lat:52.2503727,lng:21.0121091,icon: "de_pin.png",website: "",icon2: "de.png",description: "Stone Stairs is a narrow and steep downtown street, connecting the upper part of the Old Town with the Vistula River. It is surrounded by side elevations of tenement houses. "},
{name: "Palace in Łazienki",address: "Agrykoli 1, 00-460 Warsaw",lat:52.214722,lng:21.035556,icon: "de_pin.png",website: "https://www.lazienki-krolewskie.pl/en/architektura/palac-na-wyspie",icon2: "de.png",description: "Palace on the Isle is located in the central part of Łazienki Park, in an artificial island and surrounded by a poud. In 18th century Palace was a summer residence for Stanislaw August Poniatowski who wanted to create a place based on Italian architecture. During his stay there, every week he organised a meeting called 'Thursday dinners' and gathered polish writers and scientists."},
{name: "Holy Cross Churche",address: "Krakowskie Przedmieście 3, 00-047 Warsaw",lat:52.238611,lng:21.016667,icon: "de_pin.png",website: "http://swkrzyz.pl/",icon2: "de.png",description: "The church was built in the end of 17th century and rebuilt after Second World War, when over half of building structure was destroyed. Most famous from the fact that urns containing hearts of renowned Polish people, like Frederick Chopin and Władysław Reymont, are located in building pillars. "},
{name: "The main gate of University of Warsaw ",address: "ul. Krakowskie Przedmieście 26/28",lat:52.239722,lng:21.016944,icon: "de_pin.png",website: "",icon2: "de.png",description: "The gate is the main entrance to University of Warsaw Central Campus where many of departments, administration and university authorities are located. It contains two sculptures: Urania and Athena from Greek mythology, symbolising knowledge and peace. In 1984 The Gate was entered into the Registry of Objects and Cultural Heritage in Poland."}
];  




const containerStyle = {
    width: 'auto',
    height: 'calc(100vh - 65px)'
  };
   
  const center = {
    lat: 52.237049,
    lng: 21.017532
  };
  const options={
      styles:mapStyles,
      disableDefaultUI:true,
      zoomControl: true,
      clickableIcons: false
  }

//   var image = {
//     url:testIcon, scaledSize: new google.maps.Size(15,25)
//   }
const DiscoverEurope = (props) => {

    const mapRef = React.useRef();
    const [sidebarOpen, toggleSidebar] = useState(window.innerWidth<768 ? false: true);
    const [selected, setSelected]=useState(null);
    const onMapLoad = React.useCallback((map)=>{
        mapRef.current=map;
    })
    const {isLoaded, loadError}=useLoadScript({
        googleMapsApiKey:process.env.REACT_APP_GOOGLE_MAPS_API_KEY
        // googleMapsApiKey:process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    });

    const handleOpenSidebar = () =>{
        toggleSidebar(!sidebarOpen);
    }
    const handleClickList = (place) =>{
        setSelected(place);
        const bounds = new window.google.maps.LatLngBounds({lat:place.lat, lng:place.lng});
        mapRef.current.fitBounds(bounds);
        mapRef.current.setZoom(12);
    }
    
    const config = {
        angle: 90,
        spread: 360,
        startVelocity: 40,
        elementCount: 150,
        dragFriction: 0.12,
        duration: 4000,
        stagger: 3,
        width: "10px",
        height: "10px",
        perspective: "500px",
        colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
      };
      

    if(loadError) return "Error loading map";
    if(!isLoaded) return <Loading/>;
    return  <div className="col-md-12 mx-auto map-container page-container">
        {/* <h3 className="page-title visible-xs">Warsaw Map</h3> */}

        <div id="side-toggle" className={sidebarOpen ? 'active': ''} onClick={handleOpenSidebar}>
            <img src={sidebarToggleIcon} alt="Toggle sidebar"/>
        </div>
        <div id="sidebar-map" className={sidebarOpen ? 'active': ''}>
            <div className="places">
            {
            places.map((place)=>{
                return(
                    <div key={place.name+place.lat} className="item" onClick={()=>{handleClickList(place)}}>
                        <div className="icon-map"><img src={require('../images/mapLogos/'+place.icon)}/></div>
                        <div className="text">
                            <div className="name">{place.name}</div>
                            <div className="description">{place.description}</div>
                        </div>
                    </div>
                )
            })}

            </div>

        </div>
        <GoogleMap 
            className="google-map-container"
            mapContainerStyle={containerStyle}
            className="google-map"
            zoom={12}
            center={center}
            options={options}
            onClick={(event)=>{
                // console.log(event);
            }}
            onLoad={onMapLoad}>
                {places.map(place=>{
                    return <Marker
                            key={place.lat+place.lng}
                            id={place.lat+";"+place.lng}
                            position={{lat: place.lat,lng:place.lng} }
                            icon={{url:require('../images/mapLogos/'+place.icon),scaledSize: new window.google.maps.Size(55,77)}}
                            onClick={()=>{
                                setSelected(place);

                            }}
                    />
                })}


            {selected ? 
                <InfoWindow
                position={{lat:selected.lat, lng:selected.lng}} 
                onCloseClick={()=>{
                    setSelected(null);
                }}>
                <div className="info-window">

                    <div className="heading">
                        <h5>{selected.name}</h5>
                        <div><img src={require('../images/mapLogos/'+selected.icon2)} alt=""/></div>
                    </div>
                    {selected.address ? <div className="address">{selected.address}</div> : '' }
                    {selected.description ? <div className="description">{selected.description}</div> : ''}
                    {selected.website ? <div className="website">{selected.website ? <a taget="_blank" rel="noopener noreferrer" href={selected.website}>Website</a> : ''}</div> : ''}
                    
                        <a style={{marginTop:"10px",display:"block"}} target="_blank" rel="noopener noreferrer" href={"https://www.google.com/maps/dir/?api=1&destination="+selected.lat+","+selected.lng}>Take me there </a>                    
                </div>
            </InfoWindow> : null}

        </GoogleMap>
    </div>

    // const [map, setMap] = React.useState(null)
    // const onLoad = React.useCallback(function callback(map) {
    //     const bounds = new window.google.maps.LatLngBounds(center);
    //     console.log(bounds);
    //     map.fitBounds(bounds);
    //     console.log(map.getZoom());
    //     setMap(map)
    //     map.setZoom(16);
    //     console.log(map);
    //   }, [])
     
    //   const onUnmount = React.useCallback(function callback(map) {
    //     setMap(null)
    //   }, [])

    // return ( 
    //     <div className="col-md-12 mx-auto map-container page-container">
    //         <h3 className="page-title">Warsaw Fun Map</h3>
    //         <LoadScript
    //             googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
    //             >
    //             <GoogleMap
    //                 mapContainerStyle={containerStyle}
    //                 center={center}
    //                 zoom={12}
    //                 defaultZoom={12}
    //                 onUnmount={onUnmount}
    //                 onLoad={onLoad}
    //             >
    //                 <Marker
    //                     position={{lat: 52.242202,lng:21.009776} }
    //                     icon={cubanoIcon}
    //                     />
    //                 {/* <Marker
    //                     position={{lat: 52.342202,lng:21.009776} }
    //                     icon={{url:testIcon, scaledSize: new window.google.maps.Size(15,25)}}
    //                     // icon={image}
    //                     /> */}
    //                 <Marker
    //                     position={{lat: 52.222761,lng:21.015421}}
    //                     icon={{url:manekinIcon,scale:0.3}}
    //                     />
    //                 <Marker
    //                     position={{lat: 52.237995,lng:21.008429}}
    //                     icon={{url:manekinIcon,scale:0.3}}
    //                     />
    //                 { /* Child components, such as markers, info windows, etc. */ }
    //             </GoogleMap>
    //         </LoadScript>
    //     </div>
    //  );
}
 
export default DiscoverEurope;