const initialState = {
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null,
  }

  export default(state=initialState, action) =>{
      switch(action.type){ 
            case 'SW_INIT':
                const newState = {serviceWorkerInitialized: !state.serviceWorkerInitialized, serviceWorkerUpdated: state.serviceWorkerUpdated, serviceWorkerRegistration:state.serviceWorkerRegistration}
                return newState;
            case 'SW_UPDATE':
                return {serviceWorkerInitialized: state.serviceWorkerInitialized, serviceWorkerUpdated: !state.serviceWorkerUpdated, serviceWorkerRegistration:action.payload}
            default:
                return state  
          
      }
  }