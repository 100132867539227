import React from 'react';
import esnStar from '../images/esn_logo.png';
const PullDownContent = () => {
    const style = {
        height: '80px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection:'column',
        background:'#2f2f2f',
        color: 'white',
        zIndex:333
    }
    const imgStyle = {
        height: '30px',
        width: '30px'
    }
    const textStyle={
        marginTop:'5px',
        textAlign:'center',
        fontFamily:'KelsonNormal'
    }
    return ( 
        <div style={style}>
            <img style={imgStyle} src={esnStar} alt=""/>
            <div style={textStyle}>Pull down to refresh</div>
        </div>
     );
}
 
export default PullDownContent;