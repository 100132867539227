import React, {Component} from 'react';

import SingleOfficeHour from './SingleOfficeHour';
import Loading from './Loading';
// import Janusz2 from '../images/janusz2.jpg';
// import Janusz3 from '../images/janusz3.jpg';
// import pengu from '../images/pengu.jpg';
// import dan from '../images/dan.jpg';
// const dayOrder = {
//     'Monday':1,
//     'Tuesday':2,
//     'Wednesday':3,
//     'Thursday':4,
//     'Friday':5,
// }
const imageOrder = [null, null, null, null, null];
const cardOrder=['first', 'second', 'third', 'fourth', 'fifth','sixth','seventh'];
const dayOrder = ['Monday','Tuesday','Wednesday','Thursday','Friday', 'Saturday', 'Sunday'];
class OfficeHours extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: true
         }
    }
    componentDidMount(){
        //preload images - change logic if images taken from DB.
        imageOrder.forEach((picture, index) => {
            if(picture!=null){
                const img = new Image();
                img.src = picture;
            }
            if(index===imageOrder.length-1){
                this.setState({loading: false});
            }
        })
    }
    render() { 
        // const {menuOpen, edit} = this.state;
        const {user, officeHours} = this.props;
        const sortedOfficeHours = officeHours.slice().sort((a,b)=>{
            return dayOrder.indexOf(a.day) < dayOrder.indexOf(b.day) ? -1 : 1;
        });

        return ( 
            this.state.loading ? <Loading/> : 
            <div className="col-md-12 mx-auto oh-container page-container" onClick={this.closeMenu}>
                <h3 className="page-title">Office Hours</h3>
                {/* <p style={{textAlign:'center',color:'#C41414',fontWeight:'600'}}>Due to the current situation with regarding the spread of Covid-19, we are cancelling all standard Office Hours. Nevertheless, it is possible to schedule an appointment with us, just message anyone from the Board! </p> */}
                <p style={{textAlign:'center',fontWeight:'600'}}>Nowoursynowska 161I, Ikar dorm, room 102 ("blue" side of the building) </p>
                <div className="oh-cards">
                    {sortedOfficeHours.map((dayData, index)=>{
                        return(
                            <SingleOfficeHour key={dayData.id} dayData = {dayData} user={user} dayNumber={cardOrder[index]} photo={imageOrder[index]}/>
                        );

                    })}
                </div>
            </div>
         );
    }
}

 
export default OfficeHours;