import React, {useState} from 'react';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {Row, Col} from 'react-bootstrap';
import MessageModal from './MessageModal';

import firebase from '../firebase';

const PasswordReset = (props) => {
    const [modalVisible, setModal] = useState(false);
    const [stateError, setError] = useState('');
    // useSelector((state)=>{
    //     console.log(state.firestore.data);
    // });

    const closeModal = () =>{
        setModal(false);
    }
    const showModal = () =>{
        setModal(true);
    }

    return ( 
    <div className="page-container fix-pad mx-auto">
        <div className="container mobile-container register-container">
        <MessageModal show={modalVisible} closeModal={closeModal} message={"If the given email address is valid, the password reset link has been sent to it."} title="Password reset link sent."/>
            <h3 className="page-title">Password reset</h3>
            <Formik
                initialValues={{
                    email:''
                }}

                onSubmit={(values,{setSubmitting})=>{
                    firebase.auth().sendPasswordResetEmail(values.email).then((resp)=>{
                        setSubmitting(false);
                        showModal();
                    }).catch((error)=>{
                        setSubmitting(false);
                        setError(error.message);
                    })
                    // let raiseError;
                }}
            >
              {({
                values,
                handleSubmit,
                setFieldValue,
                errors,
                isSubmitting
              })=>(
                <Form className="register-form">
                <div className="form-group">
                    <Row>
                        <Col xs={3}  className="pl-0 label-container">
                        <label htmlFor="email">Email</label>
                        </Col>
                        <Col xs={9}  className="form-error pr-0">
                            <ErrorMessage name="email"/>
                        </Col>
                    </Row>
                    <Row ><Field id="email" name="email" type="text" className="form-control" /></Row>
                </div> 
                <div className="text-center form-error">{stateError}</div>
                <Row className="form-group">
                    <button className="btn btn-primary mx-auto" type="submit" disabled={isSubmitting}>Submit</button>
                </Row>
                </Form>
              )}  
            </Formik>

        </div>
    </div>

     );
}
 
export default PasswordReset;