import React from 'react';
import banner from '../../images/homeSlider/eurosender.jpg';
import ESNstar from '../../images/esn_logo.png'
const Eurosender = () => {
    return ( 
        <div className="article-wrapper">
            <div className="image-wrapper container-fluid">
                <img src={banner} alt=""/>
            </div>
            <div className="content">
                <h3 className="star-header"><img src={ESNstar} alt=""/>EUROSENDER - OUR NEW PARTNER</h3>
                <div className="description">
                <p>Eurosender becomes a new partner of ESN Poland</p>
                
                <p><span className="bold">Use the promo code: ERASMUS20 and get a 10% discount when sending luggage, packages or relocating with Eurosender.</span></p>
                
                <p>Don’t waste time trying to make everything fit into one little suitcase when preparing for your move. Bring as much luggage as you need, at the lowest cost. Eurosender offers simple and reliable shipping services, helping more than 500,000 students organise their move when studying abroad. They collaborate with multiple international courier companies to make sure you always find the right shipping service for your needs, whether you are sending a suitcase, a package, an envelope, or organising an entire relocation.</p>
                <h5>What do you get when shipping with Eurosender?</h5>
                Besides having unbeatable prices and shipping discounts for students, Eurosender offers several other advantages, such as:
                <ul>
                <li>Instant shipping quotes – without hidden costs!</li>
                <li>Free basic insurance included in the price</li>
                <li>Door-to-door deliveries for greater comfort</li>
                <li>Global reach – ship to any country in the world</li>
                <li>Are you in a hurry? Choose Express shipping for an urgent delivery</li>
                </ul>
                <br/>
                <h5>How to use your shipping discount on Eurosender:</h5>
                <ul>
                <li>Go to <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" href="https://www.eurosender.com/en/">www.eurosender.com</a></li>
                <li>Choose the countries “from” and “to” you wish to send.</li>
                <li>Select the type of delivery service you need. Choose Packages for parcels and luggage or Van Delivery for relocations.</li>
                <li>Enter the shipping details.</li>
                <li>Choose your payment method enter the applicable code: ERASMUS20</li>
                </ul>
                <h5>What can you send?</h5>
                <p>When shipping with Eurosender, you can send almost anything! These are some of the items most shipped by students while moving abroad or back home – check their guides to find out how to pack your belongings before shipping:</p>
                <ul>
                <li><a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" href="https://www.eurosender.com/en/shipping-luggage">Suitcases</a></li>
                <li><a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" href="https://www.eurosender.com/en/pack-ship/clothes">Clothes</a></li>
                <li><a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" href="https://www.eurosender.com/en/pack-ship/musical-instruments">Musical instruments</a></li>
                <li><a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" href="https://www.eurosender.com/en/pack-ship/sports-equipment">Sports equipment</a></li>
                <li><a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" href="https://www.eurosender.com/en/pack-ship/furniture">Furniture</a></li>
                <li><a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" href="https://www.eurosender.com/en/pack-ship/household-appliances">Household appliances</a></li>
 
                </ul>
                <p>
                <span className="bold">For more information and help during the procedures please check <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" href="https://www.eurosender.com/en/">Eurosender’s website.</a></span>
                </p>

                </div>
            </div>
        </div>
     );
}
 
export default Eurosender;