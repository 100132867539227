import React from 'react';
import ESNstar from '../images/esn_logo.png'
import ESNcardDefault from '../images/ESNcard.jpg'
import ESNcardMin from '../images/ESNcard_min.jpg'
import ProgressiveImage from './ProgressiveImageSimple'
const ESNcard = () => {
    return ( 
        <div className="page-container col-md-10 container esncard-container">
            <h3 className='star-header'><img src={ESNstar} alt=""/>ESNcard</h3>
            <div className="row">
                <div className="col-xs-12 col-lg-8" style={{'textAlign':'justify'}}>
                    ESNcard allows you to benefit from many discounts all over Europe, in pubs, clubs, cafes, restaurants and so on.
                    One of the most interesting offers is the <b>Ryanair</b> deal which grants you free checked-in luggage and 15% discount for 8 flights (does not apply to flights between 16th of June and 31st of August).
                    Maybe most importantly though, you are required to have an ESNcard in order to be able to join us on the great <b>ESN SGGW trips</b>! 
                    <br/> <br/>
                    You can order your ESNcard at  <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" href="https://www.card.esn.pl/" target="_blank">card.esn.pl</a> and pick it up at our events or in the office of ESN SGGW during Office Hours.
                    You should then register it at <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" href="https://www.esncard.org/" target="_blank">esncard.org</a> 
                    <br/> <br/>
                    To order your card, you will need to upload a picture of yourself. We recommend using <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" href=" https://passport-photo.online/en-gb/uk-esncard-photo?fbclid=IwAR16cvt2-byiXtzH3AoY6j3Eus-gaiBGORcVUERVfqvwrj3I6fe6NuYEkEM" target="_blank">the tool made by PhotoAiD</a> which will help you transform your photo to an ESNcard-ready, professional photo! 
                </div>
                <div className="col-xs-12 col-md-4">
                    <ProgressiveImage src={ESNcardDefault} overlaySrc={ESNcardMin}/>
                </div>
            </div>
        </div>
     );
}
 
export default ESNcard;