import React, {Component} from 'react';
import Calendar from './components/Calendar';
import Header from './components/Header';
import Register from './components/Register';
import Login from './components/Login';
import LoginBox from './components/LoginBox';
import Faq from './components/Faq';
import Event from './components/Event';
import NotFound from './components/NotFound';
import {Route, Switch, HashRouter, Redirect } from 'react-router-dom';
import './styles/style.sass';
// import store from './store/store';
import CreateOrEditEvent from './components/CreateOrEditEvent';
import firebase from './firebase';
import {compose, } from 'redux';
import {connect} from 'react-redux';
import {withFirestore} from 'react-redux-firebase';
import Loading from './components/Loading';
import LoadingFull from './components/LoadingFull';
import Tasks from './components/Tasks';
import DiscoverEurope from './components/DiscoverEurope';
import OfficeHours from './components/OfficeHours';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import ManageUsers from './components/ManageUsers';
import Profile from './components/Profile';
import PasswordReset from './components/PasswordReset';
import PrivacyPolicy from './components/PrivacyPolicy';
import Polish from './components/Polish';
import Forms from './components/Forms';
import ErasmusMap from './components/ErasmusMap';
import ESNcard from './components/ESNcard';
import ScrollToTop from './components/ScrollToTop';
import Team from './components/Team';
import Home from './components/Home';
import HeaderMobile from './components/HeaderMobile';
import Articles from './components/Articles';
import DataFill from './components/DataFill';
import MessengerBubble from './components/MessengerBubble';
import Down from './components/Down';
import {PullToRefresh} from 'react-js-pull-to-refresh'
import PullDownContent from './components/PullDownContent'
import ReleaseContent from './components/ReleaseContent'
import RefreshContent from './components/RefreshContent'
class App extends Component {
  constructor(props) {
    super(props);
    //removes error where firestore is undefined on cleanup.
    this.componentCleanup = this.componentCleanup.bind(this);
    this.state = { 
      user: null,
      events: [],
      loading: true,
      serviceWorkerInitialized: props.swData.serviceWorkerInitialized,
      // teamImagesLoaded: 0,
      wasHomePageLoaded: false,
      redirectUserToFillData: false,
      userNotInitiated: false,
      passedUid: null,
      userToFillData:null
     }
  }
  componentCleanup(){
    // console.log('cleanup');
    this.props.firestore.unsetListener({
      collection: 'events',
      orderBy: ['startTime'],
      where: ['isPublic','==',true],
      storeAs: 'allEvents'
    });
    this.props.firestore.unsetListener({
      collection: 'events',
      orderBy: ['startTime'],
      where: ['isEsnOnly','==',true],
      storeAs: 'esnEvents'
    });
    this.props.firestore.unsetListener({
      collection: 'events',
      orderBy: ['startTime'],
      where: ['isZarzad','==',true],
      storeAs: 'zarzadEvents'
    });
      this.props.firestore.unsetListener({
        collection: 'officeHours'
      });
  }
  // updateTeamPageLoad = () =>{
  //   this.setState({teamImagesLoaded:this.state.teamImagesLoaded+1});
  // }
  updateHomePageLoad = () =>{
    this.setState({wasHomePageLoaded:true});
  }
  componentDidMount() {
    window.addEventListener('beforeunload', this.componentCleanup);
    const analytics = firebase.analytics();
    let messaging;
    // we need to check if messaging is supported by the browser
    window.addEventListener('hashchange',()=>{
      const userData = this.state.user;
      if(userData && userData!=='guest'){
        if(userData.country==="" || userData.email==="" || userData.firstName==="" || userData.lastName==="" || userData.fullname==="" || userData.type==="temp"){
          this.userAddedToDB(userData.uid);
          if(window.location.hash!=="#/" && window.location.hash!=="#/data-fill"){
            window.location.reload();
          }
        }
      }
      const screenView = document.location.hash.substring(1);
      analytics.logEvent('screen_view',{screen_name:screenView});
    });
    if(firebase.messaging.isSupported()) {
      messaging = firebase.messaging();
    }
    firebase.auth().onAuthStateChanged((user)=>{
      if(user){
        firebase.firestore().collection('users').doc(user.uid).get().then((resp)=>{
          if(resp.exists){
            const userWithProps = {...user,...resp.data()};
            this.setState({user:userWithProps});
            analytics.setUserId(user.uid);
            const userData = resp.data();
            //if some data is not filled - redirect user to fill data.
            if(userData.country==="" || userData.email==="" || userData.firstName==="" || userData.lastName==="" || userData.fullname==="" || userData.type==="temp"){
              this.userAddedToDB(user.uid);
            }
            let userType='guest';
            if((userData.type==='esn' && userData.isConfirmedEsn===true)) userType='esn';
            else if(userData.type==='mentor') userType='mentor';
            else if(userData.type==='erasmus') userType='erasmus';
            else if(userData.type==='fulltime') userType='fulltime';
            analytics.setUserProperties({userCategory:userType});
            
            //kamil - 19.05.2022 - removing notification functionality because it's pointless
            // if(messaging){
            //   messaging.requestPermission().then(()=>{
            //     return messaging.getToken();
            //   }).then((token)=>{
            //     console.log('token: ' +token);
            //     if(window.innerWidth<=768){
            //       firebase.firestore().collection('notificationTokens').doc('tokenContainer').update({tokens:firebase.firestore.FieldValue.arrayUnion(token)});
            //     }
            //   }).catch((err)=>{
            //     console.log("Error: " +err);
            //   });
            // }
            if(userType==='esn'){
              this.props.firestore.setListener({
                collection: 'events',
                orderBy: ['startTime'],
                where: ['esnOnly','==',true],
                storeAs: 'esnEvents'
              });
            }
            if(userData.isZarzad){
              this.props.firestore.setListener({
                collection: 'events',
                orderBy: ['startTime'],
                where: ['zarzadOnly','==',true],
                storeAs: 'zarzadEvents'
              });
            }
          }else{
            this.setState({userNotInitiated:true});
          }
        })
      }else{
        console.log('logged out');
        this.props.firestore.unsetListener({
          collection: 'events',
          orderBy: ['startTime'],
          where: ['zarzadOnly','==',true],
          storeAs: 'zarzadEvents'
        });
        this.props.firestore.unsetListener({
          collection: 'events',
          orderBy: ['startTime'],
          where: ['esnOnly','==',true],
          storeAs: 'esnEvents'
        });
        this.setState({user:'guest'});
      }
    });
    // has to be stored as new, otherwise events state can get changed after looking at a single event (?).
    this.props.firestore.setListener({
        collection: 'events',
        orderBy: ['startTime'],
        where: ['isPublic','==',true],
        storeAs: 'allEvents'
      });
    this.props.firestore.setListener({
        collection: 'officeHours'
      });
    

  }
  
  componentDidUpdate(e){
    // show calendar if didn't finish loading yet events exist.
    if(this.state.loading && this.props.allEvents!==undefined && this.props.officeHours){
      this.setState({loading:false});
    }
  }
  componentWillUnmount(){
    this.componentCleanup();
    window.removeEventListener('beforeunload', this.componentCleanup);
    this.props.firestore.unsetListener({
      collection: 'events',
      orderBy: ['startTime'],
      where: ['isPublic','==',true],
      storeAs: 'allEvents'
    });
    this.props.firestore.unsetListener({
      collection: 'events',
      orderBy: ['startTime'],
      where: ['isEsnOnly','==',true],
      storeAs: 'esnEvents'
    });
    this.props.firestore.unsetListener({
      collection: 'events',
      orderBy: ['startTime'],
      where: ['isZarzad','==',true],
      storeAs: 'zarzadEvents'
    });
      this.props.firestore.unsetListener({
        collection: 'officeHours'
      });
  }

  updateServiceWorker = () => {
    const registrationWaiting = this.props.swData.serviceWorkerRegistration.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      registrationWaiting.addEventListener('statechange', e => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  };

  userAddedToDB = (uid) =>{
    this.setState({redirectUserToFillData:true,passedUid:uid});
  }
  userFilledData = () =>{
    this.setState({redirectUserToFillData:false});
  }
  onRefresh = () =>{
    return new Promise((resolve) => {
          window.location.reload();
          setTimeout(resolve, 2000);
      });
  }
  render() { 
    // console.log(this.props.esnEvents)
    const {user}=this.state;
    const publicEvents = this.props.allEvents===undefined ? [] : this.props.allEvents;
    const esnEvents = this.props.esnEvents===undefined ? [] : this.props.esnEvents;
    // console.log(esnEvents);
    const zarzadEvents = this.props.zarzadEvents===undefined ? [] : this.props.zarzadEvents;
    let userToFillData;
    if(this.state.userToFillData===null && this.state.redirectUserToFillData){
      firebase.firestore().collection('users').doc(this.state.passedUid).get().then((doc)=>{
        const data = doc.data();
        userToFillData = {
          uid:this.state.passedUid,
          email:data.email,
          firstName:data.firstName,
          lastName:data.lastName,
          country:data.country,
          birthdate: data.birthdate ? data.birthdate.seconds*1000 : null
        }
        this.setState({userToFillData});
      })
    }
    return (

    // <div>
    //     <Down/>
    //     {this.props.swData.serviceWorkerUpdated ? 
    //       <div className="updatePopup">
    //         <div className="inner">
    //           <div>Pardon the interruption, but <br/>A new version has been found! </div>
    //           <div className="btn-container"> 
    //             <button className="btn hidden-xs" onClick={this.updateServiceWorker}>Update</button>
    //           <button className="btn visible-xs" onClick={this.updateServiceWorker}>Update</button>
    //           </div>
    //         </div>
    //       </div> : ''}
    // </div>
      this.state.loading ? <Loading /> :  
      window.innerWidth<768 ? 
      <PullToRefresh
        pullDownContent={<PullDownContent />}
        releaseContent={<ReleaseContent />}
        refreshContent={<RefreshContent />}
        pullDownThreshold={80}
        onRefresh={this.onRefresh}
        triggerHeight={130}
        startInvisible={false}
        className="pull-to-refresh"
      >
      <div className="construction-container">
      {this.props.swData.serviceWorkerUpdated ? 
      <div className="updatePopup">
        <div className="inner">
          <div>Pardon the interruption, but <br/>A new version has been found! </div>
          <div className="btn-container"> 
            <button className="btn hidden-xs" onClick={this.updateServiceWorker}>Update</button>
          <button className="btn visible-xs" onClick={this.updateServiceWorker}>Update</button>
          </div>
        </div>
      </div> : ''}
        {!user ? <Loading /> : <div className={this.props.swData.serviceWorkerUpdated ? "update-blur" : ''}>
        <HashRouter>
        {(this.state.userToFillData!=null && this.state.redirectUserToFillData) ? <Redirect to={{pathname:"/data-fill", state:{user:this.state.userToFillData}}} /> : ''}
        <ScrollToTop/>
        <HeaderMobile/>
        <MessengerBubble/>
        <Header user={user==='guest' ? null : user}/>
        <Sidebar user={user==='guest' ? null : user} officeHours={this.props.officeHours} events={publicEvents.concat(esnEvents).concat(zarzadEvents)}/>
          <Switch>
          <Route exact path="/">
            <Home updateHomePageLoad = {this.updateHomePageLoad} wasHomePageLoaded={this.state.wasHomePageLoaded} />
            {/* <Calendar 
              events={publicEvents.concat(esnEvents).concat(zarzadEvents)}
              waitingForConfirmation = {user.isConfirmedEsn===false ? true: false}
              esn={user!=='guest' && user.type==='esn' && user.isConfirmedEsn ? true : false}
              isZarzad={user!=='guest' && user.isZarzad ? true : false}
              /> */}
          </Route>
          <Route exact path="/register">
            {user==='guest' ? <Register/> : <Redirect to={"/"}/>}
          </Route>
          <Route exact path="/login">
            {user==='guest' ? <Login/> : <Redirect to={"/"}/>}
          </Route>
          <Route exact path="/login-box">
            {user==='guest' ? <LoginBox userAddedToDB={this.userAddedToDB}/> : <Redirect to={"/"}/>}
          </Route>
          <Route exact path="/password-reset">
            <PasswordReset/>
          </Route>
          <Route exact path="/faq">
              <Faq/>
          </Route>
          <Route exact path="/ESNcard">
              <ESNcard/>
          </Route>
          <Route exact path="/team">
              <Team />
          </Route>
          <Route exact path="/polish-students">
              <Polish />
          </Route>
          <Route exact path="/discover-warsaw">
              <DiscoverEurope />
          </Route>
          <Route exact path="/polish-students/:articleName">
              <Polish />
          </Route>
          <Route exact path="/articles">
              <Articles/>
          </Route>
          <Route exact path="/articles/:articleName">
              <Articles/>
          </Route>
          <Route exact path="/home">
              <Home updateHomePageLoad = {this.updateHomePageLoad} wasHomePageLoaded={this.state.wasHomePageLoaded}/>
          </Route>
          <Route exact path="/forms">
              <Forms/>
          </Route>
          <Route exact path="/data-fill">
              <DataFill userFilledData={this.userFilledData} userFacebook={this.state.user}/>
          </Route>
          {/* <Route exact path="/treasure-hunt">
              <TreasureHunt/>
          </Route> */}
          <Route exact path="/map">
              <ErasmusMap/>
          </Route>
          <Route exact path="/privacy-policy">
              <PrivacyPolicy/>
          </Route>
          <Route exact path="/profile">
              {user!=='guest' ? <Profile user={user}/> : <Redirect to={"/"}/>}
          </Route>
          <Route exacth path="/manage-users">
            {user.isAdmin ? <ManageUsers/> : <Redirect to={"/"}/>}
          </Route>
          <Route exact path="/office-hours">
              <OfficeHours user={user} officeHours={this.props.officeHours}/>
          </Route>
          <Route exact path="/tasks">
              {user!=='guest' && user.type==='esn' && user.isConfirmedEsn ? <Tasks user={user}/> : <Redirect to={"/"}/>}
             {/* <Tasks user={user}/>  */}
          </Route>
          <Route exact path="/events/:eventId">
              <Event user={user==='guest' ? null : user} events={publicEvents.concat(esnEvents).concat(zarzadEvents)}/>
          </Route>
          <Route exact path="/events">
              <Calendar 
                events={publicEvents.concat(esnEvents).concat(zarzadEvents)}
                waitingForConfirmation = {user.isConfirmedEsn===false ? true: false}
                esn={user!=='guest' && user.type==='esn' && user.isConfirmedEsn ? true : false}
                isZarzad={user!=='guest' && user.isZarzad ? true : false}
                />
          </Route>
          <Route exact path="/createEvent/:eventId">
              {user!=='guest' && user.type==='esn' && user.isConfirmedEsn ? <CreateOrEditEvent user={user} edit={true}/> : <Redirect to={"/"}/>}
          </Route>
          <Route exact path="/createEvent">
            {/* {user && user.type==='esn' ? <CreateOrEditEvent user={user}/> : <Redirect to={"/"}/>} */}
            { user!=='guest' && user.type==='esn' && user.isConfirmedEsn ? <CreateOrEditEvent user={user}/> : <Redirect to={"/"}/>}
          </Route>
          <Route path="*">
            <NotFound/>
          </Route>
          </Switch>
        <Footer/>
        </HashRouter>
        </div>
      }
      </div>
      </PullToRefresh> : 
      <div className="construction-container">
      {this.props.swData.serviceWorkerUpdated ? 
      <div className="updatePopup">
        <div className="inner">
          <div>Pardon the interruption, but <br/>A new version has been found! </div>
          <div className="btn-container"> 
            <button className="btn hidden-xs" onClick={this.updateServiceWorker}>Update</button>
          <button className="btn visible-xs" onClick={this.updateServiceWorker}>Update</button>
          </div>
        </div>
      </div> : ''}
        {!user ? <Loading /> : <div className={this.props.swData.serviceWorkerUpdated ? "update-blur" : ''}>
        <HashRouter>
        {(this.state.userToFillData!=null && this.state.redirectUserToFillData) ? <Redirect to={{pathname:"/data-fill", state:{user:this.state.userToFillData}}} /> : ''}
        <ScrollToTop/>
        <HeaderMobile/>
        <MessengerBubble/>
        <Header user={user==='guest' ? null : user}/>
        <Sidebar user={user==='guest' ? null : user} officeHours={this.props.officeHours} events={publicEvents.concat(esnEvents).concat(zarzadEvents)}/>
          <Switch>
          <Route exact path="/">
            <Home updateHomePageLoad = {this.updateHomePageLoad} wasHomePageLoaded={this.state.wasHomePageLoaded} />
            {/* <Calendar 
              events={publicEvents.concat(esnEvents).concat(zarzadEvents)}
              waitingForConfirmation = {user.isConfirmedEsn===false ? true: false}
              esn={user!=='guest' && user.type==='esn' && user.isConfirmedEsn ? true : false}
              isZarzad={user!=='guest' && user.isZarzad ? true : false}
              /> */}
          </Route>
          <Route exact path="/register">
            {user==='guest' ? <Register/> : <Redirect to={"/"}/>}
          </Route>
          <Route exact path="/login">
            {user==='guest' ? <Login/> : <Redirect to={"/"}/>}
          </Route>
          <Route exact path="/login-box">
            {user==='guest' ? <LoginBox userAddedToDB={this.userAddedToDB}/> : <Redirect to={"/"}/>}
          </Route>
          <Route exact path="/password-reset">
            <PasswordReset/>
          </Route>
          <Route exact path="/faq">
              <Faq/>
          </Route>
          <Route exact path="/ESNcard">
              <ESNcard/>
          </Route>
          <Route exact path="/team">
              <Team />
          </Route>
          <Route exact path="/discover-warsaw">
              <DiscoverEurope />
          </Route>
          <Route exact path="/polish-students">
              <Polish />
          </Route>
          <Route exact path="/polish-students/:articleName">
              <Polish />
          </Route>
          <Route exact path="/articles">
              <Articles/>
          </Route>
          <Route exact path="/articles/:articleName">
              <Articles/>
          </Route>
          <Route exact path="/home">
              <Home updateHomePageLoad = {this.updateHomePageLoad} wasHomePageLoaded={this.state.wasHomePageLoaded}/>
          </Route>
          <Route exact path="/forms">
              <Forms/>
          </Route>
          <Route exact path="/data-fill">
              <DataFill userFilledData={this.userFilledData} userFacebook={this.state.user}/>
          </Route>
          <Route exact path="/map">
              <ErasmusMap/>
          </Route>
          {/* <Route exact path="/treasure-hunt">
              <TreasureHunt/>
          </Route> */}
          <Route exact path="/privacy-policy">
              <PrivacyPolicy/>
          </Route>
          <Route exact path="/profile">
              {user!=='guest' ? <Profile user={user}/> : <Redirect to={"/"}/>}
          </Route>
          <Route exacth path="/manage-users">
            {user.isAdmin ? <ManageUsers/> : <Redirect to={"/"}/>}
          </Route>
          <Route exact path="/office-hours">
              <OfficeHours user={user} officeHours={this.props.officeHours}/>
          </Route>
          <Route exact path="/tasks">
              {user!=='guest' && user.type==='esn' && user.isConfirmedEsn ? <Tasks user={user}/> : <Redirect to={"/"}/>}
             {/* <Tasks user={user}/>  */}
          </Route>
          <Route exact path="/events/:eventId">
              <Event user={user==='guest' ? null : user} events={publicEvents.concat(esnEvents).concat(zarzadEvents)}/>
          </Route>
          <Route exact path="/events">
              <Calendar 
                events={publicEvents.concat(esnEvents).concat(zarzadEvents)}
                waitingForConfirmation = {user.isConfirmedEsn===false ? true: false}
                esn={user!=='guest' && user.type==='esn' && user.isConfirmedEsn ? true : false}
                isZarzad={user!=='guest' && user.isZarzad ? true : false}
                />
          </Route>
          <Route exact path="/createEvent/:eventId">
              {user!=='guest' && user.type==='esn' && user.isConfirmedEsn ? <CreateOrEditEvent user={user} edit={true}/> : <Redirect to={"/"}/>}
          </Route>
          <Route exact path="/createEvent">
            {/* {user && user.type==='esn' ? <CreateOrEditEvent user={user}/> : <Redirect to={"/"}/>} */}
            { user!=='guest' && user.type==='esn' && user.isConfirmedEsn ? <CreateOrEditEvent user={user}/> : <Redirect to={"/"}/>}
          </Route>
          <Route path="*">
            <NotFound/>
          </Route>
          </Switch>
        <Footer/>
        </HashRouter>
        </div>
      }
      </div>
      
     );
  }
}


const mapStateToProps = (state) =>{
  return{
    swData: state.swReducer,
    allEvents: state.firestore.ordered.allEvents,
    esnEvents: state.firestore.ordered.esnEvents,
    zarzadEvents: state.firestore.ordered.zarzadEvents,
    officeHours: state.firestore.ordered.officeHours
  }
}
export default compose(withFirestore,connect(mapStateToProps))(App);