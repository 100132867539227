import React, {useState} from 'react';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {Row, Col} from 'react-bootstrap';
import {Link, useHistory} from 'react-router-dom'
import googleLoginIcon from '../images/ikony/googleLoginIcon.svg';
import facebookLoginIcon from '../images/ikony/facebookLogo_default.png';
import emailLoginIcon from '../images/ikony/mail.svg';
import firebase from '../firebase';
import ErrorModal from './ErrorModal';

const LoginBox = (props) => {
    const history = useHistory();
    const [modalVisible, setModal] = useState(false);
    const [errorMsg, setError] = useState('');
    const closeModal = () =>{
        setModal(false);
    }
    const handleGoogleSignIn = () =>{
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider).then(function(result) {
            const obj = {firstName: result.additionalUserInfo.profile.given_name,
                lastName: result.additionalUserInfo.profile.family_name ? result.additionalUserInfo.profile.family_name : '',
                type: 'temp',
                country: '',
                fullName: '',
                email: result.additionalUserInfo.profile.email,
                privacyPolicyAgreement: true,
                accountCreatedAt: Date.now()}
                
            firebase.firestore().collection('users').doc(result.user.uid).get().then((doc)=>{
                if(doc.exists){
                    const savedData = doc.data();
                    if(savedData.country==="" || savedData.email==="" || savedData.firstName==="" || savedData.lastName==="" || savedData.fullname==="" || savedData.type==="temp"){
                        props.userAddedToDB(result.user.uid)
                    }
                }else{
                    firebase.firestore().collection('users').doc(result.user.uid).set(obj);
                    props.userAddedToDB(result.user.uid);
                }
            })
            // ...
          }).catch(function(error) {
            // Handle Errors here.
            console.log(error);
            // alert(error.message);
            setError(error.message);
            setModal(true);
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
          });
    }
    const handleFacebookSignIn = () =>{
        const provider = new firebase.auth.FacebookAuthProvider();
        firebase.auth().signInWithPopup(provider).then(function(result) {
            const obj = {firstName: result.additionalUserInfo.profile.first_name,
                lastName: result.additionalUserInfo.profile.last_name ? result.additionalUserInfo.profile.last_name : '',
                type: 'temp',
                country: '',
                fullName: '',
                email: result.additionalUserInfo.profile.email ? result.additionalUserInfo.profile.email : '',
                privacyPolicyAgreement: true,
                // birthdate: values.birthdate,
                accountCreatedAt: Date.now()}
            // if(values.type==='esn')
            //     obj.isConfirmedEsn=false;
            firebase.firestore().collection('users').doc(result.user.uid).get().then((doc)=>{
                if(doc.exists){
                    const savedData = doc.data();
                    if(savedData.country==="" || savedData.email==="" || savedData.firstName==="" || savedData.lastName==="" || savedData.fullname==="" || savedData.type==="temp"){
                        props.userAddedToDB(result.user.uid)
                    }
                }else{
                    firebase.firestore().collection('users').doc(result.user.uid).set(obj);
                    props.userAddedToDB(result.user.uid);
                }
            })
            // ...
          }).catch(function(error) {
              console.log(error);
              setError(error.message);
              setModal(true);
            //   alert(error.message);
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
          });
    }
    return ( 
    <div className="page-container fix-pad mx-auto login-box">
    <ErrorModal show={modalVisible} closeModal={closeModal} error={errorMsg} title="Success"/>
        <div className="container mobile-container register-container">
            <h3 className="page-title">Login</h3>
            {/* <div className="login-button" id="googleButton" onClick={handleGoogleSignIn}>
                <div className="abcRioButton abcRioButtonBlue">
                    <div className="abcRioButtonContentWrapper">
                            <div className="abcRioButtonIcon">
                                <div className="img-wrapper">
                                    <img src={googleLoginIcon} alt="Google"/>
                                </div>
                            </div>
                            <span class="abcRioButtonContents"><span id="not_signed_inlheb3186chx">Sign in with Google</span></span>
                    </div>
                </div>
            </div>
            <div className="login-button fb connect" onClick={handleFacebookSignIn}>Sign in with Facebook</div> */}
            <Link to="/login" className="login-button">
                <img src={emailLoginIcon} alt="Google"/>
                <span>Sign in with Email</span>
            </Link>

            <div className="login-button" onClick={handleGoogleSignIn}>
                <img src={googleLoginIcon} alt="Google"/>
                <span>Sign in with Google</span>
            </div>
            <div className="login-button" onClick={handleFacebookSignIn}>
                <img src={facebookLoginIcon} alt="Facebook"/>
                <span>Sign in with Facebook</span>
            </div>
            <Row className="text-center no-account">
                <Link className="have-account" to="/register">Don't have an account yet? <span>Create one!</span></Link>
            </Row>
        </div>
    </div>

     );
}
 
export default LoginBox;