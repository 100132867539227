import React, {Component} from 'react';
import {Carousel} from 'react-bootstrap';
// import mentor from '../images/homeSlider/welcome.jpg'
import pepehousing from '../images/homeSlider/pepehousing.jpg'
import ESNcard from '../images/homeSlider/esncard_3.jpg'
// import OW from '../images/homeSlider/OW.jpg'
// import Webinar from '../images/homeSlider/Erasmus-webinar.jpg'
import rekrutacja from '../images/homeSlider/rekrutacja-2024.jpg'
import orientationWeek from '../images/homeSlider/OW-2021.jpg'
import Loading from './Loading';
import {NavLink } from 'react-router-dom'

const pictures = [ESNcard, pepehousing];

class HomeCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: true
         }
    }
    componentDidMount(){
        //call parent method when loaded
        const isAlreadyLoaded = this.props.wasHomePageLoaded;
        if(!isAlreadyLoaded){
            let loadedImages = 0;
            pictures.forEach((picture, index) => {
                const img = new Image();
                img.src = picture;
                img.onload = () =>{
                    loadedImages++;
                    if(loadedImages===pictures.length){
                        this.setState({loading: false});
                        this.props.updateHomePageLoad();
                    }
                }
            });
        }else{
            this.setState({loading:false});
        }
    }
    render() { 
        return ( 
        this.state.loading ? <Loading noText={true}/> :
        <Carousel>
        {/* <Carousel.Item >
            <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" target="_blank" href="https://fb.me/e/20cVNSmxT" >
                <div className="background-item no-center-mobile" style={{backgroundImage: `url(${Webinar})`}}></div>
                <Carousel.Caption>
                <h3>Polish speaking student of SGGW?</h3>
                <p>Find out what opportunities are waiting for you with Erasmus+!</p>
                </Carousel.Caption>
            </a>
        </Carousel.Item> */}
        <Carousel.Item >
            <NavLink to="/polish-students/dolacz">
                <div className="background-item rekrutacja no-center-mobile" style={{backgroundImage: `url(${rekrutacja})`}}></div>
                <Carousel.Caption>
                <h3>Polish speaking student of SGGW?</h3>
                <p>Find out how to join us during our recruitment!</p>
                </Carousel.Caption>
            </NavLink>
        </Carousel.Item>
            {/* <Carousel.Item >
                <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" target="_blank" href="https://fb.me/e/2LS5lD5oi" >
                    <div className="background-item standard" style={{backgroundImage: `url(${orientationWeek})`}}></div>
                    <Carousel.Caption>
                    <h3>Orientation Week 2021</h3>
                    <p>Join our events between 4th and 10th of October!</p>
                    </Carousel.Caption>
                </a>
            </Carousel.Item> */}
            <Carousel.Item >
                <NavLink to="/articles/pepehousing"  >
                    <div className="background-item" style={{backgroundImage: `url(${pepehousing})`}}></div>
                    <Carousel.Caption>
                    <h3>Looking for accomodation?</h3>
                    <p>Check out our partnership with Pepe Housing!</p>
                    </Carousel.Caption>
                </NavLink>
            </Carousel.Item>
            {/* <Carousel.Item >
                <div className="background-item standard" style={{backgroundImage: `url(${mentor})`}}></div>
                <Carousel.Caption>
                <h3>First slide label</h3>
                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </Carousel.Caption>
            </Carousel.Item> */}
            <Carousel.Item >
            
                <NavLink to="/ESNcard" >
                    <div className="background-item standard" style={{backgroundImage: `url(${ESNcard})`}}></div>
                    <Carousel.Caption>
                    <h3>Learn more about ESNcard!</h3>
                    <p>Gain access to ESN SGGW trips and amazing discounts (Including Ryanair!)</p>
                    </Carousel.Caption>
                </NavLink>
            </Carousel.Item>

        </Carousel>);
        
    }
}
 
export default HomeCarousel;

