import React, {useState} from 'react';
import firebase from '../firebase';
import {compose} from 'redux';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {object, string,bool } from 'yup';
import {Row, Col} from 'react-bootstrap';
import ReactFlagsSelect from 'react-flags-select';
import DatePickerField from './DatePickerField';
import {Link, useHistory} from 'react-router-dom';
import {withFirestore} from 'react-redux-firebase';
//import css module
import 'react-flags-select/css/react-flags-select.css';

const SelectCountry = ({name,id,onSelect})=>{
    return(

    <ReactFlagsSelect
        name={name}
        id={id}
        className="form-control"
        searchable={true} 
        onSelect = {(val)=>{
            onSelect(name,val);
        }}
    />
    )
}
const Checkbox = ({ id,name, value, onChange, onBlur, checked }) => {
    return (
      <div>
        <input
          name={name}
          id={id}
          type="checkbox"
          value={value}
          onChange={val => {
                onChange(name, val.currentTarget.checked);
            }}
          onBlur={onBlur}
          className={"form-control"}
        />
      </div>
    );
  };

const Register = (props) => {
    const history = useHistory();
    const [stateError, setError] = useState('');
    let maxBirthDate = new Date();
    maxBirthDate.setFullYear(maxBirthDate.getFullYear()-16);
    return ( 
        <div className="page-container fix-pad mx-auto">
        <div className="container mobile-container register-container">
            <h3 className="page-title">Register</h3>
            <Formik
                initialValues={{
                    email:'', password:'',type:'', firstName:'', lastName:'', country:'',privacyPolicyAgreement:false, birthdate:''
                }}
                validationSchema={object({
                    firstName: string()
                    .required('Required'),
                    lastName: string()
                    .required('Required'),
                    email:string().email('Enter a valid email')
                    .required('Required'),
                    password:string()
                    .required('Required')
                    .min(6,"Minimum 6 characters"),
                    country: string()
                    .required('Required'),
                    type: string()
                    .required('Required'),
                    privacyPolicyAgreement: bool()
                    .oneOf([true],'Required')
                    
                })}
                onSubmit={(values,{setSubmitting})=>{
                    let raiseError;
                    // alert(JSON.stringify(values, null,2));
                    firebase.auth().createUserWithEmailAndPassword(values.email,values.password).catch(error=>{
                        setSubmitting(false);
                        raiseError = true;
                        setError(error.message);
                        // let errorCode = error.code;
                        // let errorMessage = error.message;
                    }).then((data)=>{
                        if(!raiseError){
                            // uid
                            const obj = {firstName: values.firstName,
                                lastName: values.lastName,
                                type: values.type,
                                country: values.country,
                                fullName: values.firstName+" "+ values.lastName,
                                email: values.email,
                                privacyPolicyAgreement: values.privacyPolicyAgreement,
                                birthdate: values.birthdate,
                                accountCreatedAt: Date.now()}
                            if(values.type==='esn')
                                obj.isConfirmedEsn=false;
                            props.firestore.collection('users').doc(data.user.uid).set(obj)
                            .then((resp)=>{
                                data.user.sendEmailVerification().then(()=>{
                                    setSubmitting(false);
                                    // test, later show popup
                                    const analytics = firebase.analytics();
                                    if(obj.birthdate && obj.birthdate!==''){
                                        var diff_ms = Date.now() - obj.birthdate.getTime();
                                        var age_dt = new Date(diff_ms); 
                                        const age =  Math.abs(age_dt.getUTCFullYear() - 1970);
                                        analytics.setUserProperties({age:age});
                                    }
                                    analytics.logEvent('register',{uid:data.user.uid});
                                    // analytics.setUserProperties()
                                    history.push('/');
                                })
                            });
                        }
                    });
                }}
            >
              {({
                values,
                handleSubmit,
                setFieldValue,
                errors,
                isSubmitting
              })=>(
                <Form className="register-form">
                <Row >
                    <Col xs={12} md={6} className=" label-container form-left">
                        <Row className="mr-md-0">
                            <Col xs={6} md={8} className="pl-0 label-container">
                            <label htmlFor="firstName">First Name</label>
                            </Col>
                            <Col xs={6} md={4} className="form-error pr-0 pl-0">
                                <ErrorMessage name="firstName"/>
                            </Col>
                        </Row>
                        <Row className="mr-md-0"><Field id="firstName" name="firstName" type="text" className="form-control" placeholder="Enter first name..."/></Row>
                    </Col>
                    <Col xs={12} md={6} className=" label-container form-group">
                        <Row className="ml-md-0">
                            <Col xs={6} md={8} className="pl-0 label-container">
                                <label htmlFor="lastName">Last Name</label>
                            </Col>
                            <Col xs={6} md={4} className="form-error pr-0 pl-0">
                                <ErrorMessage name="lastName"/>
                            </Col>
                        </Row>
                        <Row className="ml-md-0"><Field id="lastName" name="lastName" type="text" className="form-control" placeholder="Enter last name..."/></Row>
                    </Col>
                </Row>
                <div className="form-group">
                    <Row>
                        <Col xs={3}  className="pl-0 label-container">
                        <label htmlFor="email">Email</label>
                        </Col>
                        <Col xs={9}  className="form-error pr-0">
                            <ErrorMessage name="email"/>
                        </Col>
                    </Row>
                    <Row ><Field id="email" name="email" type="text" className="form-control" placeholder="Enter a valid email..."/></Row>
                </div>
                <div className="form-group">
                    <Row>
                        <Col xs={4}  className="pl-0 label-container">
                        <label htmlFor="password">Password</label>
                        </Col>
                        <Col xs={8}  className="form-error pr-0">
                            <ErrorMessage name="password"/>
                        </Col>
                    </Row>
                    <Row ><Field id="password" name="password" type="password" className="form-control" placeholder="Minimum 6 characters" /></Row>
                </div>
                <div className="form-group">
                    <Row>
                        <Col xs={8}  className="pl-0 label-container">
                        <label htmlFor="type">Erasmus/ESN/Mentor/Foreign Student ?</label>
                        </Col>
                        <Col xs={4}  className="form-error pr-0">
                            <ErrorMessage name="type"/>
                        </Col>
                    </Row>
                    <Row>
                        <Field id="type" name="type" as="select" className="form-control">
                            <option value="">Select one</option>
                            <option value="erasmus">Erasmus</option>
                            <option value="fulltime">Foreign Student</option>
                            <option value="esn">ESN</option>
                            <option value="mentor">Mentor</option>
                        </Field>
                    </Row>
                </div>
                <div className="form-group">
                    <Row>
                        <Col xs={8}  className="pl-0 label-container">
                        <label htmlFor="country">Country</label>
                        </Col>
                        <Col xs={4}  className="form-error pr-0">
                            <ErrorMessage name="country"/>
                        </Col>
                    </Row>
                    <Row>
                        <SelectCountry
                        className="form-control"
                        name="country"
                        id="country"
                        onSelect={setFieldValue}
                        />
                    </Row>
                </div>
                <div className="form-group">
                    <Row>
                        <Col xs={8}  className="pl-0 label-container">
                        <label htmlFor="birthdate">Birth Date</label>
                        </Col>
                        <Col xs={4}  className="form-error pr-0">
                            <ErrorMessage name="birthdate"/>
                        </Col>
                    </Row>
                    <Row>
                        <DatePickerField
                            className="form-control"
                            name="birthdate"
                            id="birthdate"
                            onChange={setFieldValue}
                            value={values.birthdate}
                            showTimeSelect={false}
                            clearable={true}
                            maxDate={maxBirthDate}
                            showMonthDropdown={true}
                            showYearDropdown={true}
                            dateFormat={"MMMM d, yyyy"}
                        />
                    </Row>
                </div>
                <Row className="form-group privacy-container">
                <div className="left">
                    <Checkbox name="privacyPolicyAgreement" id="privacyPolicyAgreement" onChange={setFieldValue}/>
                    <span> I agree to the <Link to="/privacy-policy">Privacy Policy</Link></span>
                </div>
                <div className="pull-right">
                    <span className="form-error"><ErrorMessage name="privacyPolicyAgreement"></ErrorMessage></span>
                </div>
                </Row>
                <div className="text-center form-error">{stateError}</div>
                <Row className="form-group">
                    <button className="btn btn-primary mx-auto" type="submit" disabled={isSubmitting}>Submit</button>
                </Row>
                <Row className="text-center no-account">
                    <Link className="have-account" to="/login">Already have an account? <span>Log in!</span></Link>
                </Row>
                </Form>
              )}  
            </Formik>

        </div>
        </div>
     );
}
 
export default compose(withFirestore)(Register);


