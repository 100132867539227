import React,{useState} from 'react';
import firebase from '../firebase';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {string, object} from 'yup';
import {useLocation, useHistory} from 'react-router-dom';
import ReactFlagsSelect from 'react-flags-select';
import MessageModal from './MessageModal';
import {Row, Col} from 'react-bootstrap';
import DatePickerField from './DatePickerField';
const SelectCountry = ({name,id,onSelect,defaultCountry})=>{
    return(

    <ReactFlagsSelect
        name={name}
        id={id}
        className="form-control"
        searchable={true} 
        onSelect = {(val)=>{
            onSelect(name,val);
        }}
        defaultCountry={defaultCountry}
    />
    )
}

const DataFill = (props) => {
    const location = useLocation();
    const history = useHistory();
    const [modalVisible, setModal] = useState(false);
    const closeModal = () =>{
        setModal(false);
        props.userFilledData();
        window.location.reload();
    }
    const showModal = () =>{
        setModal(true);
    }
    let maxBirthDate = new Date();
    maxBirthDate.setFullYear(maxBirthDate.getFullYear()-16);
    let user='';
    if(location.state){
        user = location.state.user;
    }else{
        history.push('/');
    }
    return ( 
        <div className="container col-md-10 page-container faq-container">
            <MessageModal show={modalVisible} closeModal={closeModal} message={"Profile successfully modified"} title="Success"/>
            <h3 className="page-title">
                Please fill in the remaning fields
            </h3>
            <div className="profile-wrapper mobile-container">
            <Formik
                initialValues={{
                    email:user.email,
                    firstName:user.firstName,
                    lastName:user.lastName,
                    country:user.country,
                    birthdate: user.birthdate ? user.birthdate.seconds*1000 : null,
                    type: ''
                }}
                validationSchema={object({
                    firstName: string()
                    .required('Required'),
                    lastName: string()
                    .required('Required'),
                    country: string()
                    .required('Required'),
                    type: string()
                    .required('Required'),
                    email: string().email('Enter a valid email')
                })}
                onSubmit={(values,{setSubmitting})=>{
                    const obj = {firstName:values.firstName, lastName:values.lastName,country:values.country,fullName:values.firstName+ " "+values.lastName, birthdate:values.birthdate, type:values.type, email:values.email}
                    if(values.type==='esn')
                        obj.isConfirmedEsn=false;
                    firebase.firestore().collection('users').doc(user.uid).update(obj).then((data)=>{
                        showModal();
                        setSubmitting(false);
                        //update age
                        const analytics = firebase.analytics();
                        if(obj.birthdate && obj.birthdate!==''){
                            var diff_ms = Date.now() - obj.birthdate.getTime();
                            var age_dt = new Date(diff_ms); 
                            const age =  Math.abs(age_dt.getUTCFullYear() - 1970);
                            analytics.setUserProperties({age:age});
                        }
                    }).catch((err)=>{
                        console.log(err);
                    });
                }}
            >
              {({
                values,
                handleSubmit,
                setFieldValue,
                errors,
                isSubmitting
              })=>(
                <Form className="register-form">
                <Row >
                    <Col xs={12} md={6} className=" label-container form-left">
                        <Row className="mr-md-0">
                            <Col xs={6} md={8} className="pl-0 label-container">
                            <label htmlFor="firstName">First Name</label>
                            </Col>
                            <Col xs={6} md={4} className="form-error pr-0 pl-0">
                                <ErrorMessage name="firstName"/>
                            </Col>
                        </Row>
                        <Row className="mr-md-0"><Field id="firstName" name="firstName" type="text" className="form-control" placeholder="Enter first name..."/></Row>
                    </Col>
                    <Col xs={12} md={6} className=" label-container form-group">
                        <Row className="ml-md-0">
                            <Col xs={6} md={8} className="pl-0 label-container">
                                <label htmlFor="lastName">Last Name</label>
                            </Col>
                            <Col xs={6} md={4} className="form-error pr-0 pl-0">
                                <ErrorMessage name="lastName"/>
                            </Col>
                        </Row>
                        <Row className="ml-md-0"><Field id="lastName" name="lastName" type="text" className="form-control" placeholder="Enter last name..."/></Row>
                    </Col>
                </Row>
                <div className="form-group">
                    <Row>
                        <Col xs={3}  className="pl-0 label-container">
                        <label htmlFor="email">Email</label>
                        </Col>
                        <Col xs={9}  className="form-error pr-0">
                            <ErrorMessage name="email"/>
                        </Col>
                    </Row>
                    <Row >
                        {(user.email==="") ? 
                        <Field id="email" name="email" type="text" className="form-control" placeholder="Enter a valid email..."/>
                        : 
                        <Field disabled id="email" name="email" type="text" className="form-control" placeholder="Enter a valid email..."/>
                        }
                    </Row>
                </div>
                <div className="form-group">
                    <Row>
                        <Col xs={8}  className="pl-0 label-container">
                            <label htmlFor="type">Erasmus/ESN/Mentor/Foreign Student ?</label>
                        </Col>
                        <Col xs={4}  className="form-error pr-0">
                            <ErrorMessage name="type"/>
                        </Col>
                    </Row>
                    <Row>
                        <Field id="type" name="type" as="select" className="form-control">
                            <option value="">Select one</option>
                            <option value="erasmus">Erasmus</option>
                            <option value="fulltime">Foreign Student</option>
                            <option value="esn">ESN</option>
                            <option value="mentor">Mentor</option>
                        </Field>
                    </Row>

                    <Row>

                        <Col xs={8}  className="pl-0 label-container">
                        <label htmlFor="country">Country</label>
                        </Col>
                        <Col xs={4}  className="form-error pr-0">
                            <ErrorMessage name="country"/>
                        </Col>
                    </Row>
                    <Row>
                        <SelectCountry
                        className="form-control"
                        name="country"
                        id="country"
                        onSelect={setFieldValue}
                        defaultCountry={values.country}
                        />
                    </Row>
                </div>
                <div className="form-group">
                    <Row>
                        <Col xs={8}  className="pl-0 label-container">
                        <label htmlFor="birthdate">Birthdate</label>
                        </Col>
                        <Col xs={4}  className="form-error pr-0">
                            <ErrorMessage name="birthdate"/>
                        </Col>
                    </Row>
                    <Row>
                    <DatePickerField
                            className="form-control"
                            name="birthdate"
                            id="birthdate"
                            onChange={setFieldValue}
                            value={values.birthdate}
                            showTimeSelect={false}
                            clearable={true}
                            maxDate={maxBirthDate}
                            showMonthDropdown={true}
                            showYearDropdown={true}
                            dateFormat={"MMMM d, yyyy"}
                        />
                    </Row>
                </div>

                <Row className="form-group">
                    <button className="btn btn-primary mx-auto" type="submit" disabled={isSubmitting}>Apply Changes</button>
                </Row>
                </Form>
              )}  
            </Formik>
            </div>
        </div>
     );
}
 
export default DataFill;