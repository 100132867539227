import React, {Component} from 'react';
import Loading from '../components/Loading';
import dayjs from 'dayjs';
import firebase from '../firebase';
import close from '../images/close.svg';
import calendarGray from '../images/calendar.svg';
class Tasks extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            allEventsWithTasks: null,
            showAllEvents: false
         }
    }
    componentDidMount() {
        this.loadTasks();
    }
    loadTasks = () =>{
        firebase.firestore().collection('tasks').where('tasksCounter','>',0).get().then((doc)=>{
            const events = [];
            const eventsIDs = [];
            doc.forEach(event=>{
                const eventId = event.id;
                const eventData = event.data();

                const eventWithTasks = {
                    id: eventId,
                    name: eventData.eventName,
                    startTime: eventData.eventStartTime, 
                    tasks: eventData.tasks,
                    zarzadOnly:eventData.zarzadOnly ? true:false
                }
                if(!(eventData.zarzadOnly && !this.props.user.isZarzad)){
                    events.push(eventWithTasks);
                    eventsIDs.push(eventId);
                }
            });
            // setEventsWithTasks({events,eventsIDs});
            this.setState({allEventsWithTasks: events});
        });
    }
    cancelVolunteer = (eventId, selectedTaskName) =>{
        const userFullName = this.props.user.fullName;
        firebase.firestore().collection('tasks').doc(eventId).get().then(doc =>{
            const tasksArray = doc.data().tasks;
            tasksArray.forEach(task =>{
                const currentTaskName = Object.keys(task)[0];
                if(currentTaskName===selectedTaskName && task[currentTaskName].includes(userFullName))
                    task[currentTaskName] = task[currentTaskName].filter((volunteer)=>{
                        return volunteer !==userFullName;
                    });
            });
            firebase.firestore().collection('tasks').doc(eventId).update({tasks:tasksArray,tasksCounter:tasksArray.length}).then(e=>{
                this.loadTasks();
            });
        });
    }
    addVolunteer = (eventId, selectedTaskName) =>{
        const userFullName = this.props.user.fullName;
        firebase.firestore().collection('tasks').doc(eventId).get().then(doc =>{
            const tasksArray = doc.data().tasks;
            tasksArray.forEach(task =>{
                const currentTaskName = Object.keys(task)[0];
                if(currentTaskName===selectedTaskName && !task[currentTaskName].includes(userFullName))
                    task[currentTaskName].push(userFullName);
            });
            firebase.firestore().collection('tasks').doc(eventId).update({tasks:tasksArray,tasksCounter:tasksArray.length}).then(e=>{
                this.loadTasks();
            });
        });
    }
    handleToggleOldEvents = () =>{
        if(this.state.showAllEvents){
            this.setState({showAllEvents:false});
        }else{
            this.setState({showAllEvents:true});
        }
    }
    render() { 
        const {allEventsWithTasks, showAllEvents} = this.state;
        const {user} = this.props;
        return ( 
            allEventsWithTasks!=null && user ? 
            <div className="col-md-10 mx-auto tasks-container page-container">
                <h3 className="page-title">Tasks</h3>
                <div className="tasks-list">
                {/* {console.log('ilosc eventow: ' +allEventsWithTasks)} */}
                        {/* {allEventsWithTasks.Length===0 ? <h5>No i co, nie ma nic do roboty, idź lepiej wymyśl jakiś event...</h5> : ''} */}
                        {allEventsWithTasks.map(event=>{
                            const isBeforeEvent =  Date.now()<event.startTime.seconds*1000;
                            const zarzadClass = event.zarzadOnly ? 'zarzad-only' : '';
                            if(!showAllEvents && !isBeforeEvent)
                                return false;
                            
                            return(
                                <div key={event.id} className={`event ${zarzadClass}`}>
                                <div className="title">
                                        <h4 className="name">{event.name}</h4>
                                    <div className="time"><img src={calendarGray} alt="time"/><div>{dayjs(event.startTime.seconds*1000).format('DD-MM-YYYY')}</div></div>
                                </div>
                                <div className="content">
                                    {/* <span><b>Tasks:</b> </span> */}
                                    {event.tasks.map((task,index)=>{
                                        const taskName = Object.keys(task)[0];
                                        //List all tasks
                                        return(
                                            <div key={event.id+'-'+index} className="task">
                                                <div className="task-name">{taskName}</div>
                                                <ul className="volunteers">
                                                    {/* List all volunteers */} 
                                                    {task[taskName].map(volunteer =>{
                                                        /* WARN: Potential issue - identified by fullName - there might be an exception */
                                                        return (<li key={event.id+"-"+volunteer}>{volunteer} {(volunteer===user.fullName && isBeforeEvent) ? <img src={close} className="resign-cross" alt="resign" onClick={()=>{this.cancelVolunteer(event.id,taskName)}}/> : ''} </li> )
                                                    })}
                                                </ul>
                                                {(task[taskName].includes(user.fullName) || !isBeforeEvent) ? '' : <button className="btn btn-primary" onClick={()=>{this.addVolunteer(event.id,taskName)}}>Volunteer</button>}
                                            </div>
    
                                        )
                                    })}
                                </div>
                                {/* <div className="footer">

                                </div> */}
                                </div>
                            )
                        })}
                </div>
                <div className="text-center">
                <button className="btn dark-blue-button" onClick={()=>{this.handleToggleOldEvents()}}>{showAllEvents ? "Hide past events" : "Show past events"}</button>
                </div>

            </div>
            : <Loading/> 
         );
    }
}
 
export default Tasks;
