import React from 'react';
import banner from '../../images/photos/photo.jpg';
import ESNstar from '../../images/esn_logo.png'
const AboutUs = () => {
    return ( 
        <div className="article-wrapper">
            <div className="image-wrapper container-fluid">
                <img src={banner} alt=""/>
            </div>
            <div className="content">
                <h3 className="star-header"><img src={ESNstar} alt=""/>Who are we?</h3>
                <div className="description">
                                       We are the ESN SGGW Association at Warsaw University of Life Sciences and our motto is <span className="bold">"Students helping Students"!</span> <br/><br/>
                    Our main goal is to help the students who will spend their international exchange at our university. We organise free time for our guests from abroad and help them to accomodate in Poland!  We make sure, that they feel welcome in our country, our city and – of course – our University. We believe that thanks to our effort and positive way of thinking of our Erasmus students they will experience the greatest adventure of their lives!<br/><br/>
                    We also promote mobility amongst Polish students and inform people about all the opportunities to go abroad and help them in case of any doubts!<br/><br/>
                </div>
            </div>
        </div>
     );
}
 
export default AboutUs;