import React, {useEffect} from 'react';
import ESNstar from '../images/esn_logo.png'
import OwlCarousel from 'react-owl-carousel';
import facebookLogo from '../images/ikony/facebookLogo.png'
import instagramLogo from '../images/ikony/instagramLogo.png'
import instagramBackground from '../images/instagramBackground_2.jpg'
import facebookBackground from '../images/facebookBackground.jpg'
import facebookLogoDefault from '../images/ikony/facebookLogo_default.png'
import instagramLogoDefault from '../images/ikony/instagramLogo_default.png'
import aboutPhoto from '../images/photos/photo.jpg';
import Loading from './Loading';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import LazyHomeCarousel from './HomeCarousel';
const partners = [
    {
        name:'Browar Jabłonowo',
        logo: 'jablonowo.png',
        site: 'https://jablonowo.pl/'
    },
    {
        name:'Redbull',
        logo: 'redbull.png',
        site: 'https://www.redbull.com/int-en/'
    },
    {
        name:'SamSmak',
        logo: 'samsmak.png',
        site: 'https://www.samsmak.com/'
    },
    {
        name:'2KC',
        logo: '2kc.png',
        site: 'https://www.2kc.pl/'
    },
    {
        name: 'ProfiLingua',
        logo: 'profilingua.png',
        site: 'https://www.profi-lingua.pl/2104-erasmus'
    },
    {
        name: 'Eurosender',
        logo: 'eurosender.png',
        site: '#/articles/eurosender'
    },
    {
        name:'British Council',
        logo: 'britishcouncil.png',
        site: 'https://www.britishcouncil.pl/en'
    },
];
const Home = (props) => {
    return ( 
        <div className="page-container home-container">


        {/* PepeHousing, OrientationWeek, ESNcard */}
        <LazyHomeCarousel updateHomePageLoad = {props.updateHomePageLoad} wasHomePageLoaded={props.wasHomePageLoaded}/>


        {/* Who are we? */}
        <div className="about-container">
            <div className="row col-md-10">

                <div className="col-xs-12 col-lg-6">
                    <h3 className="star-header"><img src={ESNstar} alt=""/>Who are we?</h3>
                    <div className="description">
                    We are the ESN SGGW Association at Warsaw University of Life Sciences and our motto is <span className="bold">"Students helping Students"!</span> <br/><br/>
                    Our main goal is to help the students who will spend their international exchange at our university. We organise free time for our guests from abroad and help them to accomodate in Poland!  We make sure, that they feel welcome in our country, our city and – of course – our University. We believe that thanks to our effort and positive way of thinking of our Erasmus students they will experience the greatest adventure of their lives!<br/><br/>
                    We also promote mobility amongst Polish students and inform people about all the opportunities to go abroad and help them in case of any doubts!<br/><br/>
                    </div>
                </div>
                <div className="col-xs-12 col-lg-6 image-container">
                    <img src={aboutPhoto} alt="Farewell 2018"/>
                </div>
            </div>
        </div>

        {/* FB/Instagram */}
        <div className="social-media-container">
            <h4>Follow us on social media!</h4>
            <div className="backgrounds col-md-10">
                <a href="https://www.facebook.com/esn.sggw/" target="_blank" rel="noopener noreferrer" className="facebook-wrapper sm-wrapper">
                    <div className="background">
                        <div className="grayout">
                            <img src={facebookLogoDefault} alt=""/>
                            <span>@esn.sggw</span>
                        </div>
                        <img src={facebookBackground} alt=""/>
                    </div>
                    <img className="logo" src={facebookLogo} alt="Facebook"></img>

                    {/* <div className="text">Facebook<br/><a href="https://www.facebook.com/esn.sggw/">Esn.sggw</a></div> */}

                </a>
                <a href="https://www.instagram.com/esnsggw/" target="_blank" rel="noopener noreferrer" className="instagram-wrapper sm-wrapper">
                    <div className="background">
                        <div className="grayout">
                            <img src={instagramLogoDefault} alt=""/>
                            <span>#esnsggw</span>
                        </div>
                        <img src={instagramBackground} alt=""/>
                    </div>
                    <img className="logo" src={instagramLogo} alt="Instagram"></img>
                </a>
            </div>
        </div>



        {/* Partnerzy owl-carousel */}
        <OwlCarousel
        className="owl-theme"
        loop
        margin={10}
        autoplay
        items={window.innerWidth<768 ? 3 : 5}
        dots={false}
        nav={false}
        >
            {partners.map(partner => <a key={partner.site} href={partner.site} title={partner.name} target="_blank" rel="noopener noreferrer" className="item"><img src={require('../images/partnerLogos/'+partner.logo)} alt={partner.name}/></a>)}


    </OwlCarousel>
        </div>
     );
}
 
export default Home;