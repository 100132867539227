import React from 'react';
import banner from '../../images/homeSlider/mentor.jpg';
import ESNstar from '../../images/esn_logo.png'
const Pepehousing = () => {
    return ( 
        <div className="article-wrapper">
            <div className="image-wrapper container-fluid">
                <img src={banner} alt=""/>
            </div>
            <div className="content">
                <h3 className="star-header"><img src={ESNstar} alt=""/>Program Mentor</h3>
                <div className="description">
                <h5><span className="bold">Z czym to się je - czyli kim jest Mentor?</span></h5>
                Mentor jest wolontariuszem, który pomaga studentowi zza granicy przebywającemu w Polsce na wymianie. Powinien starać się, aby jego podopieczny już od pierwszego dnia czuł się w Polsce dobrze i bezpiecznie. To także osoba, którą podopieczny – zwany Buddym – może zapytać o tutejsze zasady, zwyczaje czy kulturę.<br/><br/>
                <h5><span className="bold">Co dostaniesz w zamian?</span></h5>
                Wiele z Mentorów podejmuje trud pracy w każdym semestrze, opiekując się nawet kilkoma osobami. Dlaczego?<br/>
                Przede wszystkim, bycie Mentorem to poznawanie niezwykłych ludzi z innych zakątków świata. To niepowtarzalna szansa na rozwinięcie w sobie wrażliwości, tolerancji, ale również pozyskanie wiedzy o innych nacjach. To nauka języka obcego i przełamywanie barier w mówieniu – umiejętności niezwykle ważne dla pracodawców. No i w końcu – to olbrzymia satysfakcja, niepowtarzalna atmosfera i świetna zabawa w międzynarodowym towarzystwie! Kto wie – może bycie Mentorem zaowocuje wieloletnią przyjaźnią z osobą z innego kraju?<br/>
                Jeżeli tak jak my uważasz, że to wszystko brzmi kusząco – nie zwlekaj! Przy odrobinie zaangażowania możesz zyskać naprawdę wiele!<br/><br/>
                <h5><span className="bold">Jak dołączyć?</span></h5>
                Najbliższa rekrutacja odbędzie się z początkiem przyszłego semestru - znajdziesz o niej informacje tutaj oraz na naszym <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/esn.sggw/">Fanpage'u</a>!
                </div>
            </div>
        </div>
     );
}
 
export default Pepehousing;