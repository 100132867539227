import React from 'react';
import ESN_LOGO_WHITE from '../images/ESN_SGGW_allwhite.png'
import {NavLink} from 'react-router-dom'
const Footer = () => {
    return ( 
        <div className="footer-wrapper">
            <div className="footer container col-md-10">
                <div className="esn-full-logo">
                    <img src={ESN_LOGO_WHITE} alt="ESN SGGW logo"/>
                    <NavLink to="/privacy-policy">Privacy Policy</NavLink>

                </div>
                <div className="about-us">
                    <div className="title">
                        About us
                    </div>
                    <div className="desc">
                    Erasmus Student Network (ESN) is a non-profit international student organisation. Our mission is to represent international students, thus provide opportunities for cultural understanding and self-development under the principle of Students Helping Students.
                    </div>
                </div>
                <div className="address">
                    <div className="title">Address</div>
                    <div className="desc">
                        Eramus Student Network SGGW <br/>
                        ul. Nowoursynowska 161I, DS Ikar, room 102 (annex) <br/>
                        02-787 Warszawa, Poland
                    </div>
                </div>
            </div>
            <div className="footer-low">
                <div className="col-md-10 container">
                    <div className="left">
                        © 2020 Copyright: Kamil Grzegorek.<br/> SVG icons (Roundicons, Pixel Perfect, Freepik) from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Footer;