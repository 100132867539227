import { combineReducers } from 'redux';
import getTest  from './createTest';
import {firestoreReducer} from 'redux-firestore';
import authReducer from './authReducer';
import swReducer from './swReducer';
const rootReducer = combineReducers({
    firestore: firestoreReducer,
    getTest: getTest,
    auth: authReducer,
    swReducer: swReducer
})
export default rootReducer