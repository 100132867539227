import React, {useState} from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import mapStyles from './mapStyles.js';
import sidebarToggleIcon from '../images/left_fat.svg';
import Loading from './Loading';
const atms = [
    {name: "Euronet ATM",lat:52.16350961721553,lng:21.03670472183002,icon: "atm_pin.png",icon2: "atm.svg"},

{name: "Euronet ATM",lat:52.16479058438618,lng:21.04411670988553,icon: "atm_pin.png",icon2: "atm.svg"},

{name: "Euronet ATM",lat:52.162474016494585,lng:21.029182171066253,icon: "atm_pin.png",icon2: "atm.svg"},

{name: "Euronet ATM",lat:52.159788752814535,lng:21.037336085938843,icon: "atm_pin.png",icon2: "atm.svg"},

{name: "Euronet ATM",lat:52.15399645603016,lng:21.040511821205016,icon: "atm_pin.png",icon2: "atm.svg"}

]
const currencyExchange=[
    {name: "LOMBARD KANTOR KOMISJI EDUKACJI NARODOWEJ 96 KEN",address: "Aleja Komisji Edukacji Narodowej 96/U14, 02-777 Warszawa",lat:52.16152111190565,lng:21.029010494310047,icon: "exchange_pin.png",website: "https://www.loombard.pl/locations/warszawa-komisji-edukacji-narodowej-96u14-warszawa-f",icon2: "exchange.svg"},
{name: "Max Kantor - Kantor wymiany walut",address: "Aleja Komisji Edukacji Narodowej 90, 02-777 Warszawa",lat:52.15873048071598,lng:21.032529552307693,icon: "exchange_pin.png",website: "http://max-kantor.pl/",icon2: "exchange.svg"},
{name: "INGENSUM Sp. z o.o.",address: "Aleja Komisji Edukacji Narodowej, 02-777 Warszawa",lat:52.15367530684182,lng:21.03982516067118,icon: "exchange_pin.png",website: "https://topfx.pl/",icon2: "exchange.svg"},
{name: "Kantor Wymiany Walut, CH KEN Center",address: "Jana Ciszewskiego 15, 02-777 Warszawa",lat:52.15367530684182,lng:21.0434300493517,icon: "exchange_pin.png",icon2: "exchange.svg"},
{name: "LOMBARD KANTOR KEN Ghandi",address: "róg I. Gandhi, Aleja Komisji Edukacji Narodowej, 02-766 Warszawa",lat:52.150936847953254,lng:21.044631679332433,icon: "exchange_pin.png",website: "https://lombardkenimielinwarszawa.business.site/?utm_source=gmb&utm_medium=referral",icon2: "exchange.svg"}

]
const places=[
    {name: "Teatro Cubano",category: "Entertainment",address: "Aleksandra Fredry 6, 00-097 Warsaw",lat:52.242148,lng:21.009776,icon: "entertainment_pin.png",website: "https://www.warsaw.teatrocubano.com/",icon2: "entertainment.svg",description: "One of the biggest clubs in Warsaw - especially popular on Mondays due to free entrance and a very cheap cuba libre ;)"},
    {name: "Manekin",category: "Food/Restaurants",address: "Plac Konstytucji 5, 00-657 Warsaw",lat:52.222648,lng:21.015050,icon: "food_pin.png",website: "http://manekin.pl/",icon2: "food.svg",description: "Pancakes restaurant beloved by Erasmus students! The queues to get inside can sometimes be a bit long, but it's definitely worth the wait!"},

    {name: "Manekin",category: "Food/Restaurants",address: "Marszałkowska 140, 00-970 Warsaw",lat:52.237751,lng:21.007610,icon: "food_pin.png",website: "http://manekin.pl/",icon2: "food.svg",description: "Pancakes restaurant beloved by Erasmus students! The queues to get inside can sometimes be a bit long, but it's definitely worth the wait!"},

    {name: "Zapiecek",category: "Food/Restaurants",address: "Nowy Świat 64, 00-357 Warsaw",lat:52.236569,lng:21.018274,icon: "food_pin.png",website: "https://www.zapiecek.eu/main.html",icon2: "food.svg",description: "One of the most known places with Polish food - they have plenty of restaurants all over Warsaw."},

    {name: "ESN SGGW Office",category: "Other",address: "Nowoursynowska 161I, room 102, 02-787 Warsaw",lat:52.163758,lng:21.039515,icon: "other_pin.png",website: "https://erasmus-sggw.pl/#/",icon2: "other.svg",description: "Oh hey! That's us!"},

    {name: "Łazienki Królewskie",category: "Nature",address: "Łazienki Królewskie, Warsaw",lat:52.215153,lng:21.034997,icon: "nature_pin.png",website: "https://www.lazienki-krolewskie.pl/en",icon2: "nature.svg",description: "The most famous park in Warsaw - definitely a place worth visiting - in autumn you'll have a chance to see plenty of squirrels running around!"},

    {name: "University of Warsaw Library Garden",category: "Nature",address: "Dobra 56/66, 00-312 Warsaw",lat:52.242563,lng:21.024773,icon: "nature_pin.png",website: "https://www.buw.uw.edu.pl/en/",icon2: "nature.svg",description: "A nice place to see nature on the top of a library, there are even binoculars, for you to see your favorite buildings in warsaw"},
    {name: "PKiN",category: "Culture/History",address: "plac Defilad 1, 00-901 Warsaw",lat:52.231841,lng:21.006000,icon: "history_pin.png",website: "http://www.pkin.pl/eng",icon2: "history.svg",description: "The tallest and most known building in Poland. Inside you can find restaurants, theatres, museums, a viewing terrace and more."},
    {name: "Old Town",category: "Culture/History",address: "Warsaw",lat:52.249776,lng:21.012166,icon: "history_pin.png",website: "",icon2: "history.svg",description: "Oldest part of Warsaw, a must-see place when in the capital."},
{name: "Klub No.22",category: "Entertainment",address: "Bogumiła Wojciecha Jastrzębowskiego 22, 02-786 Warsaw, Poland",lat:52.161240,lng:21.036557,icon: "entertainment_pin.png",website: "https://www.facebook.com/KlubNo22",icon2: "entertainment.svg",description: "It's a club and a pub. It's really close to gas station near SGGW. They have plenty events like karaoke and watching football matches. They have also some other attractions like billiards, darts or even table football."},
{name: "Pawilony",category: "Entertainment",address: "Nowy Świat 22/28, 00-373 Warsaw",lat:52.233055,lng:21.020121,icon: "entertainment_pin.png",website: "http://pawilonynowyswiat.pl",icon2: "entertainment.svg",description: "One of the best place to spend time with your friends! You will find there more than 20 pubs and bars in one place."},
{name: "Zagrywki",category: "Entertainment",address: "Nowy Świat 4a, 00-496 Warsaw",lat:52.230428,lng:21.022096,icon: "entertainment_pin.png",website: "https://zagrywki.pl/en/zagrywki/",icon2: "entertainment.svg",description: "First activity bar in Poland. Mini golf, karaoke bars, shuffle boards, beer pong & more!"},
{name: "International Relations Office",category: "Other",address: "Nowoursynowska 166, building 8, 02-787 Warsaw",lat:52.163738,lng:21.049229,icon: "other_pin.png",website: "https://www.sggw.pl/en/international-cooperation_/organization/office-of-international-cooperation",icon2: "other.svg",description: "The place to go to whenever you need to discuss anything related to your Erasmus mobility"},
{name: "Mr. Pancake",category: "Food/Restaurants",address: "Marszałkowska 17, 00-628 Warsaw",lat:52.2165399,lng:21.0196113,icon: "food_pin.png",website: "http://www.mrpancake.pl/",icon2: "food.svg",description: "Awesome Pancakes and also crazy idea pizzas"},
{name: "Ogród Botaniczny UW",category: "Nature",address: "al. Ujazdowskie 4, 00-478 Warszawa",lat:52.217833,lng:21.027382,icon: "nature_pin.png",website: "http://www.ogrod.uw.edu.pl/aktualnosci",icon2: "nature.svg",description: "In the immediate vicinity of SGGW there is a botanical garden. This lovely place offers a great variety of plants from around the world. Many photographers or artists may find the garden inspiring due to its wonderful landscape and biodiversity. It’s also a perfect place for plant lovers to enhance their interests in gardening."},
{name: "Muzeum Neonów",category: "Entertainment",address: "Soho Factory, Mińska 25, Praga District, 03-808 Warszawa",lat:52.250157,lng:21.062348,icon: "entertainment_pin.png",website: "http://www.neonmuzeum.org/",icon2: "entertainment.svg",description: "One of the most spectacular places is the Neon Museum. It’s dedicated to preserve the Cold War neon signs. The museum has a wonderful atmposphere, a perfect one to do the photoshoot and it’d opened 7 days per week!"},
{name: "Muzeum Polskiej Wódki",category: "Culture/History",address: "Plac Konesera 1, 03-736 Warszawa",lat:52.255394,lng:21.045949,icon: "history_pin.png",website: "http://www.muzeumpolskiejwodki.pl/",icon2: "history.svg",description: "I bet that when you think about Poland, vodka is one of your associations. Some Poles do realise that so they’ve found the Museum of Polish Vodka! A place where you can see how it’s produced, taste it and have lots of fun is just around the corner."},
{name: "ZOO",category: "Nature",address: "Ratuszowa 1/3, 03-461 Warszawa",lat:52.258110,lng:21.021574,icon: "nature_pin.png",website: "https://zoo.waw.pl/",icon2: "nature.svg",description: "If you have seen “The Zookeeper’s Wife” with Jessica Chastain, you must visit the Zoo. If you haven’t, you need to catch up with both! The zoological garden is located in the beautiful district of Warsaw - Praga Północ. Plenty of wild animals and natural vegetation will please even the most demanding ones."},,
{name: "Poniatówka (beach)",category: "Nature",address: "Most Księcia Józefa Poniatowskiego, 03-901 Warszawa",lat:52.236529,lng:21.042084,icon: "nature_pin.png",website: "",icon2: "nature.svg",description: "There is unfortunately no access to the sea in Warsaw. However, we do have beaches! The one close to the Most Poniatowskiego is very popular among young folks. A great place to relax and hang out with some friends. Another spot to chill by the river is very well-known Schodki! "},
{name: "Bulwary nad Wisłą",category: "Entertainment",address: "Wybrzeże Kościuszkowskie, 00-123 Warszawa",lat:52.241482,lng:21.030650,icon: "entertainment_pin.png",website: "",icon2: "entertainment.svg",description: <div>'Weather is nice? Do you feel like chilling at the river? "Bulwary nad Wisłą", otherwise known as <a target="_blank" href="https://www.youtube.com/watch?v=AjrtRgZdzgU&ab_channel=SBMLabel">"Schodki"</a> is the place to go!</div>},
{name: "Plaża Jeziorko Czerniakowskie",category: "Nature",address: "Jeziorna, 00-001 Warszawa",lat:52.188009,lng:21.072174,icon: "nature_pin.png",website: "",icon2: "nature.svg",description: "Jeziorko Czerniakowskie is a small lake in amazingly lovely and peaceful area. It is really good spot to hang out with your friends during hot summer days. Especially because of special area where you can go and swim."},
{name: "Vegemiasto",category: "Food/Restaurants",address: 'aleja "Solidarności" 60A, 00-240 Warszawa',lat:52.246033,lng:21.005016,icon: "food_pin.png",website: "http://www.vegemiasto.pl/",icon2: "food.svg",description: "Vege restaurant which offers delicius food and is in walking distance from Metro Ratusz Arsenał."},
{name: "Las Kabacki",category: "Nature",address: "Las Kabacki im. Stefana Starzyńskiego",lat:52.116755,lng:21.059249,icon: "nature_pin.png",website: "",icon2: "nature.svg",description: "Las Kabacki is one of the most famous forest parks in Warsaw as well as in Poland. There are plenty of trees and bushes. It’s a fantastic spot for jogiing or cycling. You can also go for a walk and listen to the birds singing to calm down. A peace of mind guaranteed!"},
{name: "Hydrozagadka",category: "Entertainment",address: "1 Listopada 22, 03-436 Warszawa",lat:52.242563,lng:21.036863,icon: "entertainment_pin.png",website: "https://hydrozagadka.waw.pl/",icon2: "entertainment.svg",description: "Club known for their 80s/90s parties"},
{name: "Thebooks",category: "Other",address: "Puławska 140, 02-624 Warsaw",lat:52.190541,lng:21.024052,icon: "other_pin.png",website: "https://thebooks.pl/",icon2: "other.svg",description: "Looking for a cheap book in English (or Spanish, French)? That's the place you might be interested in!"},
{name: "Smolna",category: "Entertainment",address: "Smolna 38, 00-375 Warszawa",lat:52.232667,lng:21.021636,icon: "entertainment_pin.png",website: "https://www.smolna38.com/en",icon2: "entertainment.svg",description: "Looking for a techno club in the capital? Definitely give this place a go. Entrance is usually 40PLN"},
{name: "Aioli",category: "Food/Restaurants",address: "Chmielna 26, 05-800 Warszawa",lat:52.232276,lng:21.013449,icon: "food_pin.png",website: "http://aioli.com.pl/en/home-spot/",icon2: "food.svg",description: "One of 2 Aioli locations in Warsaw. Both have great breakfast deals, you can get breakfast+coffee for around 15PLN"},
{name: "Krowarzywa",category: "Food/Restaurants",address: "Hoża 29/31",lat:52.226330991224344 ,lng:21.015016312044555,icon: "food_pin.png",website: "https://krowarzywa.pl/en/",icon2: "food.svg",description: "Krowarzywahas a lot of very tasty vegan fast food options. "},
{name: "Non Solo pizza",category: "Food/Restaurants",address: "Grójecka 28/30, 02-308 Warszawa",lat:52.22097746609747,lng: 20.98467492166372,icon: "food_pin.png",website: "http://nonsolo.pl/menu/",icon2: "food.svg",description: "You will eat tasty pizzas with fresh tomato sauce and lots of great pasta"},
{name: "Miss Kimchi ",category: "Food/Restaurants",address: "Żelazna 58, 00-806 Warszawa",lat:52.23533809448669,lng:20.990875562740403,icon: "food_pin.png",website: "",icon2: "food.svg",description: "You will eat fresh Korean food with lots of different interesting additives"},
{name: "Little Georgia restaurant",category: "Food/Restaurants",address: "Nowogrodzka 40, 00-691 Warszawa",lat:52.22866026632097,lng:21.010598191576065,icon: "food_pin.png",website: "http://www.mala-gruzja.pl/about-us.php",icon2: "food.svg",description: "Great Georgian cuisine, perfect for cold days"}

];  
const containerStyle = {
    width: 'auto',
    height: 'calc(100vh - 65px)'
  };
   
  const center = {
    lat: 52.237049,
    lng: 21.017532
  };
  const options={
      styles:mapStyles,
      disableDefaultUI:true,
      zoomControl: true,
      clickableIcons: false
  }

//   var image = {
//     url:testIcon, scaledSize: new google.maps.Size(15,25)
//   }
const ErasmusMap = (props) => {

    const mapRef = React.useRef();
    const [selected, setSelected]=useState(null);
    const [sidebarOpen, toggleSidebar] = useState(window.innerWidth<768 ? false: true);
    const [currentCategory, changeCategory]= useState("all");
    const onMapLoad = React.useCallback((map)=>{
        mapRef.current=map;
    })
    const {isLoaded, loadError}=useLoadScript({
        googleMapsApiKey:process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    });
    const handleOpenSidebar = () =>{
        toggleSidebar(!sidebarOpen);
    }
    const handleClickList = (place) =>{
        setSelected(place);
        const bounds = new window.google.maps.LatLngBounds({lat:place.lat, lng:place.lng});
        console.log(bounds);
        mapRef.current.fitBounds(bounds);
        mapRef.current.setZoom(15);
    }
    if(loadError) return "Error loading map";
    if(!isLoaded) return <Loading/>;
    return  <div className="col-md-12 mx-auto map-container page-container">
        {/* <h3 className="page-title visible-xs">Warsaw Map</h3> */}
        <div id="side-toggle" className={sidebarOpen ? 'active': ''} onClick={handleOpenSidebar}>
            <img src={sidebarToggleIcon} alt="Toggle sidebar"/>
        </div>
        <div id="sidebar-map" className={sidebarOpen ? 'active': ''}>
            <div className="select-category">
                <select className="form-control" onChange={e => changeCategory(e.target.value)}>
                    <option value="all">All</option>
                    <option value="food/restaurants">Food / Restaurants</option>
                    <option value="entertainment">Entertainment</option>
                    <option value="nature">Nature</option>
                    <option value="culture/history">Culture / History</option>
                    <option value="other">Other</option>
                </select>
            </div>
            <div className="places">

            {currentCategory!=="all" ? 
            places.filter((place)=>{
                return place.category.toLowerCase() === currentCategory.toLowerCase();
            }).map((place)=>{
                return(
                    <div key={place.name+place.lat} className="item" onClick={()=>{handleClickList(place)}}>
                        {place.category==="Culture/History" ? <div className={`left-background history`}></div> : 
                        place.category==="Food/Restaurants" ? <div className={`left-background food`}></div> : 
                        <div className={`left-background ${place.category.toLowerCase()}`}></div>}
                        <div className="icon-map"><img src={require('../images/mapLogos/'+place.icon)}/></div>
                        <div className="text">
                            <div className="name">{place.name}</div>
                            <div className="description">{place.description}</div>
                        </div>
                    </div>
                )
            })
            :
            places.map((place)=>{
                return(
                    <div key={place.name+place.lng} className="item" onClick={()=>{handleClickList(place)}}>
                        {place.category==="Culture/History" ? <div className={`left-background history`}></div> : 
                        place.category==="Food/Restaurants" ? <div className={`left-background food`}></div> : 
                        <div className={`left-background ${place.category.toLowerCase()}`}></div>}
                        <div className="icon-map"><img src={require('../images/mapLogos/'+place.icon)}/></div>
                        <div className="text">
                            <div className="name">{place.name}</div>
                            <div className="description">{place.description}</div>
                        </div>
                    </div>
                )
            })}
            </div>

        </div>
        <GoogleMap 
            className="google-map-container"
            mapContainerStyle={containerStyle}
            className="google-map"
            zoom={12}
            center={center}
            options={options}
            onClick={(event)=>{
                // console.log(event);
            }}
            onLoad={onMapLoad}>
                {places.map(place=>{
                    return <Marker
                            key={place.lat+place.lng}
                            id={place.lat+";"+place.lng}
                            position={{lat: place.lat,lng:place.lng} }
                            icon={{url:require('../images/mapLogos/'+place.icon),scaledSize: new window.google.maps.Size(55,77)}}
                            onClick={()=>{
                                setSelected(place);
                            }}
                    />
                })}
                {atms.map(place=>{
                    return <Marker
                            key={place.lat+place.lng}
                            id={place.lat+";"+place.lng}
                            position={{lat: place.lat,lng:place.lng} }
                            icon={{url:require('../images/mapLogos/'+place.icon),scaledSize: new window.google.maps.Size(55,77)}}
                            onClick={()=>{
                                setSelected(place);
                            }}
                    />
                })}
                {currencyExchange.map(place=>{
                    return <Marker
                            key={place.lat+place.lng}
                            id={place.lat+";"+place.lng}
                            position={{lat: place.lat,lng:place.lng} }
                            icon={{url:require('../images/mapLogos/'+place.icon),scaledSize: new window.google.maps.Size(55,77)}}
                            onClick={()=>{
                                setSelected(place);
                            }}
                    />
                })}

                    {/* <Marker
                        position={{lat: 52.242202,lng:21.009776} }
                        icon={cubanoIcon}
                        onClick={(event)=>{
                            // console.log(event.latLng.lat());
                            setSelected(event);
                        }}
                    />
                    <Marker
                        position={{lat: 52.222761,lng:21.015421}}
                        icon={{url:manekinIcon,scaledSize:new window.google.maps.Size(60,84)}}
                    />
                    <Marker
                        position={{lat: 52.237995,lng:21.008429}}
                        icon={{url:manekinIcon,scale:0.3}}
                    /> */}

            {selected ? 
            <InfoWindow
                position={{lat:selected.lat, lng:selected.lng}} 
                onCloseClick={()=>{
                    setSelected(null);
                }}>
                <div className="info-window">

                    <div className="heading">
                        <h5>{selected.name}</h5>
                        <div><img src={require('../images/mapLogos/'+selected.icon2)} alt=""/></div>
                    </div>
                    {selected.address ? <div className="address">{selected.address}</div> : '' }
                    {selected.description ? <div className="description">{selected.description}</div> : ''}
                    {selected.website ? <div className="website">{selected.website ? <a taget="_blank" rel="noopener noreferrer" href={selected.website}>Website</a> : ''}</div> : ''}
                </div>
            </InfoWindow> : null}
        </GoogleMap>
    </div>

    // const [map, setMap] = React.useState(null)
    // const onLoad = React.useCallback(function callback(map) {
    //     const bounds = new window.google.maps.LatLngBounds(center);
    //     console.log(bounds);
    //     map.fitBounds(bounds);
    //     console.log(map.getZoom());
    //     setMap(map)
    //     map.setZoom(16);
    //     console.log(map);
    //   }, [])
     
    //   const onUnmount = React.useCallback(function callback(map) {
    //     setMap(null)
    //   }, [])

    // return ( 
    //     <div className="col-md-12 mx-auto map-container page-container">
    //         <h3 className="page-title">Warsaw Fun Map</h3>
    //         <LoadScript
    //             googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
    //             >
    //             <GoogleMap
    //                 mapContainerStyle={containerStyle}
    //                 center={center}
    //                 zoom={12}
    //                 defaultZoom={12}
    //                 onUnmount={onUnmount}
    //                 onLoad={onLoad}
    //             >
    //                 <Marker
    //                     position={{lat: 52.242202,lng:21.009776} }
    //                     icon={cubanoIcon}
    //                     />
    //                 {/* <Marker
    //                     position={{lat: 52.342202,lng:21.009776} }
    //                     icon={{url:testIcon, scaledSize: new window.google.maps.Size(15,25)}}
    //                     // icon={image}
    //                     /> */}
    //                 <Marker
    //                     position={{lat: 52.222761,lng:21.015421}}
    //                     icon={{url:manekinIcon,scale:0.3}}
    //                     />
    //                 <Marker
    //                     position={{lat: 52.237995,lng:21.008429}}
    //                     icon={{url:manekinIcon,scale:0.3}}
    //                     />
    //                 { /* Child components, such as markers, info windows, etc. */ }
    //             </GoogleMap>
    //         </LoadScript>
    //     </div>
    //  );
}
 
export default ErasmusMap;