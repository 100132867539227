import React from 'react';
import { Button, Modal } from 'react-bootstrap';
function ErrorModal(props) {
    return (
        <Modal show={props.show} onHide={props.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Oops, something went wrong 😔</Modal.Title>
          </Modal.Header>
          <Modal.Body>There was an error: {props.error}<br/><br/>Please refresh and try again or try a different login method !<br/>If you're accessing the website directly through Facebook, please use a different browser.</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary"  onClick={props.closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
    );
  }
 
export default ErrorModal;