import React from 'react';
import banner from '../../images/homeSlider/rekrutacja-2024.jpg';
import ESNstar from '../../images/esn_logo.png';
import img1 from '../../images/photos/slider-1.jpg'
import img2 from '../../images/photos/slider-2.jpg'
import img3 from '../../images/photos/slider-3.jpg'
import img4 from '../../images/photos/slider-4.jpg'
import img5 from '../../images/photos/slider-5.jpg'
import img6 from '../../images/photos/slider-6.jpg'
import {Carousel} from 'react-bootstrap';
import {NavLink } from 'react-router-dom'

const JoinUS_PL = () => {
    return ( 
        <div className="article-wrapper">
            <div className="image-wrapper container-fluid">
                <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" target="_blank" href="https://fb.me/e/7sYSvEvnY"><img src={banner} alt="Przeżyj Erasmusa nie wyjeżdżając z Polski"/></a>
            </div>

            <div className="content">
                <h3 className="star-header"><img src={ESNstar} alt=""/>Dołącz do nas!</h3>
                <div className="description">
                <p>
                    Studia to bez wątpienia bardzo ważny okres dla nas wszystkich, dlatego nie pozwólmy, aby czas ten minął nam jedynie w towarzystwie książek.
                Jeżeli jesteś osobą, która pragnie urozmaicić swoje życie akademickie, lubi aktywnie spędzać czas, odczuwa silną potrzebę na rozwinięcie swojej kreatywności a co najważniejsze czerpie przyjemność z pracy z ludźmi - weź udział w rekrutacji do ekipy ESN SGGW!</p>

                <p>Będąc w ESN SGGW masz możliwość działania w grupach roboczych:</p>
                <ul className='tjustify'>
                    <li><span className="bold">PR</span> – Public Relations – zarządzanie wizerunkiem Stowarzyszenia – głównie poprzez tworzenie grafik i prowadzenie kont społecznościowych</li>
                    <li><span className="bold">HR</span> – Human Resources – dbanie o “good vibes” w sekcji poprzez motywowanie członków i organizowanie integracji wewnątrz organizacji.</li>
                    <li><span className="bold">FR</span> – Fundraising – utrzymywanie dobrych relacji z partnerami (Red Bull, cukiernia Olsza, Browar Jabłonowo...) oraz organizacja zniżek dla posiadaczy ESNcard</li>
                    <li><span className="bold">Social</span> – organizacja wydarzeń o charakterze społecznym – zbiórki charytatywne, schroniska dla zwierząt, Pola Nadziei, Blind Dinner...	</li>
                    <li><span className="bold">Coolture</span> – organizacja wydarzeń o charakterze kulturowym – Eurodinner, muzea, Thursday Dinner, wydarzenia okolicznościowe (Wielkanoc, Halloween, Wigilia)...</li>
                    <li><span className="bold">Sport</span> – organizacja wydarzeń sporowych, National Erasmus Games</li>
                    <li><span className="bold">Bounce</span> – organizacja imprez – wyjścia do klubów/pubów, organizacja predrinkingów oraz wydarzeń (beerpong, pubcrawl, kręgle, grill)</li>
                    <li><span className="bold">Travel</span> – organizacja wycieczek (Kraków, Wrocław, Trójmiasto, Mazury, Toruń, Łódź, Zakopane)</li>
                </ul>

                <p>A ponadto oferujemy dużo zabawy, mnóstwo nowych znajomości i  niezastąpione towarzystwo najfajniejszej (subiektywnie) ekipy na SGGW :)</p>

                {/* <p>Śledź nasz fanpejdż, by być na bieżąco - informacje o naborze zamieszczane są zwykle zaraz na początku roku akademickiego.</p>

                <p>Przegapiłeś rekrutacje? Nic się nie stało. Napisz maila do naszego Członka Zarządu ds. Zasobów Ludzkich (hr.sggw@esn.pl), wpisując w temacie wiadomości "ESN SGGW Rekrutacja" a w odpowiedzi dostaniesz dalsze instrukcje dotyczące naboru.</p> */}

                <p>Nie zastanawiaj się zbyt długo, czekamy właśnie na Ciebie!!!!</p>

                <p className="bold">Rekrutacje najczęściej rozpoczynają się na przełomie wrzesnia/października oraz w marcu!</p>
                <p>Aby uzyskać więcej informacji, zajrzyj na naszego <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/esnsggw/" >Instagrama</a> oraz <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/esn.sggw">Facebooka</a></p>
                {/* <p className="bold">Rekrutacja obecnie zamknięta - w razie pytań, odezwij się do naszego HRa!</p> */}
                {/* <p className="bold">Formularz rekrutacyjny: <a target="_blank" href="https://forms.gle/5JKtz7krK576ZBB68">https://forms.gle/5JKtz7krK576ZBB68</a></p> */}
                {/* <p>Pierwszy punkt: wypełnienie formatki - <a onClick={(e)=>{e.stopPropagation()}} rel="noopener noreferrer" target="_blank" href="https://docs.google.com/forms/d/1iM9aBJYeIGmfKn1mFcaSCzVo5qX1jG2u9yZ0FAt8Zyc/viewform?fbclid=IwAR20A-wCH34vPvXbHM927Nj5YIw5NLFMhqjLOqPFDfi9sUTVTo238T8L5Vk">Tutaj</a></p> */}
                <Carousel className="photos-carousel" interval={1350}>
                    <Carousel.Item >
                        <img src={img5} alt="" />
                    </Carousel.Item>
                    <Carousel.Item >
                        <img src={img2} alt="" />
                    </Carousel.Item>
                    <Carousel.Item >
                        <img src={img3} alt="" />
                    </Carousel.Item>
                    <Carousel.Item >
                        <img src={img4} alt="" />
                    </Carousel.Item>
                    <Carousel.Item >
                        <img src={img1} alt="" />
                    </Carousel.Item>
                    <Carousel.Item >
                        <img src={img6} alt="" />
                    </Carousel.Item>
                </Carousel>
                </div>
            </div>
        </div>
     );
}
 
export default JoinUS_PL;