import firebase from 'firebase/app';
import 'firebase/firestore';  
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/messaging';
import 'firebase/performance';
const config = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: "sggw-erasmus-1.firebaseapp.com",
    databaseURL: "https://sggw-erasmus-1.firebaseio.com",
    projectId: "sggw-erasmus-1",
    storageBucket: "sggw-erasmus-1.appspot.com",
    messagingSenderId: "410238809968",
    appId: "1:410238809968:web:3e5d7a22cd7a51058b01b5",
    measurementId: "G-WS375F85SG"
  };

  console.log('initializeApp');
firebase.initializeApp(config);
firebase.firestore();
firebase.analytics();
firebase.performance();
// do i need this here?
if(firebase.messaging.isSupported()) {
  firebase.messaging();
}
export default firebase;