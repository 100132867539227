import React from 'react';
import logoESN from '../images/esn_logo.png';
import {Link} from 'react-router-dom';
const NotFound = () => {
    return ( 
        <div className="container page-not-found page-container">
            <h2 className="construction">Page not found!</h2>
            <img className="rotating esn-logo" src={logoESN} alt=""/> 
            <Link className="link" to="/">Go back!</Link>
        </div>
     );
}
 
export default NotFound;