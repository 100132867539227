import React from 'react';
import DatePicker from 'react-datepicker';

const DatePickerField = ({ name, value, onChange, clearable, minDate,maxDate,showTimeSelect,showMonthDropdown,showYearDropdown,dateFormat }) => {
    return (
        <DatePicker
            selected={(value && new Date(value)) || null}
            showTimeSelect={showTimeSelect}
            showMonthSelect
            showYearSelect
            dateFormat={dateFormat}
            minDate={minDate}
            maxDate={maxDate}
            className="form-control"
            onChange={val => {
                onChange(name, val);
            }}
            isClearable={clearable}
            showMonthDropdown={showMonthDropdown}
            showYearDropdown={showYearDropdown}
            dropdownMode="select"

        />
    );
};

export default DatePickerField;