import React from 'react';
import { Button, Modal } from 'react-bootstrap';

function RemoveAccountModal(props) {
    return (
      <>  
        <Modal show={props.show} onHide={props.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Delete account?</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you would like to delete this account? This action is irreversible.</Modal.Body>
          <Modal.Footer>
          <Button variant="danger"  onClick={props.removeAccount}>
              Delete
          </Button>
            <Button variant="secondary"  onClick={props.closeModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

export default RemoveAccountModal;