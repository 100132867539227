import React, {useState} from 'react';
import { Link, NavLink } from 'react-router-dom'
import { useParams, useHistory } from 'react-router-dom';
import Pepehousing from './articles/Pepehousing.js';
import Eurosender from './articles/Eurosender.js';
import Mentor from './articles/Mentor.js';
import AboutUs from './articles/AboutUs.js';
import Workgroups from './articles/Workgroups.js';
// import Pepehousing from './articles/Pepehousing';
const Articles = () => {
    const [menuOn, toggleMenu] = useState(false);
    const [articleName,setArticle] = useState('');
    const params = useParams();
    const history = useHistory();
    if(!(Object.keys(params).length === 0 && params.constructor === Object)){
        if(articleName!=params.articleName){
            setArticle(params.articleName);
        }
    }
    const renderArticle = (articleName) =>{
        switch(articleName){
            case 'pepehousing':
                return <Pepehousing/>
            case 'eurosender':
                return <Eurosender/>
            case 'mentor':
                return <Mentor/>
            case 'workgroups':
                return <Workgroups/>
            default: return <AboutUs/>
        }
    }
    return ( 
        <div className="articles-container">
            <div className={menuOn ? "articles-menu active" : "articles-menu"}>
                <div className="title">
                    Read more 
                </div>
                <div className="hamburger" onClick={()=>{toggleMenu(!menuOn)}}><div className="box"><div className="hamburger-inner"></div></div></div>

            </div>
            <div className={menuOn ? "articles-list active" : "articles-list"} onClick={()=>toggleMenu(false)}>
                <NavLink to="/articles/pepehousing" className="list-item">Pepehousing</NavLink>
                <NavLink to="/articles/eurosender" className="list-item">Eurosender</NavLink>
                <NavLink to="/articles/mentor" className="list-item">Mentor Programme</NavLink>
                <NavLink to="/articles/workgroups" className="list-item">Workgroups</NavLink>

            </div>
            <div className="container page-container col-md-12 articles">
               {renderArticle(articleName)}
            </div>
        </div>
     );
}
 
export default Articles;