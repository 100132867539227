import React, { Component } from "react";
const omit = (obj, omitKey) =>
	Object.keys(obj).reduce((result, key) => {
		if (key !== omitKey) {
			result[key] = obj[key];
		}
		return result;
	}, {});

const overlayStyles = {
	position: "absolute",
	filter: "blur(3px)",
	transition: "opacity ease-in 1000ms",
    clipPath: "inset(0)",
    width: "100%",
    left: 0,
    top: 0,
    zIndex: '4'
};
const loadedStyles = {
	position: "absolute",
	filter: "blur(3px)",
	transition: "opacity ease-in 1000ms",
    clipPath: "inset(0)",
    width: "100%",
    opacity: 0,
    left: 0,
    top: 0
};

export default class ProgressiveImage extends Component {
	constructor(props) {
		super(props);
		this.state = { highResImageLoaded: false };
	}
	render() {
		const { highResImageLoaded } = this.state;
		// let filteredProps = omit(this.props, "overlaySrc");
		return (
			<span style={{position:'relative',display:'block'}}>
				<img
					alt={this.props.alt}
					onLoad={() => {
						this.setState({ highResImageLoaded: true });
					}}
					ref={img => {
						this.highResImage = img;
					}}
					src={this.props.src}
				/>
				<img
					alt={this.props.alt}
					style= {highResImageLoaded ?  loadedStyles : overlayStyles}
					src={this.props.overlaySrc}
				/>
			</span>
		);
	}
}