import React, {Component} from 'react';
import Loading from './Loading.js';
import dayjs from 'dayjs';
import defaultImg from '../images/ESN_SGGW_background.png';
import clock from '../images/clockRed.svg';
import marker from '../images/markerRed.svg';
import isBetween from 'dayjs/plugin/isBetween';
import {HashRouter, Link} from 'react-router-dom';
dayjs.extend(isBetween);
const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday','Thursday', 'Friday', 'Saturday'];
class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: true,
            eventsToday: null,
            ohToday: null
         }
    }
    componentDidMount(){
        const {events, officeHours} = this.props;
        const now = dayjs(Date.now());
        const weekday = weekdays[now.day()];
        const isUserEsn = this.props.user && this.props.user.type==='esn' && this.props.user.isConfirmedEsn ? true: false;
        const isUserZarzad = this.props.user && this.props.user.isZarzad ? true: false;
        const eventsToday = events.filter((event)=>{
            if(event.esnOnly && !isUserEsn)
                return false;
            if(event.zarzadOnly && !isUserZarzad)
                return false;
            let startTime, endTime='';
            if(event.isMultiDay){
                startTime = dayjs(event.startTime.seconds*1000)
                endTime = dayjs(event.endTime.seconds*1000)
            }
            if(now.isSame(event.startTime.seconds*1000,'day') || (event.isMultiDay && now.isBetween(startTime,endTime,'day','[]')))
                return event;
            return false;
            
        });
        const ohToday = officeHours.filter(oh=>{
            if(oh.day===weekday)
                return oh;
            return false;
        });
        this.setState({loading: false, eventsToday, ohToday});
    }
    componentWillReceiveProps(props){

        const now = dayjs(Date.now());
        const isUserEsn = this.props.user && this.props.user.type==='esn' && this.props.user.isConfirmedEsn ? true: false;
        if(props.events){
            const eventsToday = props.events.filter((event)=>{
                if(event.esnOnly && !isUserEsn){
                    return false;
                }
                let startTime, endTime='';
                if(event.isMultiDay){
                    startTime = dayjs(event.startTime.seconds*1000)
                    endTime = dayjs(event.endTime.seconds*1000)
                }
                if(now.isSame(event.startTime.seconds*1000,'day') || (event.isMultiDay && now.isBetween(startTime,endTime,'day','[]')))
                    return event;
                return false;
                
            });
            this.setState({eventsToday});
        }
    }
    render() {
        
        const {loading, eventsToday, ohToday} = this.state;
        return ( 
            <div className="sidebar">
            {loading ? <Loading />: 
            <div className="wrapper"> 
                <h3 className="today">
                    {dayjs(Date.now()).format('dddd, MMMM D')}
                </h3>
                <div className="events-today">
                    <h5>Events today</h5>
                    {eventsToday.length===0 ? <div className="no-events">No events planned today</div> : 
                    eventsToday.map((event)=>{

                        const eventStartTime = dayjs(event.startTime.seconds*1000);
                        const now = dayjs(Date.now());
                        const displayTime = (!event.isMultiDay || (event.isMultiDay && now.isSame(event.startTime.seconds*1000,'day'))) ? true : false;

                        return(
                            <HashRouter key ={event.id}>
                            <div className="event">
                                <div className="image">{event.image ? <img src={event.image} alt="event"/> : <img src={defaultImg} alt="event"/>}</div>
                                <div className="name">{event.name}</div>
                                <div className="time"><img src={clock} alt="time"/>{!(!event.hasOwnProperty('isTimeKnown') || (event.hasOwnProperty('isTimeKnown') && event.isTimeKnown===true)) ? 'TBA' : displayTime ? eventStartTime.format('HH:mm') : 'Ongoing'}</div>
                                <div className="location"><img src={marker} alt="location"/>{event.location}</div>
                                <div className="text-center"><Link className="link" to={`../events/${event.id}`}><div className="btn dark-gray-button">See More </div></Link></div>
                            </div>
                            </HashRouter>
                        )
                    })}

                </div>

                <div className="office-hours hide">
                    <h5>Today's Office Hours:</h5>
                        {ohToday.length===0 ? <div className="no-oh">No Office Hours today</div> :
                        ohToday.map((oh)=>{
                            const {isCancelled, showChanges, defaultTime, modifiedTime} = oh;
                            return(
                                <div key={oh.id}>
                                <div className="name">{oh.name}</div>
                                <div className={showChanges ? "time crossed" : "time"}><span className="kelson-bold">Hours:</span><span className="kelson-normal hours">{defaultTime}</span></div>
                                {isCancelled ? <div className="time changed cancelled"><span className="kelson-bold" >This week: </span>Cancelled</div> : showChanges ? <div className="time changed"><span className="kelson-bold">This week:</span> <span className="kelson-normal">{modifiedTime}</span></div> : ''}
                                </div>
                            )
                        })}

                </div>
            </div>
            }
            </div>

         );
    }
}
 
 
export default Sidebar;