import React, {Component} from 'react';
import {Carousel} from 'react-bootstrap';
import esnStar from '../images/esnLogo512.png'
import $ from 'jquery';
import Loading from './Loading.js';
import ProgressiveImage from './ProgressiveImageSimple';
const teamMembers=[
    {
        name:'Nina Wąsik',
        position:'President',
        description:<div>
            My name is Nina and I study sociology at SGGW. In my free time I am involved in social and political activities. <br/><br/>
            I joined ESN because I love meeting new people, discovering their culture and traveling!
        </div>,
        photo:'nina.jpg',
        minPhoto:'nina_min.jpg',
        mail:'president.sggw@esn.pl'
    },
    {
        name:'Klaudia Ławniczak',
        position:'Vicepresident',
        description:<div>
            <span className="bold">Hi!</span><br/><br/>
            My name is Klaudia Ławniczak, and I am the vice-president of the ESN SGGW section. I am 22 years old and studying Environmental Protection in my fourth year at the Warsaw University of Life Sciences.🏫🪴<br/><br/>

            I've been active in ESN for three years, with one year as the coordinator of the sports group. 🥇🏀🏐<br/>

            Personally, I love cooking and discovering new recipes 🌯🍝, and in my free time, I play basketball 🏀 or go horse riding 🐎. I also love getting to know new cultures and exploring new places. 🌍✈️<br/><br/>

            I can't wait to meet all of you! 🥳
        </div>,
        photo:'klaudia_l.jpg',
        minPhoto:'klaudia_l_min.jpg',
        mail:'vicepresident.sggw@esn.pl'
    },
    {
        name:'Ewa Baranowska',
        position:'Treasurer',
        description: <div><span className="bold">Hello!👋🏼</span><br/><br/>
        
        My name is Ewa. I study Finance&Accounting.<br/><br/>

        I'm fond of reading books, especially thriller ones.📚
        I also love cycling. My dream is to go around Poland by bike 🚲🏕️
        Furthermore, I collect postcards (and I like to sending them too!)<br/><br/>
        Ask away about anything, I will gladly help you 🧡<br/><br/>

        I can't wait to meet you! 😊<br/>
        Ewa
        </div>,
        photo:'ewa.jpg',
        minPhoto:'ewa_min.jpg',
        mail:'treasurer.sggw@esn.pl'
    },
    {
        name:'Wiktoria Przygoda',
        position:'Human Resources Manager',
        description:<div>
            <span className="bold">Hi😊</span><br/><br/>            
            
            My name is Wiktoria and I'm biotechnology student👩🏼‍🔬🦠<br/><br/>

            In my free time I like reading books (especially fantasy ones). I also enjoy cooking and trying new things.

            I can't wait to meet you🩷
        </div>,
        photo:'wiktoria.jpg',
        minPhoto:'wiktoria_min.jpg',
        mail:'hr.sggw@esn.pl'
    },
    {
        name:'Sebastian Zellah',
        position:'Project Manager',
        description:<div>
            <span className="bold">Heyo!👋</span><br/><br/>

            My name is Sebastian and I joined ESN in 2022 after coming back from Erasmus in Italy.<br/><br/>

            I study IT, though I barely pass it 😅. I love dancing and bicycle, so my dream vacation would be to cycle from disco to disco hah 🚲💃.<br/><br/>

            ESN gave me a lot of love, and now it's time to give some love back 🩷. That is why I decided to become Project Manager and help you live your best Erasmus experience! 😁
        </div>,
        photo:'sebastian2.jpg',
        minPhoto:'sebastian2_min.jpg',
        mail:'pm.sggw@esn.pl'
    },
    {
        name:'Julia Wiśniewska',
        position:'Public Relations Manager',
        description:<div>
            <span className="bold">Hi, my name is Julia!</span><br/><br/>

            I am PR Manager in our ESN section and also I'm studying landscape architecture🏞👩🏻‍🎓 <br/><br/>

            Personally I'm a dancer💃🏻 My passion is making photos, traveling, drawing and doing sports!🎨🏐 <br/><br/>

            I can't wait to meet all of you and I hope that we will have so many great memories together!🥰❤
        </div>,
        photo:'julia_wi.jpg',
        minPhoto:'julia_wi_min.jpg',
        mail:'pr.sggw@esn.pl'
    },
]
const boaMembers = [
    {
        name:'Mateusz Nowicki',
        position:'Board of Audit Member',
        description:<div>
            Hey I'm Mati<br/><br/>

            I'm graduating this year with a bachelor's degree in biology 🧪 I work as a barista ☕<br/><br/>

            I have many hobbies but the most important ones are cooking, traveling and singing 🎶 I listen to many genres of music but currently most k-pop.<br/><br/>

            I can't wait to meet you guys 
        </div>,
        photo:'mati.jpg',
        minPhoto:'mati_min.jpg',
        mail:'kr.sggw@esn.pl'
    },
    {
        name:'Julia Rutkowska',
        position:'Board of Audit Member',
        description:<div>
            My name is Julia and I’m a vet student.<br/><br/>

            I really like trains and metro systems around the world, with my favorite being those of London Underground. I collect transport maps and tickets from different cities. In my free time I like to do some heavy lifting at the gym.<br/><br/>

            I am also a proud mother of a cat- Pesto.
        </div>,
        photo:'julia_r.jpg',
        minPhoto:'julia_r_min.jpg',
        mail:'kr.sggw@esn.pl'
    },
    {
        name:'Natalia Stasiowska',
        position:'Board of Audit Member',
        description:<div><span className="bold">Hello 😊</span><br/><br/>
            
        My name is Natalia and I study zootechnical engineering.<br/><br/>

        I’ve joined ESN after coming back from Portugal, where I did erasmus for one year.<br/><br/>

        My biggest hobbies are horse races 🏇, travelling ✈️ and photography 📸.<br/><br/>

        See you!</div>,
        photo:'natalias.jpg',
        minPhoto:'natalias_min.jpg',
        mail:'kr.sggw@esn.pl'
    },
]
const coordinators = [
    {
        name:'Gabi Chybińska',
        position:'Mentor Programme',
        description:<div>
            <span className="bold">Hi!</span><br/><br/>
            I'm Gabi and I study veterinary medicine.<br/><br/>
            I've joined ESN because I love to travel and learn about foreign cultures!Also my biggest hobbies are singing and reading 🎤📚 <br/><br/>
            See you around! 👋

</div>,
        photo:'gabi.jpg',
        minPhoto:'gabi_min.jpg',
        mail: <div>mentor.sggw@esn.pl</div>
    },
    {
        name:'Patrycja Gienejko',
        position:'Mentor Programme',
        description:<div>
            <span className="bold">Hi there!</span><br/><br/>
            My name is Patrycja, but everyone calls me Pata! I've just turned 22 and I'm a vet-med student.<br/><br/>
            I am a huge animal lover who can't decide between cats 🐈‍ and dogs 🐩.<br/><br/>
            In my spare time I love playing video games, solving crosswords and doing the puzzle!
        </div>,
        photo:'patrycja.jpg',
        minPhoto:'patrycja_min.jpg',
        mail: <div>mentor.sggw@esn.pl</div>
    },
    // {
    //     name:'Piotr Rabek',
    //     position:'Fundraising Committee',
    //     description:<div><span className="bold">Hello! 👋</span><br/><br/>
    //     I'm Piotr and I study Envitonmental Engineering. I'm keen on foreign languages and sports.<br/><br/>
    //     I love learning new things so I never get bored and I bet there's always something we can fall into discussion about 😁</div>,
    //     mail: 'fr.sggw@esn.pl',
    //     photo:'piotrek.jpg',
    //     minPhoto:'piotrek_min.jpg',
    // },
    // {
    //     name:'Recruitment in progress',
    //     position:'Fundraising Committee',
    //     description:"Recruitment in progress",
    //     mail: 'fr.sggw@esn.pl',
    //     photo:'default.jpg',
    //     minPhoto:'default.jpg',
    // },
    // {
    //     name:'Recruitment in progress',
    //     position:'Travel Committee',
    //     description:"Recruitment in progress",
    //     mail: 'travel.sggw@esn.pl',
    //     photo:'default.jpg',
    //     minPhoto:'default.jpg',
    // },
    {
        name:'Julia Rutkowska',
        position:'Travel Committee',
        description:<div>
            My name is Julia and I’m a vet student. <br/><br/>
            I really like trains and metro systems around the world, with my favorite being those of London Underground. I collect transport maps and tickets from different cities. In my free time I like to do some heavy lifting at the gym.<br/><br/>
            I am also a proud mother of a cat- Pesto.
        </div>,
        photo:'julia_r.jpg',
        minPhoto:'julia_r_min.jpg',
        mail: 'travel.sggw@esn.pl'
    },
    {
        name:'Mateusz Nowicki',
        position:'Travel Committee',
        description:<div><span className="bold">Hey I'm Mati</span><br/><br/>
            I'm graduating this year with a bachelor's degree in biology 🧪 I work as a barista ☕ this year I was elected president of ESN SGGW 😊<br/><br/>
            I have many hobbies but the most important ones are cooking, traveling and singing 🎶 I listen to many genres of music but currently most k-pop.<br/><br/>
            I can't wait to meet you guys 
         </div>,
        photo:'mati.jpg',
        minPhoto:'mati_min.jpg',
        mail: 'travel.sggw@esn.pl'
    },
    // {
    //     name:'Julia Warchoł',
    //     position:'Bounce Group',
    //     description:<div>
    //     <span className="bold">Hello!</span><br/><br/>
    //     Im Julia and Im Mentor Programme Coordinator and also Bounce Coordinator.<br/><br/>
    //     Im 21 years old and I’m studying veterinary medicine in WULS. I love music, I play piano and I graduated from music school some time ago.<br/><br/>
    //     In my free time I enjoy watching films, meeting with my friends, reading and playing volleybal.<br/><br/>
    //     Im hoping that I will bring some good energy into ESN and a lot of good memories!
    // </div>,
    //     mail: 'bounce.sggw@esn.pl',
    //     photo:'juliaw.jpg',
    //     minPhoto:'juliaw.jpg',
    // },
    {
        name:'Szymon Knapiński',
        position:'Public Relations Committee',
        description:<div><span className="bold">Hello there!</span><br/><br/>
        My name is Szymon and I'm halfway through my Veterinary Medicine studies.<br/><br/>
        I've been a member of ESN SGGW for a year, during which I worked for Coolture, PR and Sport sections.<br/>

        Privately I'm a bedroom DJ, music junkie, architecture enthusiast, world traveller, sports lover, LEGO collector, dancer, proud cat dad and a gamer with terrible skills.<br/><br/>

        I am looking forward to meeting great new people, with whom I will establish great frendships.<br/>
        See you soon!
    </div>,
        photo:'szymon.jpg',
        minPhoto:'szymon_min.jpg',
        mail:'pr.sggw@esn.pl'
    },
    {
        name:'Klaudia Ławniczak',
        position:'Sports Committee',
        description:<div>
            <span className="bold">Hi 😊</span><br/><br/>
            My name is Klaudia I’m 21 years old and I study Environmental Protection. 🌳<br/><br/>

            I joined ESN SGGW last year. During that time I was always present at our weekly sports games 🏀.<br/>
            For me, it was a fantastic way to meet new people and be active so I decided that it was time to raise the bar and become coordinator of the sports group.<br/><br/>

            I also like reading books, watching movies, and spending time with all kinds of animals. 📘 🎥 🐱 🐴
        </div>,
        mail: 'sports.sggw@esn.pl',
        photo:'klaudia.jpg',
        minPhoto:'klaudia_min.jpg',
    },
    {
        name:'Sebastian Zellah',
        position:'Events Group',
        description:<div>
            <span className="bold">Heyo!👋</span><br/><br/>

            My name is Sebastian and I joined ESN in 2022 after coming back from Erasmus in Italy.<br/><br/>

            I study IT, though I barely pass it 😅. I love dancing and bicycle, so my dream vacation would be to cycle from disco to disco hah 🚲💃.<br/><br/>

            ESN gave me a lot of love, and now it's time to give some love back 🩷. That is why I decided to become Project Manager and help you live your best Erasmus experience! 😁
        </div>,
        photo:'sebastian2.jpg',
        minPhoto:'sebastian2_min.jpg',
        mail:'culture.sggw@esn.pl'
    },
    {
        name:'Nina Wąsik',
        position:'Events Group',
        description:<div>
            My name is Nina and I study sociology at SGGW. In my free time I am involved in social and political activities. <br/><br/>
            I joined ESN because I love meeting new people, discovering their culture and traveling!
        </div>,
        photo:'nina.jpg',
        minPhoto:'nina_min.jpg',
        mail:'culture.sggw@esn.pl'
    },
//     {
//         name:'Martyna Dwojewska',
//         position:'Social Committee',
//         description:<div>
//             <span className="bold">Hey everyone! 👋</span><br/><br/>
//             My name is Martyna. I'm 20 year-old- student of Business Management at SGGW (WULS). I joined ESN at the beginning of December 2021. Now, I'm a Mentor & Social Coordinator.<br/><br/> 
//             Besides being a student and ESNer, I'm huge sports fan. I play basketball and volleyball regularly, so we can probably meet during our sports events 😉 <br/><br/>
//             I also love reading books, watching movies, learning languages, cooking and traveling 🍽️🌏🎥📚
// </div>,
//         photo:'martynad.jpg',
//         minPhoto:'martynad_min.jpg',
//         mail: 'social.sggw@esn.pl'
//     },
    {
        name:'Natalia Stasiowska',
        position:'Local Education Officer',
        description:<div><span className="bold">Hello 😊</span><br/><br/>
            
            My name is Natalia and I study zootechnical engineering.<br/><br/>

            I’ve joined ESN after coming back from Portugal, where I did erasmus for one year.<br/><br/>

            My biggest hobbies are horse races 🏇, travelling ✈️ and photography 📸.<br/><br/>

            See you!</div>,
        mail: 'erasmus.sggw@esn.pl',
        photo:'natalias.jpg',
        minPhoto:'natalias_min.jpg',
    },
];
// const teamMembersCount = teamMembers.length + boaMembers.length + coordinators.length;
class Team extends Component{

    constructor(props) {
        super(props)
        this.state = { 
            loading: false,
            activeCategory:'board',
            activeIndex:1,
         }
    }
    componentDidMount(){
    }

            //preload images - change logic if images taken from DB.
    toggleClicked = function(e){
        e.stopPropagation();
        e.preventDefault();
        $(e.currentTarget).toggleClass('clicked');
    }
    render(){   
        // console.log(this.state.loading);
    return ( 
        this.state.loading ? <Loading/> : 
        <div className="container team-container col-md-12 page-container">
            <h3 className="page-title visible-xs">
                Meet the team
            </h3>

            <div className="heading">
                <div className={this.state.activeIndex===0 ? "category active coordinators" :'category coordinators'} onClick={()=>{this.setState({activeIndex:0})}}>
                    <h3>Coordinators</h3>
                    <div className="astrodivider"><div className="astrodividermask"></div></div>
                </div>
                <div className={this.state.activeIndex===1 ? "category active board" :'category board'} onClick={()=>{this.setState({activeIndex:1})}}>
                    <h3>Executive Board</h3>
                    <div className="astrodivider"><div className="astrodividermask"></div></div>
                </div>
                <div className={this.state.activeIndex===2 ? "category active audit" :'category audit'}  onClick={()=>{this.setState({activeIndex:2})}}>
                    <h3>Board of Audit</h3>
                    <div className="astrodivider"><div className="astrodividermask"></div></div>
                </div>

            </div>

            <Carousel touch={false} activeIndex={this.state.activeIndex} onSelect={(index)=>{this.setState({activeIndex: index})}} interval={null}>

            <Carousel.Item>
                    <h3 className="category-name">Coordinators</h3>
                    <div className="board-members coordinators col-md-12 col-lg-12">
                        {coordinators.map(member =>{
                            return ( <div className="card" onClick={(e)=>{this.toggleClicked(e)}} key={member.name+member.position}>

                                <div className="front">
                                    <ProgressiveImage src={require('../images/teamImages/'+member.photo)} overlaySrc={require('../images/teamImages/'+member.minPhoto)} alt=""/>
                                    {/* <ProgressiveImage teamMembersCount = {teamMembersCount} updateTeamPageLoad= {this.props.updateTeamPageLoad} teamImagesLoaded={this.props.teamImagesLoaded} src={require('../images/teamImages/'+member.photo)} overlaySrc={require('../images/teamImages/'+member.minPhoto)} alt=""/> */}
                                    <div className="info-front">
                                        <div className="name">{member.name}</div>
                                        <div className="separator"></div>
                                        <div className="position">{member.position}</div>
                                        {member.mail ? <div className="mail"><a href={"mailto:"+member.mail}>{member.mail}</a></div> : null}
                                    </div>
                                </div>
                                <div className="back">
                                    <img src={require('../images/teamImages/'+member.photo)} alt="" />
                                    <div className="text">
                                        {member.description}
                                        
                                    </div>


                                </div>
                            </div> )})}
                        </div>
                </Carousel.Item>
                <Carousel.Item>
                <h3 className="category-name">Executive Board</h3>
                <div className="board-members col-md-12 col-lg-12">
                    {teamMembers.map(member =>{
                        return ( <div className="card" onClick={(e)=>{this.toggleClicked(e)}} key={member.name+member.position}>

                            <div className="front">
                                <ProgressiveImage src={require('../images/teamImages/'+member.photo)} overlaySrc={require('../images/teamImages/'+member.minPhoto)} alt="" />
                                <div className="info-front">
                                    <div className="name">{member.name}</div>
                                    <div className="separator"></div>
                                    <div className="position">{member.position}</div>
                                    {member.mail ? <div className="mail"><a href={"mailto:"+member.mail}>{member.mail}</a></div> : null}
                                </div>
                            </div>
                            <div className="back">
                                <img src={require('../images/teamImages/'+member.photo)} alt="" />
                                <div className="text">
                                    {member.description}
                                    
                                </div>


                            </div>
                        </div> )})}

                    </div>
                </Carousel.Item>

                <Carousel.Item>
                    <h3 className="category-name">Board of Audit</h3>
                    <div className="board-members audit col-md-12 col-lg-12">
                        {boaMembers.map(member =>{
                            return ( <div className="card" onClick={(e)=>{this.toggleClicked(e)}} key={member.name+member.position}>

                                <div className="front">
                                    <ProgressiveImage src={require('../images/teamImages/'+member.photo)} overlaySrc={require('../images/teamImages/'+member.minPhoto)} alt="" />
                                    <div className="info-front">
                                        <div className="name">{member.name}</div>
                                        <div className="separator"></div>
                                        <div className="position">{member.position}</div>
                                        {member.mail ? <div className="mail"><a href={"mailto:"+member.mail}>{member.mail}</a></div> : null}
                                    </div>
                                </div>
                                <div className="back">
                                    <img src={require('../images/teamImages/'+member.photo)} alt="" />
                                    <div className="text">
                                        {member.description}
                                        
                                    </div>


                                </div>
                            </div> )})}
                    </div>
                </Carousel.Item>
                </Carousel>
        </div>
     );
    }
}
 
export default Team;