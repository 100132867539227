import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';


class FeedbackModal extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      text: ''
     }
  }
  setText = (e) =>{
    this.setState({text:e.target.value});
  }
  render() { 
    return (
      <>  
        <Modal show={this.props.show} onHide={this.props.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea className="form-control" id="feedback" name="feedback" rows="4" placeholder="Type here..." onChange={this.setText}/>
          </Modal.Body>
          <Modal.Footer>

          <Button variant="success"  disabled={this.state.text.length<=0} onClick={() => {this.props.submitFeedback(this.state.text)}}>
              Submit
          </Button>
            <Button variant="secondary"  onClick={this.props.closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default FeedbackModal;
 