import React from 'react';
import ESNlogo from '../images/ESN_SGGW_allwhite.png'
import facebookLogoDefault from '../images/ikony/facebookLogo_default.png'
import instagramLogoDefault from '../images/ikony/instagramLogo_default.png'
import { Link} from 'react-router-dom'
const HeaderMobile = () => {
    return ( 
        <div className="header-mobile dark">
            <Link to="/" className="logo"><img src={ESNlogo} alt="ESN SGGW"/></Link>
            <div className="sm">
                <a href="https://www.facebook.com/esn.sggw/" target="_blank" rel="noopener noreferrer">
                    <img src={facebookLogoDefault} alt="Facebook"/>
                </a>
                <a href="https://www.instagram.com/esnsggw/" target="_blank" rel="noopener noreferrer">
                    <img src={instagramLogoDefault} alt="Instagram"/>
                </a>

            </div>
        </div>
     );
}
 
export default HeaderMobile;