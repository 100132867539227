import React from 'react';
import { Button, Modal } from 'react-bootstrap';

function MessageModal(props) {
    return (
      <>  
        <Modal show={props.show} onHide={props.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{props.message}</Modal.Body>
          <Modal.Footer>
          {props.remove ?
          <Button variant="danger"  onClick={props.removeEvent}>
              Remove
          </Button> : ''}

            <Button variant="secondary"  onClick={props.closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

export default MessageModal;