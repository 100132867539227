import React, {useState} from 'react';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {Row, Col} from 'react-bootstrap';
import {Link, useHistory} from 'react-router-dom'

import firebase from '../firebase';

const Login = (props) => {
    const history = useHistory();
    const [stateError, setError] = useState('');
    // console.log(stateError);
    // useSelector((state)=>{
    //     console.log(state.firestore.data);
    // });

    return ( 
    <div className="page-container fix-pad mx-auto">
        <div className="container mobile-container register-container">
            <h3 className="page-title">Login</h3>
            <Formik
                initialValues={{
                    email:'', password:''
                }}

                onSubmit={(values,{setSubmitting})=>{
                    let raiseError=0;
                    firebase.auth().signInWithEmailAndPassword(values.email, values.password).catch((error)=>{
                        // console.log(error.code);
                        setSubmitting(false);
                        if(error.code==="auth/invalid-email" || error.code==="auth/user-not-found"){
                            setError('No user found with that email');
                        }else{
                            setError('Incorrect password');
                        }
                        raiseError=1;
                        // console.log(stateError);
                    }).then((user)=>{
                        setSubmitting(false);
                        if (raiseError===0){
                            firebase.analytics().logEvent('login',{uid:user.user.uid});
                            history.push('/');
                        }
                    })

                }}
            >
              {({
                values,
                handleSubmit,
                setFieldValue,
                errors,
                isSubmitting
              })=>(
                <Form className="register-form">
                <div className="form-group">
                    <Row>
                        <Col xs={3}  className="pl-0 label-container">
                        <label htmlFor="email">Email</label>
                        </Col>
                        <Col xs={9}  className="form-error pr-0">
                            <ErrorMessage name="email"/>
                        </Col>
                    </Row>
                    <Row ><Field id="email" name="email" type="text" className="form-control" /></Row>
                </div>
                <div className="form-group">
                    <Row>
                        <Col xs={4}  className="pl-0 label-container">
                        <label htmlFor="password">Password</label>
                        </Col>
                        <Col xs={8}  className="form-error pr-0">
                            <ErrorMessage name="password"/>
                        </Col>
                    </Row>
                    <Row ><Field id="password" name="password" type="password" className="form-control"  /></Row>
                    <Row><Link className="forgot-password" to="../password-reset">Forgot password?</Link></Row>
                </div>
                <div className="text-center form-error">{stateError}</div>
                <Row className="form-group">
                    <button className="btn btn-primary mx-auto" type="submit" disabled={isSubmitting}>Submit</button>
                </Row>
                <Row className="text-center no-account">
                    <Link className="have-account" to="/register">Don't have an account yet? <span>Create one!</span></Link>
                </Row>
                <Row className="text-center no-account no-margin">
                    <Link className="have-account" to="/login-box"><span>Use a different login method</span></Link>
                </Row>
                </Form>
              )}  
            </Formik>

        </div>
    </div>

     );
}
 
export default Login;