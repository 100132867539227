import React, {useState} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { isLoaded, isEmpty} from 'react-redux-firebase';
import ReactHtmlParser from 'react-html-parser';
import clock from '../images/clockRed.svg';
import marker from '../images/markerRed.svg';
import priceImg from '../images/priceRed.svg';
import configure from '../images/configure.svg';
import defaultImg from '../images/ESN_SGGW_background.png';
import traktor from '../images/traktor_2.png';
import firebase from '../firebase';
import {Link} from 'react-router-dom';
import NotFound from './NotFound';
import Loading from './Loading.js';
import MessageModal from './MessageModal';
import FeedbackModal from './FeedbackModal';
import user from '../images/audience.svg'
import Dropdown from 'react-bootstrap/Dropdown'
// import 'https://cdn.jsdelivr.net/npm/emojibase-data@4.2.1/en/compact.json' as SOURCE_PROP;

const months = [
    'Jan.',
    'Feb.',
    'Mar.',
    'Apr.',
    'May',
    'June',
    'July',
    'Aug.',
    'Sept.',
    'Oct.',
    'Nov.',
    'Dec.'
  ]

class HtmlComponent extends React.Component {
    render() {
      const html = this.props.data;
      return <div>{ ReactHtmlParser(html) }</div>;
    }
  }
const Event = (props) => {
    const params = useParams();
    const history = useHistory();
    const [isSubmenuVisible, toggleSubmenu] = useState(false);
    const [modalVisible, setModal] = useState(false);
    const [feedbackModalVisible, setFeedbackModal] = useState(false);
    const event = props.events.filter(event=>{
        return event.id===params.eventId
    })[0];

    const closeModal = () =>{
        setModal(false);
    }
    const showModal = () =>{
        setModal(true);
    }
    const closeFeedbackModal = () =>{
        setFeedbackModal(false);
    }
    const showFeedbackModal = () =>{
        setFeedbackModal(true);
    }
    const submitFeedback = (text) =>{
        firebase.firestore().collection('events').doc(params.eventId).update({feedback:firebase.firestore.FieldValue.arrayUnion(text)});
        setFeedbackModal(false);
    }
    let startTimePrint='';
    let endTimePrint='';
    let currentRating=null;
    let average=null;
    let votesNum = null;
    if(!isEmpty(event) && isLoaded(event)){
    
        if(event.ratings && props.user){
            const ratingsArray = Object.values(event.ratings);
            votesNum=ratingsArray.length;
            average = (ratingsArray.reduce((a,b)=> a+b ,0)/ratingsArray.length).toFixed(2);
            if(event.ratings[props.user.uid]){
                currentRating = event.ratings[props.user.uid];
            }
        }
        //very messy, should've utilized dayjs. Might be faster though.
        //format date
        const dateStart = new Date(event.startTime.seconds*1000)
        const dateEnd = (event.endTime && event.endTime!=='') ? new Date(event.endTime.seconds*1000): '';
        const day = dateStart.getDate() <=9 ? "0"+dateStart.getDate() : dateStart.getDate();
        const month = months[dateStart.getMonth()];
        const minutes = dateStart.getMinutes() <=9 ? "0"+dateStart.getMinutes() : dateStart.getMinutes();
        const hours = dateStart.getHours() <=9 ? "0"+dateStart.getHours() : dateStart.getHours();
        const getTime = hours+':'+minutes;
        endTimePrint='';
        // if on the same day, display just the hour after '-'
        if(dateEnd!=='' && dateStart.getDate()===dateEnd.getDate() && dateStart.getMonth()===dateEnd.getMonth()){
            const endMinutes = dateEnd.getMinutes() <=9 ? "0"+dateEnd.getMinutes() : dateEnd.getMinutes();
            const endHours = dateEnd.getHours() <=9 ? "0"+dateEnd.getHours() : dateEnd.getHours();
            endTimePrint = ' - '+endHours+':'+endMinutes
        }
        //end date specified, but not on the same day.
        else if( dateEnd!==''){
            const endDay = dateEnd.getDate() <=9 ? "0"+dateEnd.getDate() : dateEnd.getDate();
            const endMonth = months[dateEnd.getMonth()];
            const endMinutes = dateEnd.getMinutes() <=9 ? "0"+dateEnd.getMinutes() : dateEnd.getMinutes();
            const endHours = dateEnd.getHours() <=9 ? "0"+dateEnd.getHours() : dateEnd.getHours();
            const endTime = endHours+':'+endMinutes;
            endTimePrint = ` - ${endDay} ${endMonth} ${endTime}`
        }
        startTimePrint = `${day} ${month} ${getTime}`;
    }

    const handleRemoveEvent = () =>{
        firebase.firestore().collection('events').doc(params.eventId).delete().then(()=>{
            firebase.firestore().collection('tasks').doc(params.eventId).delete().then(()=>{

                history.push('/');
            });
        });

    }
    const askToConfirm = () =>{
        showModal();
    }

    const handleChangeInterest = (interested) =>{
        //kamil - 19.05.2022 - removing notification functionality because it's pointless
        let messaging = false;
        let notificationToken;
        // if(firebase.messaging.isSupported()) {
        //     messaging = firebase.messaging();
        // }
        if(messaging && window.innerWidth <= 768){
            //this is really dumb
            messaging.requestPermission().then(()=>{
                return messaging.getToken();
              }).then((token)=>{
                console.log('token requested and retrieved: ' +token);
                if(interested){
                    firebase.firestore().collection('events').doc(params.eventId).update({interested:firebase.firestore.FieldValue.arrayUnion(`${props.user.uid};${props.user.fullName};${props.user.country}`),notificationTokens:firebase.firestore.FieldValue.arrayUnion(token)});
                }else{
                    firebase.firestore().collection('events').doc(params.eventId).update({interested:firebase.firestore.FieldValue.arrayRemove(`${props.user.uid};${props.user.fullName};${props.user.country}`),notificationTokens:firebase.firestore.FieldValue.arrayRemove(token)});
                }
              }).catch((err)=>{
                console.log("Error: " +err);
                //error - if error, just update 'interested'
                if(interested){
                    firebase.firestore().collection('events').doc(params.eventId).update({interested:firebase.firestore.FieldValue.arrayUnion(`${props.user.uid};${props.user.fullName};${props.user.country}`)});
                }else{
                    firebase.firestore().collection('events').doc(params.eventId).update({interested:firebase.firestore.FieldValue.arrayRemove(`${props.user.uid};${props.user.fullName};${props.user.country}`)});
                }
              });
        }else{
            //if no messaging, just update 'interested'
            if(interested){
                firebase.firestore().collection('events').doc(params.eventId).update({interested:firebase.firestore.FieldValue.arrayUnion(`${props.user.uid};${props.user.fullName};${props.user.country}`)});
            }else{
                firebase.firestore().collection('events').doc(params.eventId).update({interested:firebase.firestore.FieldValue.arrayRemove(`${props.user.uid};${props.user.fullName};${props.user.country}`)});
            }
        }
    }
    const handleStarClick = (event)=>{
        const rating = parseInt(event.target.dataset.rating);
        firebase.firestore().collection('events').doc(params.eventId).update({['ratings.'+props.user.uid]:rating}).then((test)=>{
            // update frontend view
        }).catch((err)=>{
            alert(err);
        })
    }
    return ( 
        
        isEmpty(event) && !isLoaded(event)? <NotFound/> : 
        !isLoaded(event)? <Loading/> :
        
        <div className="single-event-wrapper col-md-10 mx-auto">
            <MessageModal show={modalVisible} closeModal={closeModal} message={"Are you sure you want to remove this event?"} title="Confirm" remove={true} removeEvent={handleRemoveEvent}/>
            <FeedbackModal show={feedbackModalVisible} closeModal={closeFeedbackModal} title="Send feedback (anonymous)" feedback={true} submitFeedback={submitFeedback}/>
            <div className="container-fluid image-wrapper ">
                {event.image ? <img src={event.image} alt={event.name}/> : <img src={defaultImg} alt={event.name}/>}
                {event.interested && event.interested.length>0 ? <div className="user-counter"><div className="inside"><img src={user} alt="users"></img>{event.interested.length}</div></div> : ''}
            </div>

            <div className="description-wrapper  mx-auto">
                <div className="name-container">
                    <h4 className="name kelson-bold">{event.name}</h4>
                    {/* {event.createdBy} */}
                    {props.user && event.createdBy && (event.createdBy===props.user.uid || props.user.uid===process.env.REACT_APP_ADMIN_ID) ? 
                    <div className="configure-container" onClick={()=>{toggleSubmenu(!isSubmenuVisible)}}>
                        <img  className="configure-icon" src={configure} alt="Configure options"/>
                        <div className={isSubmenuVisible ? 'submenu active': ' submenu'}>
                            <Link className="link" to={`../createEvent/${params.eventId}`}>Edit</Link>
                            <div className="link" onClick={askToConfirm}>Remove</div>
                        </div>
                    </div> 
                    : ''}
                    
                </div>
                <div className="separator"></div>
                <div className="date kelson-normal"><img className="icon" src={clock} alt="time"/>{startTimePrint+endTimePrint}</div>
                <div className="location kelson-normal"><img className="icon" src={marker} alt="location"/>{event.location}</div>
                {event.price===0 ? '' : <div className="price kelson-normal"><img className="icon" src={priceImg} alt="price"/>{event.price+" PLN"}</div>}


            </div>
            {props.user ? 
            <div className="actions-container  mx-auto">
                <div className="interested-container">
                    <div className="custom-m-r-1">

                    
                    {props.user && event.interested && event.interested.includes(`${props.user.uid};${props.user.fullName};${props.user.country}`) ?
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                Going
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={()=>handleChangeInterest(false)}>Not going</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    // jesli nie jest zapisany ALE jest zalogowany
                     : (props.user) ?
                    <Dropdown>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                            Not going
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={()=>handleChangeInterest(true)}>Going</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> : ''
                    }
                    </div>
                    <div className="disclaimer">
                        {/* <span>Clicking "Interested" will allow you to recieve important notifications regarding this event (NOT YET IMPLEMENTED) and also gives us useful insight.</span> */}
                        <span>Clicking "Interested" will allow you to recieve important notifications regarding this event (in development) and provide us with useful insight.</span>
                    </div>
                </div>
                <div className="feedback-container">
                    <button className="btn btn-primary feedback" onClick={showFeedbackModal}>Send feedback</button>
                    <div className="disclaimer">
                        <span>Providing feedback will help us improve and create better events! :)</span>
                    </div>
                </div>
                <div className="rate-container">
                    <div className="left">
                        <p>Rate: </p>
                        <div className="stars-container" onClick={handleStarClick}>
                            <img src={traktor} data-rating={1} className={(currentRating && currentRating>=1) ? "rate-icon active" : "rate-icon"} alt="rate star"/>
                            <img src={traktor} data-rating={2} className={(currentRating && currentRating>=2) ? "rate-icon active" : "rate-icon"} alt="rate star"/>
                            <img src={traktor} data-rating={3} className={(currentRating && currentRating>=3) ? "rate-icon active" : "rate-icon"} alt="rate star"/>
                            <img src={traktor} data-rating={4} className={(currentRating && currentRating>=4) ? "rate-icon active" : "rate-icon"} alt="rate star"/>
                            <img src={traktor} data-rating={5} className={(currentRating && currentRating>=5) ? "rate-icon active" : "rate-icon"} alt="rate star"/>
                        </div>

                    </div>

                    <div className="average">
                        <span className="votes">Votes: {votesNum}</span>
                        <span>Average: {average}</span>
                    </div>
                </div>
            </div>
            : 
            <div className="disclaimer-login">
                <span> Only logged in users can access leaving feedback, rating and signing up for an event.</span>
            </div>
            }
            <div className="event-description  mx-auto">
                <HtmlComponent data={event.description}/>
            </div>
            {/* <div className="administration  mx-auto">
                Administration (visible only for ESN)
            </div> */}
            {/* <div>{eventData.description}</div> */}
        </div>
    );
}
 
export default Event;
