
  const createTest = (state = {}, action) => {
    const { test } = action
  switch(action.type){
    case 'CREATE_TEST':
     return [
      ...state,
      test  
     ];
    case 'GET_ALL_TESTS':
      console.log(state);
      return state
    default:
     return state  
   }
  }
  export default createTest