import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import rootReducer from './store/reducers/rootReducer';
import {createStore, compose, applyMiddleware} from 'redux';
import { getFirestore, createFirestoreInstance } from 'redux-firestore';
import {getFirebase, ReactReduxFirebaseProvider } from 'react-redux-firebase';
import firebase from './firebase';
import thunk from 'redux-thunk';

const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
    attachAuthIsReady: true
  };

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const Store = createStore(rootReducer, composeEnhancers(
    // reactReduxFirebase(firebase,rrfConfig),
    // reduxFirestore(firebase),
    applyMiddleware(thunk.withExtraArgument({getFirebase,getFirestore}))
));

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: Store.dispatch,
    createFirestoreInstance
}
// console.log(Store);

ReactDOM.render(<Provider store={Store}><ReactReduxFirebaseProvider {...rrfProps}><App /></ReactReduxFirebaseProvider></Provider>, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
    onSuccess: (reg) => Store.dispatch({type:'SW_INIT', payload: reg}),
    onUpdate: (reg) =>  Store.dispatch({ type: 'SW_UPDATE', payload: reg }),
    onWaiting: (waiting)=> Store.dispatch({ type: 'SW_UPDATE', payload: waiting })
});
